html {
    @include font-default;
    @include default-font-size;
    color: getColor('text', 'html');
    background-color: getColor('background', 'html');
    min-height: 100vh;

    //STATE
    //locked scrolling
    &.is-locked {
        position: fixed;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
    }

}

html, body {
    width: 100%;
    min-height: 100vh;
}

html {
    &.is-featured-case-loading,
    &.is-next-case-loading,
    &.is-prev-case-loading,
    &.is-featured-case-loading body,
    &.is-next-case-loading body,
    &.is-prev-case-loading body {
        overflow-x: hidden;
    }
}

body {
    &.is-morph-opened,
    &.is-menu-opened {
        overflow: hidden;
        width: 100vw;
        height: 100vh;
    }

    //&.is-mouse-disabled {
    //    pointer-events: none;
    //}
}

#barba-wrapper {
    overflow: hidden;
}

.barba-container {
    max-width: 100%;

    &:nth-of-type(2) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        z-index: 1;
    }

    .is-prev-case-loading &,
    .is-next-case-loading & {
        &:nth-of-type(3) {
            z-index: 2;
        }
    }
}

.grecaptcha-badge {
    display: none!important;
    opacity: 0;
    visibility: hidden;
}
