//WHAT WE DO
.c-what-we-do {
    &__left {

    }

    &__list {
        padding-bottom: getSpacing('xl');

        @include mq(md) {
            padding-bottom: getSpacing(md);
        }
    }

    &__item {
        position: relative;
        padding: getSpacing('sm') 0;

        @include mq(md) {
            padding-bottom: 0;
        }
    }

    &__show-more {
        margin-top: getSpacing('ld-A', 'fluid');
    }

    /*&__item-number {
        position: absolute;
        display: inline-block;
        right: 100%;
        top: 25px;
        margin-right: 25px;

        @include mq(lg) {
            position: relative;
            right: auto;
            top: auto;
            margin-bottom: getSpacing('xs');
        }
    }*/

    &__item-title {

    }
}