.c-custom-video-wrapper {
    max-width: 870px;
    width: auto;
    margin: 0 auto;

    video {
        max-width: 100%;
    }

    // colors
    --plyr-color-main: #1e1e1e;

    // overrides
    .plyr__control--overlaid {
        border-radius: 0;
    }
}
