$settings-colors: (
        'default': (
                'white': #ffffff,
                'black': #000000,
                'dark-blue': #121a2d
        ),
        'html': (
                'text': #121a2d,
                'background': #ffffff
        ),
        'primary': (
                'white': #ffffff,
                'black': #000000,
                'dark-grey': #000000,
                'primary-blue': #7d00ff,
                'pink': #d7004d,
                'watermelon': #e54360,
                'light-pink': #e46c6c,
                'light-blue': #f5f7fd,
                'dark-blue': #121a2d,
                'yellow': #F4AF19,
                'blue': #346CF0,
                'green': #70c645,
        ),
        'secondary': (
                'grey': #dbdbdb,
                'grey-A': #B2B2B2,
                'light-grey': #e7e7e7,
                'light-grey-A': #d8d8d8,
                'dark-grey': #4b4b4b,
                'dark-grey-A': #1e1e1e,
                'pale-brown': #EAD6CC,
                'pink': #d7004d,
                'watermelon': #e54360,
                'light-pink': #e46c6c,
                'dark-blue': #121a2d,
                'yellow': #F4AF19,
                'blue': #346CF0,
                'green': #70c645,
        ),
        'sections': (
                'light-grey': #e7e7e7,
                'light-grey-A': #ebedf0,
                'light-grey-B': #C8C8C6,
                'grey': #e5e5e5,
                'dark-grey': #0d0d0d,
                'dark-grey-A': #1a1a1a,
                'pale-brown': #ebd7cd,
                'pale-blue': #dce0ea,
                'pale-blue-A': #dbe2ec,
                'pale-blue-B': #c8d1db,
                'pale-blue-C': #edf0f7,
                'green': #d8d8c2,
                'light-green': #e9ece4,
                'pale-green': #f1f4ea,
                'pale-green-A': #f4f3f1,
                'pale-violet': #d7d0dc,
                'pale-violet-A': #d3ced7,
                'white': #ffffff,
                'white-two': #fafafa,
                'pink': #d7004d,
                'light-pink': #e46c6c,
                'watermelon': #e54360,
                'dark-blue': #121a2d,
                'light-blue': #f5f7fd,
        ),
        'variations': (
                'black': #0d0d0d,
                'dark-grey': #4b4b4b,
        ),
        'bw-transparent': (
                'black-0': rgba(#000000, 0),
                'black-05': rgba(#000000, 0.05),
                'black-10': rgba(#000000, 0.1),
                'black-20': rgba(#000000, 0.2),
                'black-30': rgba(#000000, 0.3),
                'black-40': rgba(#000000, 0.4),
                'black-50': rgba(#000000, 0.5),
                'black-60': rgba(#000000, 0.6),
                'black-70': rgba(#000000, 0.7),
                'black-80': rgba(#000000, 0.8),
                'black-90': rgba(#000000, 0.9),
                'black-100': rgba(#000000, 1),
                'dark-blue-0': rgba(#121a2d, 0),
                'dark-blue-05': rgba(#121a2d, 0.05),
                'dark-blue-10': rgba(#121a2d, 0.1),
                'dark-blue-20': rgba(#121a2d, 0.2),
                'dark-blue-30': rgba(#121a2d, 0.3),
                'dark-blue-40': rgba(#121a2d, 0.4),
                'dark-blue-50': rgba(#121a2d, 0.5),
                'dark-blue-60': rgba(#121a2d, 0.6),
                'dark-blue-70': rgba(#121a2d, 0.7),
                'dark-blue-80': rgba(#121a2d, 0.8),
                'dark-blue-90': rgba(#121a2d, 0.9),
                'dark-blue-100': rgba(#121a2d, 1),
                'white-0': rgba(#ffffff, 0),
                'white-05': rgba(#ffffff, 0.05),
                'white-10': rgba(#ffffff, 0.1),
                'white-20': rgba(#ffffff, 0.2),
                'white-30': rgba(#ffffff, 0.3),
                'white-40': rgba(#ffffff, 0.4),
                'white-50': rgba(#ffffff, 0.5),
                'white-60': rgba(#ffffff, 0.6),
                'white-70': rgba(#ffffff, 0.7),
                'white-80': rgba(#ffffff, 0.8),
                'white-90': rgba(#ffffff, 0.9),
                'white-100': rgba(#ffffff, 1),
        ),
        'forms': (
                'red': #ff1a1a,
                'success-green': #6dd348,
                'green': #70c645,
                'yellow-green': #F3F3F1,
        )
);
