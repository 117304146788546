.c-client-logos {

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    margin-bottom: getSpacing('lg');
    width: percentage(1/6);

    &:nth-child(6n) {
      margin-right: 0;
    }

    @include mq(lg) {
      margin-bottom: getSpacing('md');
      width: percentage(1/5);
    }

    @include mq(md) {
      margin-bottom: getSpacing('md');
      width: percentage(1/4);
    }

    @include mq(sm) {
      margin-bottom: getSpacing('sm');
      width: percentage(1/3);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    img {
      opacity: 0.85;
      max-width: 100%;
      width: 70%;
      transition: getTransition('default', 'ease-out-quad');
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}