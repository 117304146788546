//PIN
.o-pin {
    display: block;
}

//modifiers
.o-pin--activities-scroller {
    position: absolute;
    top: getSpacing('xl');

    @include mq(md) {
        top: - getSpacing('md');
    }
}