.c-tag {

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        border-radius: getBorderRadius('xs');
        //color: getColor('white', 'primary');
        //@include font-map('b5');
        //font-weight: 600;
        padding: 2px 8px;
        margin-bottom: 5px;
        display: flex;

        span {
            color: getColor('white');
            margin: 0;
        }

        &--development {
            background-color: getColor('dark-blue-80', 'bw-transparent');
        }

        &--design {
            background-color: getColor('dark-blue-70', 'bw-transparent');
        }

        &--marketing {
            background-color: getColor('dark-blue-60', 'bw-transparent');
        }

        &--consultation {
            background-color: getColor('dark-blue-50', 'bw-transparent');
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
}

.c-featured-work {
    .c-tag {
        margin-top: getSpacing('sm');
    }
}

.work-single-info {
    .c-tag {
        &__item {
            &--development {
                background-color: getColor('black-50', 'bw-transparent');
            }

            &--design {
                background-color: getColor('black-40', 'bw-transparent');
            }

            &--marketing {
                background-color: getColor('black-30', 'bw-transparent');
            }

            &--consultation {
                background-color: getColor('black-20', 'bw-transparent');
            }
        }
    }
}