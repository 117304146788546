/* The container must be positioned relative: */
.custom-select {
  position: relative;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  color: getColor('dark-blue');
  background-color: getColor('white');
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: getColor('dark-blue');
  padding: getSpacing('xs') getSpacing('xs-A');
  cursor: pointer;
}
.select-selected {
  border-radius: getBorderRadius('default');
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: getColor('white');
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  color: getColor('white');
  background-color: getColor('dark-blue');
}

.c-form--light {
  .select-items div,
  .select-selected {
    color: getColor('dark-blue');
    background-color: getColor('white');
  }

  .select-items div:hover,
  .select-items div.same-as-selected {
    color: getColor('dark-blue');
    background-color: getColor('black-10', 'bw-transparent');
  }
}

.c-form--dark {
  .select-items div,
  .select-selected {
    color: getColor('dark-blue');
    background-color: getColor('black-10', 'bw-transparent');
  }

  .select-items div:hover,
  .select-items div.same-as-selected {
    color: getColor('white');
    background-color: getColor('dark-blue');
  }
}
