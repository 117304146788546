//TESTIMONIALS
.c-testimonials {
    position: relative;

    &__item {

    }

    &__inner {
        display: flex;
        align-items: flex-end;

        @include mq(md) {
            flex-wrap: wrap;
        }
    }

    &__image {
        width: percentage(7/17.5);

        @include mq(md) {
            width: 100%;
            order: 2;
            max-width: 280px;
            margin: 0 auto;
        }
    }

    &__text {
        height: 100%;
        width: percentage(10.5/17.5);
        padding-bottom: getSpacing('xl', 'fluid');
        margin-bottom: getSpacing('lg');

        @include mq(md) {
            width: 100%;
            order: 1;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        //overrides
        .u-a2 {
            max-width: 10 * $settings-grid-column-width;
            margin-bottom: getSpacing('md');
            @include font-map('a2--quote');
        }
    }

    &__navigation {
        padding: getSpacing('sm');
    }

    &__navigation-inner {
        float: right;
        width: percentage(10.5/17.5);

        @include mq(md) {
            width: 100%;
            text-align: center;
        }
    }

    &__navigation-item {
        display: inline-block;
        vertical-align: middle;

        @include mq(md) {
            display: block;
        }

        &#{&}--arrow-left,
        &#{&}--arrow-right {
            padding: getSpacing('sm');
            cursor: pointer;

            .icon {
                font-size: 14px;
            }
        }

        &#{&}--arrow-left {
            margin-left: - getSpacing('sm');

            @include mq(md) {
                margin-left: 0;
            }
        }

        &#{&}--counter {
            @include font-map('b6');
            letter-spacing: 0.2px;
            @include font-default();
            padding: 0 getSpacing('xs');
        }
    }
}