$window-popup-width: 400px;
$window-popup-wide-width: 750px;

$window-popup-inner-padding: 25px;
$window-popup-close-position: -10px;

$window-popup-space-around: 10px;
$window-popup-space-around-big: 20px;

$window-popup-button-icon-gap: 10px;

.window-popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: $window-popup-space-around;
  max-height: calc( 100% - 2 * var($window-popup-space-around) );
  left: $window-popup-space-around;
  padding: $window-popup-inner-padding 0;
  z-index: 10001;
  transform: scaleX(0);
  width: calc( 100% - 2 * var($window-popup-space-around) );
  will-change: transform;

  background: getColor('white');
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .12);

  // ition: $transition transform;
  border-radius: getBorderRadius('default');

  .is-no-flex &, .is-safari &, .is-flex-popup-height-issue-detected & {
    display: block;
    //.clearfix();
  }

  .is-no-flex &, .is-safari &, .is-flex-popup-height-issue-detected & {
    display: block;
  }

  &--active {
    transform: scaleX(1);
  }

  &--standard {
    @media (min-width: 900px) {
      position: fixed;
      width: $window-popup-width;
      left: 50%;
      margin-left: -$window-popup-width/2;
    }
  }

  &--wide {
    @media (min-width: 901px) {
      width: calc( 100vw - 2 * var($window-popup-inner-padding) );
      max-height: calc( 100% - 2 * var($window-popup-inner-padding) - 2 * var($window-popup-space-around-big) );
      left: $window-popup-space-around-big;
      top: $window-popup-space-around-big;
    }

    @media (max-width: 900px) {
      position: fixed;
      width: $window-popup-wide-width;
      left: 50%;
      margin-left: -$window-popup-wide-width/2;
    }
  }

  &__in {
    width: 100%;
    max-height: calc( 100vh - 2 * var($window-popup-inner-padding) - 2 * var($window-popup-space-around-big) );
    flex: 1;
    padding: 0 $window-popup-inner-padding;
    overflow-y: auto;
  }

  &__close {
    position: absolute;
    top: $window-popup-close-position;
    right: $window-popup-close-position;
    width: $window-popup-inner-padding;
    height: $window-popup-inner-padding;
    line-height: $window-popup-inner-padding;
    text-align: center;

    color: getColor('red');
    text-decoration: none;
    font-weight: bold;
    font-size: 10px;

    background: getColor('white');
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, .12);

    //#border-radius(50%);
    border-radius: getBorderRadius('default');


    &:hover {
      text-decoration: none;
    }
  }

  &__actions {
    padding: $window-popup-inner-padding/2 $window-popup-inner-padding;
    width: 100%;
    text-align: center;

    @media (max-width: 767px) {
    padding-bottom: 0;
  }

    &--multiple-buttons {
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .is-no-flex & {
        display: block;
        //.clearfix();
      }
    }

    &__btn {
      width: 100%;

      @media (max-width: 767px) {
        width: auto;
      }

      .window-popup__actions--multiple-buttons & {
        margin: 2px 0;
      }

      i {
        position: relative;
        top: 0;

        font-size: 12px;
      }

      &--cancel {
        i {
        }

      @media (max-width: 767px) {
        order: -1;
      }

        .window-popup__actions--multiple-buttons & {
          .is-no-flex & {
            float: left;
          }
        }

      }

      &--continue {
        i {
        }

        .window-popup__actions--multiple-buttons & {
          .is-no-flex & {
            float: right;
          }
        }
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    will-change: background;

    // transition: $transition background;

    &--active {
      background: rgba(255,255,255, 0.75);
    }
  }

  ul {
    margin: 5px 0;
    font-size: .75rem;

    li {
      font-weight: 700;

      ul {
        li {
          font-weight: 300;
        }
      }
    }
  }
}
