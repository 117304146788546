@mixin clearfix-global($className: 'clearfix') {

    .#{$className} {
        display: inline-block;
    }

    * html .#{$className} {
        height: 1%;
    }

    .#{$className} {

        display: block;

        &:after {
            height: 0;
            clear: both;
            font-size: 0;
            content: "\A";
            display: block;
            visibility: hidden;
        }

    }

}

@mixin clearfix($display: block) {

    display: $display;

    &:after {
        height: 0;
        clear: both;
        font-size: 0;
        content: "\A";
        display: block;
        visibility: hidden;
    }

}