//CONTACT PAGE SPECIFIC STYLES
.c-contact {
  display: flex;
  align-items: start;

  @include mq(md) {
    flex-flow: column;
  }

  &__left {
    width: percentage(2/3);

    @include mq(md) {
      margin-bottom: getSpacing(md);
      width: 100%;
    }
  }

  &__right {
    width: percentage(1/3);
    padding-left: get-vw($settings-grid-column-width);

    @include mq(md) {
      width: 100%;
    }
  }
}