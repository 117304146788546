//WORK LIST
.c-work {

    //max-width: percentage(5.5/17.5);

    &__list {
        position: relative;
        z-index: 2;
    }

    &__item {
        display: block;

        a {
            display: block;
        }
    }
}

.c-work-item {
    position: relative;
    display: block;
    cursor: pointer;
    padding: getSpacing('md') 0;
    color: getColor('dark-blue');

    &__number {
        position: absolute;
        display: inline-block;
        right: 100%;
        top: 25px;
        margin-right: 25px;
        pointer-events: none;
        transition: getTransition('default-delay', 'ease-out-quad');
        will-change: transform, opacity;
    }

    &__title {
        transition: getTransition('default', 'ease-out-quad');
        will-change: transform;

        //overrides
        .u-a6 {
            font-weight: 500;
            mix-blend-mode: difference;
        }
    }

    &__category {
        display: inline-block;
        transition: getTransition('default-delay', 'ease-out-quad');
        will-change: transform;

        i {
            font-style: normal;
            display: inline-block;
            margin-right: 8px;
        }
    }

    .c-tag {
        opacity: 0;
        max-width: 500px;
        transition: getTransition('default-delay', 'ease-out-quad');
    }

    h3.u-b0 {
        line-height: 1.3;
        font-size: .8rem;
        opacity: 0;
        max-width: 500px;
        transition: getTransition('default-delay', 'ease-out-quad');
    }

    //hover
    @include mq(null, 'lg') {

        &:hover {

            .c-tag {
                opacity: 1;
                transform: translateX(10px);
            }

            h3.u-b0 {
                opacity: 1;
                transform: translateX(10px);
            }

            //overrides
            .c-work-item__title {
                transform: translateX(10px);
            }

            .c-work-item__number {
                opacity: 0;
                transform: translateX(20px);
            }

            .c-work-item__category {
                transform: translateX(10px);
            }
        }
    }
}


//WORK PREVIEW
.c-work-preview {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    //padding-right: $settings-grid-column-width;
    pointer-events: none;
    z-index: 1;

    &__canvas {
        display: block;
        position: absolute;
        top: 0;
        right: $settings-grid-column-width;
        width: percentage(12/24);
        height: 100%;

        .canvas {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            left: 0;
            height: 0;
            padding-bottom: percentage(300/450);
            overflow: hidden;
            border-radius: getBorderRadius('default');
        }

        canvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__list {
        display: block;
        position: absolute;
        top: 0;
        right: $settings-grid-column-width;
        width: percentage(12/24);
        height: 100%;
    }

    &__item {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        left: 0;
        height: 0;
        padding-bottom: percentage(300/450);
        overflow: hidden;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        //
        opacity: 0;
        visibility: hidden;

        //STATE
        &.is-active {
            //opacity: 1;
            //visibility: visible;
        }
    }
}

//WORK TRANSIITON
.c-work-transitions {
    position: fixed;
    top: 50%;
    right: 60px;
    height: 0;
    width: 0;
    z-index: getZindex('loader');
}
