// WORK SINGLE VISUAL

.c-work-single-visual {
    position: absolute;
    bottom: -50px;
    right: 0;
    width: percentage(14/24);

    @media (min-width: 900px) and (max-height: 800px){
        width: percentage(12/24);
    }

    @media (min-width: 900px) and (max-height: 700px){
        width: percentage(10/24);
    }

    @media (min-width: 900px) and (max-height: 600px){
        width: percentage(8/24);
    }

    @include mq(md) {
        width: 100vw;
        bottom: -30px;
        left: 0;
    }

    &__image {
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: percentage(10.5/16);
        background-size: cover;
    }
}