// MAIN NAVIGATION COMPONENT
.c-navigation {
    //width: $navigation-width;
    //width: percentage(1.5/24);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: getZindex('navigation', +1);

    @include mq(md) {
        width: 0;
    }

    //MODIFIERS
    //light version
    .c-navigation--light {

    }

    //STATE
    &.is-opened {

    }

    &__trigger {
        position: absolute;
        top: 10px;
        z-index: 30;
        width: 100%;
        left: calc(100% - 80px);

        @include mq(md) {
            left: calc(100% - 60px);
        }

        .is-mobile & {
            padding-left: percentage(2.5/22.5);
            padding-right: percentage(1.5/22.5);

            @include mq(md) {
                top: 10px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__logo {
        position: absolute;
        top: 1rem;
        z-index: 4;
        width: 100%;
        padding-left: 40px;

        .is-mobile & {
            padding-left: percentage(2.5/22.5);
            padding-right: percentage(1.5/22.5);

            @include mq(md) {
                top: 40px;
                padding-left: getSpacing('lr-fixed', 'mobile');
                padding-right: getSpacing('lr-fixed', 'mobile');
            }
        }

        img {
            width: 300px;
            height: auto;
        }
    }

    &__menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        z-index: 1;
        transform: translateX(-100%);
        will-change: transform;
        overflow: hidden;

        // GLOBAL STATE
        //.is-mobile & {
        //    padding-left: 0;
        //}

        //STATE
        .is-opened & {

        }
    }

    &__menu-left {
        //width: percentage(7/22.5);
        //min-width: 420px;
        width: 46.666666662vh;
        background-color: getColor('white');
        height: 100%;
        display: flex;
        align-items: center;
        will-change: transform;
        overflow: hidden;
        z-index: 4;
        position: absolute;
        left: 0;
        top: 0;

        @include mq(lg) {
            width: 36.666666662vh;
        }

        @include mq(md) {
            width: 0;
        }

        .is-opened & {

        }

        //overrides
        .c-logo {
            margin: 0 auto;

            @include mq(sm) {
                display: none;
            }
        }
    }

    &__menu-right {
        background-color: getColor('white');
        width: 100%;
        padding-left: 46.666666662vh;
        height: 100vh;
        will-change: transform;
        position: relative;
        z-index: 3;

        @include mq(lg) {
            padding-left: 36.666666662vh;
        }

        @include mq(md) {
            padding-left: 20px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 90px;
            right: 46.666666662vh;
            background-color: getColor('white');
            display: block;
            z-index: 3;
        }

        // GLOBAL STATE
        //.is-mobile & {
        //    width: 100%;
        //    padding-left: percentage(2.5/22.5);
        //    padding-right: percentage(1.5/22.5);
        //
        //    @include mq(md) {
        //        padding-left: getSpacing('lr-fixed', 'mobile');
        //        padding-right: getSpacing('lr-fixed', 'mobile');
        //        padding-top: getSpacing('sm');
        //        padding-bottom: getSpacing('sm');
        //    }
        //}

        .is-opened & {

        }

        //overrides
        //.c-contact-info {
        //    width: auto;
        //    position: absolute;
        //    bottom: 40px;
        //    right: 46.666666662vh;
        //    margin-right: percentage(1/24);
        //
        //    .is-mobile & {
        //        right: percentage(1.5/22.5);;
        //        margin-right: 0;
        //    }
        //}

        .c-cookie-privacy-info {
            width: auto;
            position: absolute;
            bottom: 40px;
            right: 46.666666662vh;
            margin-right: percentage(1/24);

            .is-mobile & {
                right: percentage(1.5/22.5);;
                margin-right: 0;
            }
        }

        .c-button--text {

            &:before {
                transform: scale3d(0, 1, 1);
                transform-origin: 100% 50%;
            }

            //hover
            @include mq(null, 'lg') {

                &:hover {

                    &:before {
                        transform: scale3d(1, 1, 1);
                        transform-origin: 0% 50%;
                        transition-timing-function: ease;
                    }
                }
            }

            //state
            &.is-active {
                &:before {
                    transform: scale3d(1, 1, 1);
                    transform-origin: 0% 50%;
                }
            }
        }
    }
}

//NAVIGATION LINKS
.navigation-links-wrapper {
    width: 100%;
    position: relative;
    height: 100vh;
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: percentage(1.5/17);
    display: flex;
    align-items: center;

    &__inner {
        position: absolute;
        left: 0;
        width: 100%;
        max-height: 100%;
        padding-top: 120px;
        padding-bottom: 60px;
        padding-left: percentage(1.5/17);
        padding-right: percentage(1.5/17);
        overflow: auto;

        @include mq(lg) {
            padding-top: 90px;
        }

        @include mq(md) {
            padding-top: 60px;
        }

        .is-mobile & {
            padding-left: 0;
            padding-right: 0;
        }

        .is-ie & {
            top: 0;
        }
    }

}

.navigation-links {

    //modifiers
    &#{&}--top {
        padding-bottom: getSpacing('sm');

        //overrides
        .navigation-links__item {
            margin-bottom: getSpacing('sm');

            @include mq(md) {
                margin-bottom: getSpacing('xs');
            }

            .c-button {
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }
    }

    &#{&}--bottom {

        //overrides
        .navigation-links__item {

            @include mq(md) {
                margin-bottom: 0;
            }
        }
    }

    &#{&}--locale {
        display: flex;

        //overrides
        .navigation-links__item {
            transition: transform 0.35s;

            &:not(:last-child) {
                margin-right: 30px;
            }

            @include mq(md) {
                margin-right: 0;
                margin-bottom: 30px;
            }

            a {
                display: block;

                img {
                    height: 30px;
                    width: auto;
                }
            }

            &.active {
                a {
                    img {
                        opacity: .5;
                    }
                }
            }
        }
    }

    &__item {

    }
}

.navigation-link {
    span {

    }
}

.c-navigation-email {
    display: block;
    width: 20px;
    height: 180px;
    writing-mode: vertical-rl;
    transform-origin: center;
    transform: rotate(-180deg);

    a {
        display: block;
    }
}

.c-menu-bg {
    &__cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 46.766666662vh;
        height: 100%;
        background-color: getColor('white');
        z-index: 5;
        pointer-events: none;
    }

    &__canvas {
        height: 100vh;
        //padding-bottom: percentage(900/420);
        //position: absolute;
        //top: 50%;
        width: 100%;
        //transform: translateY(-50%);

        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        transform: translateX(-50%) translateY(-50%);

        .canvas, canvas, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .canvas {
            z-index: 2;
        }

        img {
            z-index: 1;
        }
    }

    &__list {
        display: none;
    }

    &__image {
        opacity: 0;
        visibility: hidden;
        background-size: cover;
        background-position: center;
    }

    &__logo {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 50px;
        display: flex;
        vertical-align: middle;

        img {
            max-width: 100%;
            display: inline-block;
        }

        &-bg {
            height: 100vh;
            width: 100%;
            background-color: getColor('watermelon');
            position: relative;
            top: 50%;
            left: 50%;
            min-width: 100%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

.c-menu-bg,
.c-menu-bg__item,
.c-menu-bg__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
