//LOADER
.c-main-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: getZindex('loader');
    overflow: hidden;

    &__logo {
        position: relative;
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        z-index: 22;
        opacity: 0;
        visibility: hidden;
        text-align: center;

        @include mq(lg) {
            max-width: 320px;
        }

        @include mq(md) {
            max-width: 180px;
        }

        img,
        svg {
            max-width: 100%;
            display: block;
        }
    }

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        will-change: visibility, opacity, transform;
        backface-visibility: hidden;
    }

    &__image {
        display: block;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        transform: scale(1.15);
        overflow: hidden;
        background-size: cover;
        background-position: center;
        will-change: opacity, visibility;
        backface-visibility: hidden;
    }

    &__curtain {
        display: block;
        height: 5px;
        width: 0;
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0;
        background-color: getColor('white');
        z-index: 99;
    }
}