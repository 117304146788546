.c-morph {
  position: relative;
  display: inline-block;
  z-index: -1;

  &.is-opening,
  &.is-opened {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: getZindex('morph');
  }

  &__transition {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    background-color: getColor('white');
    visibility: hidden;
    z-index: getZindex('morph-transition');
  }

  &__modal {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    background-color: getColor('white-0', 'bw-transparent');
    z-index: getZindex('morph-modal');
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;

    &-inner {
      position: relative;
      height: 100%;
      width: 100%;
    }

    &-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      height: 100%;
      max-width: 800px;
      margin: 0 auto;

      @include mq(sm) {
        padding: getSpacing('lg') getSpacing('sm') getSpacing('sm') getSpacing('sm');
        overflow-y: scroll;
      }
    }

    .c-menu-trigger {
      position: absolute;
      top: 10px;
      right: 30px;
      left: auto;
      z-index: getZindex(morph-modal-close);

      @include mq(md) {
        right: 10px;
      }
    }
  }

  &.is-opened {
    .c-morph__content {
      //position: fixed;
    }
  }
}

//FORM MODIFIERS
.c-morph {
  &__modal {
    &-content {
      .c-form {
        @include mq(md) {
          fieldset.choose-project--first,
          .c-fieldset.choose-project--first {
            padding-bottom: getSpacing(md);
          }
        }
      }
    }
  }
}

.c-morph {
  &__modal {
    &-content {
      .c-form {
        @include mq(sm) {

          .c-form__row {
            margin-bottom: getSpacing('zero');
          }

          fieldset,
          .c-fieldset {
            padding-top: getSpacing(sm);
          }

          fieldset.choose-project--first,
          .c-fieldset.choose-project--first {
            padding-bottom: getSpacing(md);
          }

          .c-form__row--submit {
            fieldset,
            .c-fieldset {
              padding-top: getSpacing(sm);
            }
          }

          input,
          textarea,
          select {
            //padding: getSpacing('xxs') getSpacing('xs-A');
          }

          .c-label {
            top: 7px;
          }

          fieldset,
          .c-fieldset {
            &.is-filled {
              label,
              .c-label {
                transform: translateY(-30px);
              }
            }
          }

          .c-checkbox__label {
            padding-left: 35px;
          }

          [type="checkbox"]:not(:checked) + label:before,
          [type="checkbox"]:checked + label:before {
            top: 1px;
          }

          [type="checkbox"]:not(:checked) + label:after,
          [type="checkbox"]:checked + label:after {
            top: 4px;
          }
        }
      }
    }
  }
}
