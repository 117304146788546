//MENU TRIGGER
.c-menu-trigger {
    display: block;
    padding: 20px 0;
    width: 50px;
    height: 78px;
    cursor: pointer;

    &__bar {
        display: inline-block;
        width: 50px;
        height: 4px;
        float: left;
        margin-bottom: 12px;
        padding: 0;
        background-color: getColor('dark-blue');
        position: relative;
        transition: getTransition('slide', 'ease-out-quad');
        backface-visibility: hidden;
        border-radius: getBorderRadius('default');
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-color: getColor('dark-blue', 'primary');
            will-change: transform, color;
            transform: scale3d(0,1,1);
            transform-origin: 100% 50%;
            transition-timing-function: map_get($settings-transitions-easing, 'ease-out-quad');
        }

        &#{&}--top {
            &:before {
                transition: transform 0.35s;
            }
        }

        &#{&}--middle {
            &:before {
                transition: transform 0.35s 0.1s;
            }
        }

        &#{&}--bottom {
            margin-bottom: 0;

            &:before {
                transition: transform 0.35s 0.2s;
            }
        }
    }

    //hover
    @include mq(null, 'lg') {
        &:hover {
            .c-menu-trigger__bar {
                &:before {
                    transform: scale3d(1,1,1);
                    transform-origin: 0% 50%;
                    transition-timing-function: ease;
                }
            }
        }
    }

    //STATE
    &.is-disabled {
        pointer-events: none;
    }

    &.is-opened {
        .c-menu-trigger__bar--top {
            transform: translateY(15px) rotate(-45deg);
        }

        .c-menu-trigger__bar--middle {
            transform: scaleX(0);
        }
        .c-menu-trigger__bar--bottom {
            transform: translateY(-15px) rotate(45deg);
        }

        &:hover {
            .c-menu-trigger__bar {
                &:before {
                    background-color: getColor('watermelon', 'primary');
                }
            }
        }
    }
}
