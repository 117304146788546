//CLIP ELEMENT
.c-clip-element {
    position: fixed;
    z-index: getZindex('navigation');

    //MODIFIERS
    &--logo {
        width: getLogoSize('width');
        height: getLogoSize('height');
        transform: translateY(20px) translateX(40px);

        .is-mobile & {
            position: absolute !important;
        }

        @include mq(md) {
            width: getLogoSize('width');
            height: getLogoSize('height');
            transform: translateY(20px) translateX(16px);
        }

        .is-menu-opened & {

        }

        .crealab {
            width: getLogoSize('width');
            height: getLogoSize('height');

            &.crealab--dark {
                display: none;
            }

            &.crealab--colored {
                display: none;
            }
        }
    }

    &--menu-trigger {
        width: 50px;
        height: 78px;
        right: 0 !important;
        left: auto !important;
        /* autoprefixer: ignore next */
        //-ms-transform: translateY(50vh) translateY(-29px);
        //transform: translateY(calc(50vh - 29px));
        transform: translateY(10px) translateX(-40px);

        .is-mobile & {
            position: absolute !important;
        }

        @include mq(md) {
            width: 50px;
            height: 78px;
            left: auto !important;
            transform: translateY(16px) translateX(-20px);
        }
    }

    &--email {
        width: 20px;
        height: 210px;
        margin-left: 40px;
        bottom: 0 !important;
        /* autoprefixer: ignore next */
        -ms-transform: translateY(100vh) translateY(-210px);
        transform: translateY(calc(100vh - 210px)) translateX(0);
        //transition: all 0.2s 0.2s ease-out;


        .is-menu-opened & {

        }

        @include mq(md) {
            display: none;
        }
    }
}


//CLIP NAVIGATION
.clip-header {

    &.is-black {
        z-index: 2;

        .crealab--dark {
            display: flex;
        }

        .crealab--light {
            display: none;
        }

        .crealab--colored {
            display: none;
        }
    }

    &.is-colored {
        z-index: 2;

        .crealab--light {
            display: none;
        }

        .crealab--dark {
            display: none;
        }

        .crealab--colored {
            display: flex;
        }
    }

    &.is-white {
        z-index: 1;

        .crealab--dark {
            display: none;
        }

        .crealab--colored {
            display: none;
        }

        .crealab--light {
            display: flex;
        }

        //overrides
        .c-logo {

        }

        .c-menu-trigger__bar {
            background-color: getColor('white');

            .is-menu-opened & {
                background-color: getColor('dark-blue');
            }
        }

        .c-navigation-email {
            color: getColor('white');
        }

        .c-secondary-navigation__left {
            .c-button--text {
                color: getColor('white');

                &:before {
                    background-color: getColor('white-10', 'bw-transparent');
                }

                &.is-active {
                    color: getColor('white');

                    &:before {
                        background-color: getColor('white');
                    }
                }
            }
        }

        .c-secondary-navigation__right {
            .c-button--primary {
                border: 1px solid getColor('white');
                color: getColor('dark-blue');
                background-color: getColor('white');

                &:before {
                    background-color: getColor('dark-blue');
                }

                &:hover {
                    span {
                        color: getColor('white');
                    }
                }
            }
        }

        .c-secondary-navigation-links-mobile {

            .c-button--text {
                color: getColor('white');

                &.is-active {
                    color: getColor('white');

                    &:before {
                        background-color: getColor('white');
                    }
                }
            }
        }
    }
}

.clip-inner {
    overflow: hidden !important;

    &.is-white {

    }
}