.c-full-width-image {
    background-color: getColor('black-10', 'bw-transparent');
    pointer-events: none;
    //overrides

    .is-mobile & {
        .o-media-wrapper--4-3 {
            padding-bottom: percentage(590/755);
        }
    }
}