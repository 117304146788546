.c-culture {

  &__heading {
    padding-bottom: getSpacing('xl');

    @include mq(md) {
      padding-top: getSpacing('lg');
      padding-bottom: getSpacing('lg');
    }

    //overrides
    .u-a4 {
      max-width: 16.5 * $settings-grid-column-width;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    @include mq(md) {
      display: block;
    }
  }

  &__full {
    width: percentage(17.5/17.5);
  }

  &__left {
    width: percentage(10/17.5);
    padding-right: percentage(1.5/17.5);

    @include mq(md) {
      width: 100%;
      padding-right: 0;
    }

    //overides
    .u-b2 {
      max-width: 6.5 * $settings-grid-column-width;
    }

    .o-media-wrapper {
      margin-top: getSpacing('xxl', 'fluid');
    }

    .c-article-list {
      padding-top: 0;
    }

    .c-article {
      &:nth-child(1),
      &:nth-child(2) {
        padding-bottom: getSpacing('md');
      }

      .c-list {
        padding: getSpacing('sm') 0 0 0;

        &-item {
          padding: getSpacing('xxs') 0;
        }
      }
    }
  }

  &__right {
    width: percentage(7.5/17.5);

    @include mq(md) {
      width: 100%;
      margin-top: getSpacing('lg');
    }
  }
}