//SECONDARY NAVIGATION
.c-secondary-navigation {
    height: 38px;
    position: fixed;
    transform: translateY(26px) translateX(0);
    left: 0;
    width: 100%;
    padding-top: getSpacing('xs');
    padding-bottom: getSpacing('xs');
    z-index: getZindex('navigation', -2);
    //
    //padding-left: $navigation-width;
    padding-left: percentage(2.5/24);
    padding-right: percentage(0.5/24);
    display: flex;
    align-items: center;
    justify-content: space-between;
    //
    pointer-events: none;

    @include mq(md) {
        //display: none; // TODO: will have to rethink and rewrite
        transform: translateY(26px) translateX(0);
    }

    &__left {
        //
        pointer-events: all;

        @include mq(md) {
            width: 100%;
            text-align: center;
        }

        .is-menu-opened & {

        }
    }

    &__right {
        //
        pointer-events: all;
        position: relative;

        .is-menu-opened & {

        }
    }

    //overrides
    .c-button--text {
        &:before {
            display: none;
        }
    }

    .clip-inner {
        width: 100%;
        //padding-left: $navigation-width;
        padding-left: percentage(2.5/24);
        padding-right: percentage(0.5/24);
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq(lg) {
            padding-left: percentage(3/24);
        }

        @include mq(md) {
            padding-left: percentage(4/24);
            padding-right: percentage(4/24);
        }
    }

    .c-button--text {
        color: getColor('dark-blue');

        &:before {
            transform: scale3d(0,1,1);
            transform-origin: 100% 50%;
            background-color: getColor('black-10', 'bw-transparent');
        }

        &:hover {
            &:before {
                transform: scale3d(1,1,1);
                transform-origin: 0% 50%;
            }
        }

        &.is-active {
            color: getColor('dark-blue');

            &:before {
                display: block;
                background-color: getColor('dark-blue');
                transform: scale3d(1,1,1);
                transform-origin: 0% 50%;
            }
        }
    }

    .c-button--bordered {

        &.is-active {

            &:after {
                background-color: getColor('dark-blue');
            }
        }
    }

    .c-button--primary {
        &.is-hovered {

        }
    }
}

.c-secondary-navigation-links {
    padding-left: getSpacing('sm');

    @include mq(lg) {
        padding-left: 0;
    }

    &__item {
        display: inline-block;
        position: relative;
        margin-right: getSpacing('sm');

        @include mq(md) {
            margin-left: getSpacing('xs');
            margin-right: getSpacing('xs');
        }
    }
}

//MOBILE
.c-secondary-navigation-mobile {
    width: 100%;
    position: absolute !important;
    top: 5rem !important;
    left: 0;
    padding: 0 getSpacing('lr-fixed', 'mobile');
    z-index: 3;

    //overrides
    .c-button {
        .icon {
            margin-left: 0;
        }
    }

    .clip-inner {
        width: 100%;
        //padding-left: $navigation-width;
        padding: 0 getSpacing('lr-fixed', 'mobile');
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .c-button--text {
        color: getColor('dark-blue');

        &:before {
            transform: scale3d(0,1,1);
            transform-origin: 0% 50%;
            background-color: getColor('black-10', 'bw-transparent');
        }

        &.is-active {
            color: getColor('dark-blue');

            &:before {
                display: block;
                background-color: getColor('dark-blue');
                transform: scale3d(1,1,1);
                transform-origin: 0% 50%;
            }
        }
    }

    .c-button--bordered {

        &.is-active {

        }
    }

    .c-button--primary {
        &.is-hovered {

        }
    }
}

.c-secondary-navigation-links-mobile {

    &__item {
        display: inline-block;
        margin-right: getSpacing('sm');
    }
}