$in-overlay-spinner-size: 28px;

.in-overlay {
  position: relative !important;

  &__in {
    position: fixed;
    bottom: 0px;
    right: 0px;
    top: 0px;
    left: 0px;
    z-index: 499;
    text-align: center;

    background: rgba(255, 255, 255, 0.5);

    &--absolute {
      position: absolute;
    }

    &--light {
      display: block;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  &__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: $in-overlay-spinner-size;
    min-height: $in-overlay-spinner-size;
    margin-top: -$in-overlay-spinner-size / 2;
    margin-left: -$in-overlay-spinner-size / 2;
    line-height: $in-overlay-spinner-size;
    text-align: center;

    &--absolute {
      position: absolute;
    }

    &__icon {
      display: inline-block;
      width: $in-overlay-spinner-size;
      height: $in-overlay-spinner-size;
      line-height: $in-overlay-spinner-size;

      //&:extend(.svg);
      //&:extend(.svg:before);
      //.svg-spinner-regular();

      will-change: transform;
      opacity: .3;

      font-size: $in-overlay-spinner-size;
      animation: spin 2s linear infinite;
      color: getColor('dark-blue');

      &:before {
        display: block;
        line-height: $in-overlay-spinner-size;
        height: $in-overlay-spinner-size;
        width: $in-overlay-spinner-size;
      }
    }

    &__message {
      display: block;
      margin-top: 10px;
    }
  }
}
