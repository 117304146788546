.u-clip {
  //margin-top: calc(-3vw - 1px);

  &--left {
    -webkit-clip-path: polygon(0 0,100% 3vw,100% calc(100% - 3vw),0 100%);
    clip-path: polygon(0 0,100% 3vw,100% calc(100% - 3vw),0 100%);
  }

  &--right {
    -webkit-clip-path: polygon(0 3vw,100% 0,100% 100%,0 100%);
    clip-path: polygon(0 3vw,100% 0,100% 100%,0 100%);
  }

  &--bottom {
    .u-clip--left {
      -webkit-clip-path: polygon(0 0,100% 3vw,100% calc(100% - 3vw),0 100%);
      clip-path: polygon(0 0,100% 3vw,100% calc(100% - 3vw),0 100%);
    }

    .u-clip--right {
      -webkit-clip-path: polygon(0 3vw,100% 0,100% 100%,0 100%);
      clip-path: polygon(0 3vw,100% 0,100% 100%,0 100%);
    }
  }

  & + section {
    margin-top: -3vw;
  }
}
