@function getFont($fontName) {
    @if (map-has_key($settings-fonts, $fontName)) {
        @return map-get($settings-fonts, $fontName);
    } @else {
        @return map-get($settings-fonts, 'default');
    }
}

@function getFontWeight($fontWeight) {
    @if (map-has_key($settings-font-weight, $fontWeight)) {
        @return map-get($settings-font-weight, $fontWeight);
    } @else {
        @return map-get($settings-font-weight, 'default');
    }
}

@function getFontMap($fontMapKey) {
    @if (map-has_key($settings-font-map, $fontMapKey)) {
        @return map-get($settings-font-map, $fontMapKey);
    } @else {
        @return map-get($settings-font-map, 'default');
    }
}

@function getDefaultFontSize() {
    @return map-get($settings-font-size, 'font-size');
}

@function getDefaultLineHeight() {
    @return map-get($settings-font-size, 'line-height');
}

@function getDefaultFontSizeUnit() {
    @return map-get($settings-font-size, 'default-unit');
}

@function line-height($fontSize, $lineHeight: $fontSize) {
    @return $lineHeight/$fontSize;
}

@function em($pixels, $context: getDefaultFontSize()) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1em;
}

@function rem($pixels, $context: getDefaultFontSize()) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1rem;
}

@mixin font-map($mapKey: 'default', $unit: getDefaultFontSizeUnit()) {

    @each $breakpoint, $font-size in getFontMap($mapKey) {

        @if $breakpoint == null {

            $fs: $font-size;

            @if type-of($font-size) == 'string' {
                $fs: number(nth(str-two-split($font-size, '/'), 1));
                $lh: number(nth(str-two-split($font-size, '/'), 2));
                line-height: line-height($fs, $lh);
            }

            @if $unit == rem {
                font-size: rem($fs);
            } @else if $unit == em {
                font-size: em($fs);
            } @else {
                font-size: $fs#{$unit};
            }

        } @else {

            @include mq($to: $breakpoint) {

                $fs: $font-size;

                @if type-of($font-size) == 'string' {
                    $fs: number(nth(str-two-split($font-size, '/'), 1));
                    $lh: number(nth(str-two-split($font-size, '/'), 2));
                    line-height: line-height($fs, $lh);
                }

                @if $unit == rem {
                    font-size: rem($fs);
                } @else if $unit == em {
                    font-size: em($fs);
                } @else {
                    font-size: $fs#{$unit};
                }

            }

        }
    }

}

//CONVERT px TO vw
@function get-vw($target) {
    $vw-context: (1440 * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}

@mixin default-font-size {
    font-size: getDefaultFontSize() + px;
    line-height: line-height(getDefaultFontSize(), getDefaultLineHeight());
}

@mixin font-default($fontWeight: 'default') {
    font-family: getFont('default');
    font-weight: getFontWeight($fontWeight);
}

@mixin font-heading($fontWeight: 'semibold') {
    font-family: getFont('heading');
    font-weight: getFontWeight($fontWeight);
}