$settings-font-size: (
    'font-size': 20,
    'line-height': 30,
    'default-unit': rem
);

$settings-font-map: (
    'default': (null: '20/30', lg: '14/20'),
    'a8': (null: '140/140', lg: '95/100'),
    'a7': (null: '105/105', lg: '70/74', sm: '33/39'),
    'a7--highlight': (null: '105/105', lg: '70/74', sm: '50/52'),
    'a7--header': (null: '90/95', xl: '76/80',  lg: '60/65', md: '45/50', sm: '39/44'),
    //'a7': (null: '107/107', lg: '70/74', sm: '33/39'),
    //'a7--highlight': (null: '107/107', lg: '70/74', sm: '50/52'),
    'a6': (null: '80/82', lg: '54/54', md: '40/40'),
    'a6--highlight': (null: '90/90', lg: '60/62', md: '45/47'),
    'a5': (null: '65/70', lg: '46/48', md: '30/34'),
    'a4': (null: '47/58.75', lg: '38/47.5', md: '30/37.5'),
    'a4--quote': (null: '47/54', lg: '38/46', md: '24/32'),
    'a4--case': (null: '47/54', lg: '38/46', md: '30/36'),
    'a3': (null: '37/46', lg: '30/36', md: '20/27'),
    'a3--quote': (null: '37/46', lg: '30/36', md: '20/27'),
    'a2': (null: '30/36', lg: '24/32', md: '18/24'),
    'a2--quote': (null: '30/36', lg: '24/32'),
    'a1': (null: '24/32', lg: '20/30'),
    'b0': (null: '20/30', lg: '18/24', md: '14/20'),
    'b0--forms': (null: '20/30', sm: '15/20'),
    'b0--highlight': (null: '20/30'),
    'b1': (null: '20/30', lg: '18/24'),
    'b2': (null: '18/27', lg: '16/24', md: '14/21'),
    'b2--highlight': (null: '18/24'),
    'b3': (null: '16/21', lg: '14/20'),
    'b4': (null: '14/21'),
    'b5': (null: '12/18'),
    'b6': (null: '12/18'),
    'b7': (null: '10/10')
);