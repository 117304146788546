// MAIN FOOTER COMPONENT

.c-footer {
    background-color: #090e17;
    background-color: darken(getColor('dark-blue', 'sections'), 5%);
    position: relative;
    z-index: 2;
    height: 100vh;
    padding-top: getSpacing('xl', 'fluid');
    color: getColor('white');

    &.is-morph-opened {
        z-index: getZindex('morph-modal');
    }

    @include mq(md) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: getSpacing('xxl', 'fluid');

        @include font-map('a2');

        .c-list {
            padding: 2rem 0;
        }
    }

    @include mq(sm) {
        padding-top: getSpacing('lg', 'fluid');
    }

    &__top {
        margin-bottom: getSpacing('xxxl', 'fluid');

        @include mq(sm) {
            margin-bottom: getSpacing('xl', 'fluid');
        }
    }

    &__middle {
        @include mq(sm) {
            margin-bottom: getSpacing('md', 'fluid');
        }
    }

    &__bottom {
        .u-b4 {
            margin-bottom: 0;
        }
    }

    &__contact {
        .c-button {
            &.c-button--text {
                color: getColor('white');

                &:before {
                    background-color: getColor('white');
                }
            }
        }
    }

    &__address {
        span {
            display: block;
            padding: getSpacing('xxs') 0;
        }
    }

    &__privacy {
        width: 100%;
        text-align: right;
    }

    &__columns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        &--2 {
            .c-footer__column {
                width: percentage(1/2);

                &--left {
                    width: percentage(4.5/9.5);
                }

                &--right {
                    width: percentage(5/9.5);
                }
            }
        }

        &--3 {
            .c-footer__column {
                width: percentage(1/3);
            }
        }

        .c-footer__column {
            &--left {

            }

            &--right {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;

                .c-footer__address,
                .c-footer__partner {
                    width: 50%;
                }

                .c-footer__partner {
                    text-align: right;
                }
            }

            .c-list-item {
                padding: getSpacing('xxs') 0;
                border-bottom: 1px solid getColor('white-0', 'bw-transparent');

                @include mq(md) {
                    padding: 0.1rem 0;
                }
            }
        }

        @include mq(md-lg) {
            flex-flow: column;
            align-items: flex-start;
            justify-content: start;

            .c-footer__column {
                width: 100%;
            }
        }

        @include mq(sm) {
            .c-footer__column {
                width: 100%;
                flex-wrap: wrap;

                &--right {
                    .c-footer__menu {
                        width: 50%;
                    }

                    .c-footer__partner {
                        float: none;
                        text-align: left;
                        width: 100%;
                    }
                }
            }
        }
    }
}