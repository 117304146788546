//LIST
.c-list {
    padding: getSpacing('lg') 0;

    &__item {
        padding: getSpacing('xs') 0;

        //overrides
        .u-b3 {

        }
    }
}

.c-list-column {

    &__items {

    }

    &__heading {
        padding: getSpacing('xs-A') 0;
        //border-bottom: 2px solid getColor('light-grey', 'secondary');
        //border-bottom: 2px solid getColor('black-10', 'bw-transparent');
    }

}

.c-list-item {
    padding: getSpacing('xs-A') 0;
    //border-bottom: 1px solid getColor('light-grey', 'secondary');
    border-bottom: 1px solid getColor('black-10', 'bw-transparent');

    &--no-border {
        border-bottom: 0;
    }

    //overrides
    .u-b3 {

    }
}

//MODIFIERS
.c-list--multicolumn {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.c-list--bottom {
    align-items: flex-end;
}

//2 columns
.c-list--multicolumn-2 {

    .c-list-column {
        width: percentage(8/17.5);
    }
}

//3 columns
.c-list--multicolumn-3 {
    justify-content: flex-start;

    .c-list-column {
        width: percentage(1/3);

        // GLOBAL STATE
        .is-mobile & {

            @include mq(md) {
                width: 100%;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

//4 columns
.c-list--multicolumn-4 {
    justify-content: flex-start;

    .c-list-column {
        width: percentage(1/2);
        max-width: 2.5 * $settings-grid-column-width;
        margin-right: percentage(1/17.5);

        @include mq(lg) {
            max-width: 132px;
        }

        // GLOBAL STATE
        .is-mobile & {

            @include mq(md) {
                width: 100%;
                max-width: 100%;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

//5 columns
.c-list--multicolumn-5 {

    .c-list-column {
        width: percentage(3.5/17.5);
        padding-right: getSpacing('md');
    }
}

//UNDERLINED LIST
.c-underlined-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    max-width: 9.5 * $settings-grid-column-width;
    padding-top: getSpacing('md');
    margin-bottom: 0;

    @include mq(md) {
        padding-top: getSpacing('sm@ihrvoe');
    }

    &__item {
        width: 100%;
        padding: getSpacing('xs-A') 0;
        border-bottom: 1px solid getColor('light-grey', 'secondary');
    }

    &--multicolumn {
        .c-underlined-list__item {
            width: percentage(4.5 / 9.5);

            @include mq('sm') {
                width: 100%;
            }
        }
    }
}

//ICON LIST
.c-icon-list {

    //overrides
    .u-a1 {
        margin-bottom: getSpacing('xs');
    }

    .u-b2 {
        @include mq(md) {
            max-width: 320px;
        }
    }

    &__item {
        //padding-left: 2 * $settings-grid-column-width;
        padding-right: 2 * $settings-grid-column-width;
        position: relative;
        margin-bottom: getSpacing('lg');

        .is-mobile & {
            @include mq(md) {
                padding-left: 0;
                padding-right: 0;
            }

            &:last-of-type {
                @include mq(md) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__icon {
        position: absolute;
        left: 0;
        width: $settings-grid-column-width;
        top: getSpacing('xs');

        .icon {
            display: inline-block;
            width: 60px;
            height: 60px;
            border-radius: 100px;
            background-color: getColor('dark-blue');
        }
    }

    &__description {

    }
}
