.u-no-events {
    pointer-events: none;
}

.u-visually-hidden {
    display: none;
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
}