$settings-spacing: (

    'fixed': (
        'default': 4 * $settings-unit-rem,
        'zero': 0,
        'xxs': $settings-unit-rem / 2,
        'xs': $settings-unit-rem,
        'xs-A': 1.3 * $settings-unit-rem,
        'sm': 2 * $settings-unit-rem,
        'sm-A': 3 * $settings-unit-rem,
        'md': 4 * $settings-unit-rem,
        'lg': 6 * $settings-unit-rem,
        'lg-A': 8 * $settings-unit-rem,
        'xl': 10 * $settings-unit-rem,
        'xl-A': 12 * $settings-unit-rem,
        'xxl': 14 * $settings-unit-rem,
        'xxl-A': 18 * $settings-unit-rem,
        'xxxl': 24 * $settings-unit-rem,
    ),
    'fluid': (
        'sm': 2.5vh,
        'md': 5vh,
        'lg': 10vh,
        'xl': 12.5vh,
        'xl-A': 15vh,
        'xxl': 20vh,
        'xxxl': 30vh,
    ),
    'mobile': (
        'lr-fixed': 20px,
        'lr-fluid': 4.16667%,
    )
);
