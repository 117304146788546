.c-column {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: getSpacing('lg-A');

    @include mq(md) {
        flex-flow: column;
        margin-bottom: getSpacing('md');
    }

    &__item {
        padding-right: 30px;

        &:last-of-type {
            padding-right: 0;
            padding-bottom: 0;
        }

        @include mq(md) {
            width: 100%;
            padding-bottom: getSpacing('sm');
        }

        strong {
            @include font-heading();
            font-style: normal;
            display: block;
        }

        span {
            color: getColor('grey-A', 'secondary');
            display: block;
        }
    }
}