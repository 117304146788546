//
// Thanks to the Hugo Giraudel https://github.com/HugoGiraudel/SassyCast
//

@function to-length($value, $unit) {
    $units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);

    @if not index(map-keys($units), $unit) {
        @debug ('Invalid unit `#{$unit}`.');
    }

    @return $value * map-get($units, $unit);
}

@function number($value) {
    @if type-of($value) == 'number' {
        @return $value;
    } @else if type-of($value) != 'string' {
        @debug ('Value for `number` should be a number or a string.');
    }

    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);

    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if not (index(map-keys($numbers), $character) or $character == '.') {
            @return to-length(if($minus, -$result, $result), str-slice($value, $i))
        }

        @if $character == '.' {
            $digits: 1;
        } @else if $digits == 0 {
            $result: $result * 10 + map-get($numbers, $character);
        } @else {
            $digits: $digits * 10;
            $result: $result + map-get($numbers, $character) / $digits;
        }
    }

    @return if($minus, -$result, $result);
}