.c-about-quotes {
  overflow: hidden;
  position: relative;
  height: 400px;
  width: 100%;

  &__content {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__quotes-wrapper {
    position: relative;
    height: 100%;
    width: 80%;
  }

  &__single-quote-wrapper {
    position: absolute;
    display: none;
  }

  &__quote-text {
    position: relative;
    font-weight: normal;
    color: getColor('dark-blue');
    width: percentage(15.5/17.5);

    &.u-a4 {
      @include mq(lg) {
        font-size: 1.45rem;
        line-height: 1.35;
      }

      @include mq(md) {
        font-size: 1.25rem;
        line-height: 1.35;
      }

      @include mq(sm) {
        font-size: 1.05rem;
      }

      @include mq(xs) {
        font-size: 0.95rem;
      }
    }

    .row {
      position: relative;

      div:first-child {
      }
    }
  /*
    span {
      span {
        opacity: 0;
        transition: opacity 0.33s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        .char1 {
          position: absolute;
        }
      }

      &.show {
        span {
          opacity: 1;
          transition: opacity 0.33s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        }
      }

      &.accelerated {
        span {
          will-change: opacity;
        }
      }
    }
    */
  }

  &__quote-author {
    //opacity: 0;
    //transition: opacity 0.5s;
    //transition-delay: 0.5s;
    line-height: 1.5;
    padding-top: getSpacing('md');

    //&.show {
    //  opacity: 1;
    //}

    p {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;

      padding: 0;
      margin: 0;
      color: getColor('dark-grey', 'secondary');
    }
  }

  &__nav-arrows-wrapper {
    position: absolute;
    display: flex;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
    width: 250px;
    height: 60px;
    transform: translateX(-20px);

    @include mq(lg) {
      height: 160px;
      width: 60px;
      transform: translateX(0);
    }

    &:hover {
      .c-about-quotes__nav-lines-wrapper {
        opacity: 1;
        transition: opacity 0.2s;
      }

      #arrow-left-line {
        transform: scale(0.7, 1);
        transition: transform 0.2s;
      }

      #arrow-right-line {
        transform: scale(0.7, 1);
        transition: transform 0.2s;
      }
    }
  }

  &__nav-arrow-hover-area-right {
    position: absolute;
    right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    top: 50%;
    transform: translateY(-50%);

    @include mq(lg) {
      top: 0;
      bottom: auto;
      left: 0;
      transform: translateY(0);
    }

    &:hover {
      .c-about-quotes__nav-arrow-right {
        opacity: 0.7;
        transition: opacity 0.2s;
      }
    }
  }

  &__nav-arrow-hover-area-left {
    position: absolute;
    left: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    top: 50%;
    transform: translateY(-50%);

    @include mq(lg) {
      top: auto;
      bottom: 0;
      left: 0;
      transform: translateY(0);
    }

    &:hover {
      .c-about-quotes__nav-arrow-left {
        opacity: 0.7;
        transition: opacity 0.2s;
      }
    }
  }

  &__nav-lines-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    width: 130px;
    height: 48px;
    top: 50%;
    left: 60px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    @include mq(lg) {
      display: none;
      opacity: 0;
    }
  }

  &__nav-line {
    background-color: getColor('dark-blue');
    width: calc(130px / attr(data-count number, 5));
    height: 3px;
    margin-right: 5px;
    border-radius: getBorderRadius('default');

    &:last-child {
      margin-right: 0;
    }

    &.active {
      opacity: 0.2;
    }

    &.next-active {
      opacity: 1;
      transition: opacity 0.2s;
    }

    &.next-active-arrow-hover {
      opacity: 0.5;
      transition: opacity 0.2s;
    }

    &.prev-active {
      opacity: 1;
      transition: opacity 0.2s;
    }

    &.prev-active-arrow-hover {
      opacity: 0.5;
      transition: opacity 0.2s;
    }
  }

  &__nav-arrow-left {
    overflow: hidden;
    transition: width 0.2s linear, opacity 0.2s;

    #arrow-left-line {
      transition: transform 0.2s;
    }

    svg {
      width: 100%;
      height: 100%;

      @media (min-width: 1280px) {
        width: 35px;
        height: 35px;
      }
      @media (min-width: 1152px) and (max-width: 1279px) {
        width: 26px;
        height: 26px;
      }
      @media (min-width: 1024px) and (max-width: 1151px) {
        width: 26px;
        height: 26px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 24px;
        height: 24px;
      }
      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
      }

      g,
      path,
      polyline {
        vector-effect: non-scaling-stroke;
        stroke: getColor('dark-blue');
        stroke-width: 3px;
      }
    }

    &.area-hover {
      #arrow-left-line {
        transform: scale(0.7, 1);
        transition: transform 0.2s;
      }
    }
  }

  &__nav-arrow-right {
    overflow: hidden;
    direction: rtl;
    transition: width 0.2s linear, opacity 0.2s;

    #arrow-right-line {
      transition: transform 0.2s;
    }

    svg {
      /*width: 100%;
      height: 100%;*/

      @media (min-width: 1280px) {
        width: 35px;
        height: 35px;
      }
      @media (min-width: 1152px) and (max-width: 1279px) {
        width: 26px;
        height: 26px;
      }
      @media (min-width: 1024px) and (max-width: 1151px) {
        width: 26px;
        height: 26px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 24px;
        height: 24px;
      }
      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
      }

      g,
      path,
      polyline {
        vector-effect: non-scaling-stroke;
        stroke: getColor('dark-blue');
        stroke-width: 3px;
      }
    }

    &.area-hover {
      #arrow-right-line {
        transform: scale(0.7, 1);
        transition: transform 0.2s;
      }
    }
  }
}