$settings-zindex: (
    background: 0,
    default: 1,
    zero: 0,
    navbar: 10,
    navigation: 20,
    morph: 29,
    morph-transition: 30,
    morph-modal: 31,
    morph-modal-close: 32,
    cookie-bar: 98,
    loader: 100,
    background-animation: 0,
);