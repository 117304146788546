.c-svg-hand {
  display: inline-flex;
  align-self: center;
  position: absolute;
  overflow: visible;
  opacity: 0;

  //@include mq(lg) {
  //  max-width: 132px;
  //}

  //width: 96.2px;
  //height: 103.4px;
  height: 90px;

  @include mq (lg) {
    height: 60px;
  }

  @include mq (sm) {
    height: 40px;
  }

  #hand_lines .right-big,
  #hand_lines .left-big,
  #hand_lines .left-small,
  #hand_lines .right-small {
    opacity: 0;
  }

  #hand_lines .left-big {
    transform: translate(16px, 29px);
  }

  #hand_lines .right-small {
    transform: translate(-10px, 7px);
  }

  #hand_lines .left-small {
    transform: translate(22px, 12px);
  }

  #hand_lines .right-big {
    transform: translate(-41px, 7px);
  }

  &.is-visible #hand {
    transition-delay: 0.5s;
    transform: rotate(0deg);
    transform-origin: 10% 90%;
    animation: hand-frames 867ms 0.5s linear forwards;
  }

  @keyframes hand-frames {
    0% {
      transform: rotate(0deg);
      transform-origin: 10% 90%;
    }
    25% {
      transform: rotate(11deg);
      transform-origin: 10% 90%;
    }
    50% {
      transform: rotate(-10deg);
      transform-origin: 10% 90%;
    }
    75% {
      transform: rotate(11deg);
      transform-origin: 10% 90%;
    }
    100% {
      transform: rotate(0deg);
      transform-origin: 10% 90%;
    }
  }
  
  &.is-visible {
    opacity: 1;
    transition: opacity 300ms linear;
  }
  
  &.is-visible .right-big,
  &.is-visible .left-big,
  &.is-visible .left-small,
  &.is-visible .right-small {
    opacity: 1 !important;
    transform: translate(0, 0) !important;
    transition: opacity 1s linear, transform;
  }

  &.is-visible .left-big {
    transition-delay: 780ms;
    transition-duration: 600ms;
  }

  &.is-visible .right-big {
    transition-delay: 630ms;
    transition-duration: 600ms;
  }

  &.is-visible .left-small {
    transition-delay: 1200ms;
    transition-duration: 600ms;
  }

  &.is-visible .right-small {
    transition-delay: 1030ms;
    transition-duration: 600ms;
  }

  & svg {
    width: 100%;
    height: 100%;
    overflow: visible;
    fill: getColor('dark-blue');
  }
}

.u-lang--sk {
  .c-svg-hand {
    height: 80px;
    width: 70px;

    @include mq (xl) {
      height: 70px;
      width: 60px;
    }

    @include mq (lg) {
      height: 60px;
      width: 50px;
    }

    @include mq (md) {
      height: 45px;
      width: 35px;
    }

    @include mq (sm) {
      height: 40px;
      width: 30px;
    }
  }
}