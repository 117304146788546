.c-navbar {
  position: fixed;
  z-index: getZindex('navbar');
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  transition: getTransition('slide', 'ease-out-quad');

  &.is-hidden {
    top: -100%;
  }

  &.is-visible {
    top: 0;
  }

  &__inner {
    position: relative;
    height: 100%;
    display: flex;
  }

  .c-clip-element--link {
    top: 50%;
    position: absolute;
    transform: translateY(-50%) translateX(160px);
    margin-top: -12px;

    .c-button--text {
      color: getColor('white');

      &:before {
        transform: scale3d(0,1,1);
        transform-origin: 100% 50%;
        background-color: getColor('white-10', 'bw-transparent');
      }

      &:hover {
        &:before {
          transform: scale3d(1,1,1);
          transform-origin: 0% 50%;
        }
      }

      &.is-active {
        color: getColor('white');

        &:before {
          display: block;
          background-color: getColor('white');
          transform: scale3d(1,1,1);
          transform-origin: 0% 50%;
        }
      }
    }

    .c-button--bordered {

      &.is-active {

        &:after {
          background-color: getColor('white');
        }
      }
    }

    .c-button--primary {
      &.is-hovered {

      }
    }
  }
}