.c-cursor__circle {
    width: 4.3125rem;
    height: 4.3125rem;
    font-size: .8125rem;
    border-radius: 50%;
    background-color: getColor('dark-blue', 'primary');
    color: getColor('white', 'primary');
    padding: 0 1rem;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, .12);
}

@media only screen and (min-width: 835px) {
    .c-cursor__circle {
        width: 8.26389vw;
        height: 8.26389vw;
        font-size: .5125rem;
        line-height: 1.1;
    }
}

@media only screen and (min-width: 1102px) {
    .c-cursor__circle {
        //font-size: 1.18056vw
        font-size: 1vw
    }
}

.c-cursor {
    position: fixed;
    z-index: 10010;
    opacity: 1;
    pointer-events:none;
    -webkit-transition:opacity .5s ease;
    -o-transition:opacity .5s ease;
    transition:opacity .5s ease;
    -webkit-transform:translate(-50%,-50%);
    -o-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%)
}

.c-cursor.hidden {
    opacity:0
}
.c-cursor.active .c-cursor__circle {
    -webkit-transform:scale(1);
    -o-transform:scale(1);
    transform:scale(1)
}
.c-cursor .c-cursor__circle {
    -webkit-transition:all .5s ease;
    -o-transition:all .5s ease;
    transition:all .5s ease;
    -webkit-transform:scale(.33);
    -o-transform:scale(.33);
    transform:scale(.33);
    overflow-wrap:break-word
}
.c-cursor__arrow {
    position:absolute;
    left:50%;
    top:50%;
    margin-top:-25px;
    margin-left:-16px;
    width:32px;
    height:auto;
    padding-left:5px;
    fill:#fff;
    -webkit-transition:-webkit-transform .25s ease;
    transition:-webkit-transform .25s ease;
    -o-transition:-o-transform .25s ease;
    transition:transform .25s ease;
    transition:transform .25s ease,-webkit-transform .25s ease,-o-transform .25s ease
}
.active .c-cursor__arrow {
    -webkit-transform:scaleX(1) scaleY(1);
    -o-transform:scaleX(1) scaleY(1);
    transform:scaleX(1) scaleY(1)
}
.active .c-cursor__arrow.left {
    -webkit-transform:scaleX(-1) scaleY(1);
    -o-transform:scaleX(-1) scaleY(1);
    transform:scaleX(-1) scaleY(1)
}
.c-cursor__arrow.hidden {
    -webkit-transform:scaleX(0) scaleY(0)!important;
    -o-transform:scaleX(0) scaleY(0)!important;
    transform:scaleX(0) scaleY(0)!important
}

.is-desktop .c-cursor__circle {
    -webkit-box-shadow:0 0 2vw rgba(0,0,0,.3);
    box-shadow:0 0 2vw rgba(0,0,0,.3)
}

.is-mobile .c-cursor {
    display: none;
}

html.cursor-loaded:not(.is-mobile) {
    body,
    html,
    a,
    a > *,
    button,
    //input,
    //select,
    //label,
    //textarea,
    .c-button,
    .c-header__home-arrow,
    .c-menu-trigger {
        cursor: none !important;
    }

    &.is-locked {
        .c-cursor {
            display: none;
        }
    }

    .sf-dump {
        cursor: initial !important;

        .sf-dump-toggle {
            cursor: pointer !important;
        }

        .c-cursor {
            display: none;
        }
    }
}
