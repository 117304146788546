//LOGO
.c-logo {
    text-align: center;
}

//MODIFIERS

.c-logo--navigation {
    a {
        display: block;
        position: relative;
    }
}

.c-logo--large {

    .crealab {
        width: 176px;
        heicght: 176px;
    }
}

.c-logo--white {

    .crealab {

    }
}

.crealab {
    display: inline-block;
    margin: 0 auto;
    //width: 32px;
    //height: 32px;
    //border-radius: 100px;
    //background-color: getColor('dark-blue');
    position: relative;

    img {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        height: getLogoSize('height');
        max-width: 100%;
    }

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-blockchain-1 {
    width: 1.5em;
}

.icon-blockchain-2 {
    width: 1.5em;
}

.icon-blockchain-3 {
    width: 1.5em;
}

.icon-custom-software-1 {
    width: 1.5em;
}

.icon-custom-software-2 {
    width: 1.5em;
}

.icon-custom-software-3 {
    width: 1.5em;
}

.icon-digital-products-2 {
    width: 1.5em;
}

.icon-digital-products-3 {
    width: 1.5em;
}

.icon-digital-products-1 {
    width: 1.5em;
}

.icon-interactive-1 {
    width: 1.5em;
}

.icon-interactive-2 {
    width: 1.5em;
}

.icon-interactive-3 {
    width: 1.5em;
}

.icon-mobile-apps-1 {
    width: 1.5em;
}

.icon-mobile-apps-2 {
    width: 1.5em;
}

.icon-Mobile-apps-3 {
    width: 1.5em;
}

.icon-websites-1 {
    width: 1.5em;
}

.icon-websites-2 {
    width: 1.5em;
}

.icon-websites-3 {
    width: 1.5em;
}
