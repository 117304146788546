//BUTTON COMPONENT
.c-button {
    display: inline-block;
    padding-top: getSpacing('sm');
    padding-bottom: getSpacing('sm');
    transition: getTransition();
    position: relative;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    text-align: center;
    border-radius: getBorderRadius('default');
    overflow: hidden;

    //DISABLED
    &.btn--disabled,
    &:disabled {
        opacity: 0.4;
        //pointer-events: none;
        cursor: not-allowed;
    }

    &.is-hidden {
        opacity: 0;
        visibility: hidden;
    }

    &:before {
        border-radius: getBorderRadius('default');
    }

    &__layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        transition: getTransition('slide', 'ease-out-quad');
        z-index: 1;
        border-radius: getBorderRadius('default');
    }

    span,
    .icon {

    }

    .icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        position: relative;
        z-index: 2;
        margin-left: getSpacing('xs');
    }
}

//BUTTON MODIFIERS
//primary
.c-button--primary {
    border: 2px solid getColor('dark-blue');
    padding-left: getSpacing('md');
    padding-right: getSpacing('md');
    text-transform: uppercase;
    @include font-map('b6');
    @include font-heading();
    will-change: background-color;
    background-color: getColor('dark-blue');
    color: getColor('white');
    transition: getTransition();

    span {
        display: inline-block;
        padding: 3px 0;
        will-change: color;
        transition: getTransition();
        position: relative;
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        background-color: getColor('white');
        will-change: transform, background-color;
        transform: scale3d(0, 1, 1);
        transform-origin: 100% 50%;
        transition: transform 0.35s;
        transition-timing-function: map_get($settings-transitions-easing, 'ease-out-quad');
    }

    @include mq(null, 'lg') {

        &:hover {

            span {
                color: getColor('dark-blue');
            }

            &:before {
                transform: scale3d(1, 1, 1);
                transform-origin: 0% 50%;
                transition-timing-function: ease;
            }
        }
    }

    &.is-hovered {

        span {
            color: getColor('dark-blue');
        }

        &:before {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
            transition-timing-function: ease;
        }
    }
}

.c-button--icon {
    .icon {
        transition: getTransition('slide', 'ease-out-quad');
        will-change: transform;

        &.icon-arrow-left {
            margin-left: 0;
            margin-right: getSpacing('xs');
        }

        &.icon-arrow-right {
            margin-right: 0;
            margin-left: getSpacing('xs');
        }
    }

    &:hover {

        .icon-arrow-left {
            transform: translateX(-8px);
        }

        .icon-arrow-right {
            transform: translateX(8px);
        }
    }
}

//secondary
.c-button--secondary {
    border: none;
    outline: none;
    @include font-map('b3');
    background-color: getColor('blue');
    color: getColor('white');
    padding: getSpacing('sm');
    text-transform: uppercase;
    @include font-map('b6');
    @include font-heading();

    span {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        background-color: getColor('white');
        will-change: transform, background-color;
        transform: scale3d(0, 1, 1);
        transform-origin: 100% 50%;
        transition: transform 0.5s;
        transition-timing-function: map_get($settings-transitions-easing, 'ease-out-quad');
    }

    @include mq(null, 'lg') {

        &:hover {

            span {
                color: getColor('dark-blue');
            }

            &:before {
                transform: scale3d(1, 1, 1);
                transform-origin: 0% 50%;
                transition-timing-function: ease;
            }
        }
    }

    &.is-hovered {

        span {
            color: getColor('dark-blue');
        }

        &:before {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
            transition-timing-function: ease;
        }
    }
}

//white
.c-button--white {
    border: none;
    outline: none;
    @include font-map('b3');
    background-color: getColor('white');
    color: getColor('dark-blue');
    padding: getSpacing('sm');

    span {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        background-color: darken(getColor('white'), 10%);
        will-change: transform, background-color;
        transform: scale3d(0, 1, 1);
        transform-origin: 100% 50%;
        transition: transform 0.5s;
        transition-timing-function: map_get($settings-transitions-easing, 'ease-out-quad');
    }

    @include mq(null, 'lg') {

        &:hover {

            span {
                color: getColor('dark-blue');
            }

            &:before {
                transform: scale3d(1, 1, 1);
                transform-origin: 0% 50%;
                transition-timing-function: ease;
            }
        }
    }

    &.is-hovered {

        span {
            color: getColor('dark-blue');
        }

        &:before {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
            transition-timing-function: ease;
        }
    }
}

//block - full width
.c-button--block {
    display: block;
    width: 100%;
}

.c-button--round {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 100px;

    .icon {
        margin: 0;
        margin-top: -1px;
    }

    .c-button__layer {
        backface-visibility: hidden;
        will-change: transform;
        border-radius: 200px;
    }
}

//webkit stroke
.c-button--stroke {
    @include font-map('a7');
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    -webkit-text-stroke: 1px getColor('white-0', 'bw-transparent');

    &.c-button--large,
    &.c-button--extra-large {
        -webkit-text-stroke-width: 2px;
    }

    &:hover {
        color: getColor('white');
        -webkit-text-stroke-color: getColor('dark-blue');
    }
}

//text
.c-button--text {
    @include font-map('b2');
    padding-left: 0;
    padding-right: 0;

    &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: getColor('dark-blue');
        z-index: 2;
        will-change: transform, color;
        transform: scale3d(1, 1, 1);
        transform-origin: 0% 50%;
        transition: transform 0.35s;
        transition-timing-function: map_get($settings-transitions-easing, 'ease-out-quad');
    }

    //hover
    @include mq(null, 'lg') {

        &:hover {

            &:before {
                transform: scale3d(0, 1, 1);
                transform-origin: 100% 50%;
                transition-timing-function: ease;
            }
        }
    }

    //state
    &.is-active {

        &:before {
            background-color: getColor('black-10', 'bw-transparent');
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
    }
}

//medium
.c-button--medium {
    @include font-map('a3');

    &:before {
        height: 3px;
        bottom: 2px;
    }

    //MODIFIERS
    //bordered
    &.c-button--bordered {

        &:before {
            height: 3px;
            bottom: 2px;
        }
    }
}

//large
.c-button--large {
    @include font-map('a4');

    &:before {
        height: 3px;
        bottom: 2px;
    }

    //MODIFIERS
    //bordered
    &.c-button--bordered {

        &:before {
            height: 3px;
            bottom: 2px;
        }
    }
}

//extra large
.c-button--extra-large {
    @include font-map('a5');

    &:before {
        height: 6px;
        bottom: 4px;
    }

    //MODIFIERS
    //bordered
    &.c-button--bordered {

        &:before {
            height: 6px;
            bottom: 4px;
        }
    }
}

//small
.c-button--small {
    @include font-map('b4');
    padding-top: getSpacing('zero');
    padding-bottom: getSpacing('zero');

    &:before {
        height: 1px;
        bottom: 1px;
    }

    //MODIFIERS
    //bordered
    &.c-button--bordered {

        &:before {
            height: 1px;
            bottom: 1px;
        }
    }
}

//bordered
.c-button--bordered {

    &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 2px;
        z-index: 3;
        background-color: getColor('dark-blue');
        will-change: transform, color;
        transform: scale3d(1, 1, 1);
        transform-origin: 0% 50%;
        transition: transform 0.35s;
        transition-timing-function: map_get($settings-transitions-easing, 'ease-out-quad');
    }

    //hover
    @include mq(null, 'lg') {
        &:hover {
            &:before {
                transform: scale3d(0, 1, 1);
                transform-origin: 100% 50%;
                transition-timing-function: ease;
            }
        }
    }
}

.c-button--bordered-if-active {

    &:before {
        opacity: 0;
        visibility: hidden;
    }

    &.is-active {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }
}

.c-link {
    text-decoration: underline;
    display: inline;
}

//empty
.c-button--empty {
    width: 60px;
    height: 60px;
    border: 2px solid rgba(getColor('grey', 'secondary'), 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    position: relative;
    transition: getTransition('slide', 'ease-out-quad');

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: getColor('white', 'primary');
        border-radius: 100%;
        z-index: -1;
        transform: scale(0);
        transition: getTransition('slide', 'ease-out-quad');
    }

    &:hover {

        &:before {
            transform: scale(1);
        }

        .icon {
            color: getColor('dark-blue', 'primary');
        }

    }

    .icon {
        transition: getTransition('slide', 'ease-out-quad');
        margin: 0;
        color: getColor('white', 'primary');
    }

}
