//GRID VARIABLES
$settings-grid-width: 1440px;
$settings-grid-column-width: 60px;

$settings-grid-column-number: $settings-grid-width / $settings-grid-column-width;

$settings-unit: 10px;
$settings-unit-rem: 0.5rem;

//COMPONENTS VARIABLES
$navigation-width: $settings-grid-column-width * 1.5;
