//SECTION HEADING
.c-section-heading {
    width: 100%;
    margin-bottom: getSpacing('xl');

    @include mq (md) {
        margin-bottom: getSpacing('lg');
    }
}

.c-section-heading--narrow {
    max-width: 6 * $settings-grid-column-width;

    &.c-section-heading--fluid {
        max-width: percentage(6/17.5);
    }
}