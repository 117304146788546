//MEDIA WRAPPER
.o-media-wrapper {
    position: relative;
    //background-color: getColor('black-10', 'bw-transparent');
    overflow: hidden;
    width: 100%;
    max-width: 100%;

    picture,
    img {
        display: block;
        width: 100%;
        //height: auto;
        //position: relative; //will change probably
        vertical-align: top;
        backface-visibility: hidden;
        z-index: 1;
        //
        //height: 100%;
        bottom: 1px;
        position: absolute;
        top: 1px;
        left: 0;
    }

    .c-video {
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        height: 100%;
        display: block;
        transform: translateX(-50%);
    }

    .canvas {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        canvas {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }

    &__mask {
        display: block;
        position: absolute;
        z-index: 9;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        //width: 100%;
        //height: 100%;
        background-color: getColor('white');
    }
}

.o-media-caption {
    display: block;
    color: getColor('dark-blue');
    margin-top: getSpacing('xs');
    width: 100%;
    max-width: 3.5 * $settings-grid-column-width;
    @include font-default();
    @include font-map('b6');
    z-index: 2;
}

//MODIFIERS
//4:3 aspect ratio
.o-media-wrapper--4-3 {
    height: 0;
    padding-bottom: percentage(300/450);

}

//1:1 aspect ratio
.o-media-wrapper--1-1 {
    height: 0;
    padding-bottom: percentage(1/1);

}

//1:2 aspect ratio
.o-media-wrapper--1-2 {
    height: 0;
    padding-bottom: percentage(2/1);

}

//1:2 "od oka" aspect ratio
.o-media-wrapper--1-2-a {
    height: 0;
    padding-bottom: percentage(600/390);

}

//team members aspect ratio
.o-media-wrapper--team {
    height: 0;
    padding-bottom: percentage(420/320);

}

//testimonials members aspect ratio
.o-media-wrapper--testimonials {
    height: 0;
    padding-bottom: percentage(590/420);
}

//16:9 aspect ratio
.o-media-wrapper--16-9 {
    height: 0;
    padding-bottom: percentage(9/16);
}

//16:6 aspect ratio
.o-media-wrapper--16-6 {
    height: 0;
    padding-bottom: percentage(590/1440);
}

//auto aspect ratio
.o-media-wrapper--auto {
    height: auto;
    padding-bottom: 0;

    picture {
        position: relative;
        bottom: 0;
        top: 0;
    }

    img {
        margin-bottom: 0 !important;
        position: relative;
        top: 0;
    }
}

// resources list aspect ratio

.o-media-wrapper--resources {
    height: 0;
    padding-bottom: percentage(210/150);
}

[data-parallax] {
    backface-visibility: hidden;
}
