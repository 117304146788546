.u-text-align--left {
    text-align: left;
}

.u-text-align--right {
    text-align: right;
}

.u-text-align--center {
    text-align: center;
}