@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

* {
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

ul, ol {
  list-style-type: none;
}

.wp-admin input,
.wp-admin select,
.wp-admin textarea,
.wp-admin label {
  font-size: 15px !important;
}

html {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;
  color: #121a2d;
  background-color: #ffffff;
  min-height: 100vh;
}

html.is-locked {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

html, body {
  width: 100%;
  min-height: 100vh;
}

html.is-featured-case-loading, html.is-next-case-loading, html.is-prev-case-loading,
html.is-featured-case-loading body,
html.is-next-case-loading body,
html.is-prev-case-loading body {
  overflow-x: hidden;
}

body.is-morph-opened, body.is-menu-opened {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#barba-wrapper {
  overflow: hidden;
}

.barba-container {
  max-width: 100%;
}

.barba-container:nth-of-type(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
}

.is-prev-case-loading .barba-container:nth-of-type(3),
.is-next-case-loading .barba-container:nth-of-type(3) {
  z-index: 2;
}

.grecaptcha-badge {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

a[href^="tel:"]:after {
  display: none;
}

.o-page-wrapper {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.o-page-wrapper--work {
  background-color: #0d0d0d;
}

.o-page-wrapper--blog-single {
  background-color: #ffffff;
}

.o-section {
  position: relative;
}

.o-section__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

@media (max-width: 720px) {
  .o-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.o-section--dark {
  background-color: #121a2d;
  color: #ffffff;
}

.o-section--dark .c-button--bordered:before {
  background-color: #ffffff;
}

.o-section--cta {
  background-color: #ebedf0;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .o-section--cta {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--small-cta {
  background-color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section--small-cta {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--related-content {
  background-color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section--related-content {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1140px) {
  .o-section--related-content .c-article-list .c-article:hover .o-media-wrapper {
    transform: scale(1.15);
  }
  .o-section--related-content .c-article-list .c-article:hover .caption__header {
    transform: translateX(10px);
  }
  .o-section--related-content .c-article-list .c-article:hover .caption__content {
    transform: translateX(10px);
  }
}

.o-section--featured-work {
  color: #121a2d;
  background-color: #ffffff;
}

.o-section--featured-work .o-container {
  padding-right: 0;
}

@media (min-width: 720px) {
  .o-section--featured-work--mobile,
  .o-section--featured-work--mobile.u-clearfix {
    display: none;
  }
}

@media (max-width: 720px) {
  .o-section--featured-work--desktop,
  .o-section--featured-work--desktop.u-clearfix {
    display: none;
  }
}

.o-section--show-more {
  color: #121a2d;
  background-color: #ffffff;
}

.o-section--services-clients {
  color: #121a2d;
  background-color: #ffffff;
}

.o-section--services-clients.u-clip {
  margin-top: calc(3vw - 1px);
  margin-bottom: -1px;
}

.o-section--home-quote {
  background-color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section--home-quote {
    padding-top: 0;
    padding-bottom: 3rem;
  }
}

.o-section--home-quote .c-quotes {
  max-width: 990px;
  float: left;
}

.o-section--home-quote .u-a4 {
  line-height: 1.14894;
  font-size: 2.35rem;
}

@media (max-width: 1140px) {
  .o-section--home-quote .u-a4 {
    line-height: 1.21053;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .o-section--home-quote .u-a4 {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

.o-section--home-intro {
  background-color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

.o-section--home-intro .c-article {
  margin-top: 3rem;
}

.o-section--home-intro .c-list--multicolumn-2 .c-list-column {
  width: 45%;
}

@media (max-width: 720px) {
  .o-section--home-intro {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--services {
  padding-bottom: 10vh;
}

.o-section--services .u-b0 {
  line-height: 1.5;
  font-size: 1rem;
}

.o-section--services .c-article-list--3 {
  padding-bottom: 3rem;
}

@media (max-width: 1140px) {
  .o-section--services .c-article-list--3 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.o-section--services .c-article {
  margin-bottom: 2rem;
}

@media (max-width: 1140px) {
  .o-section--services .c-article {
    margin-bottom: 0;
  }
}

.o-section--services .c-article .caption__content {
  max-width: 210px;
}

@media (max-width: 720px) {
  .o-section--services .c-article .caption__content {
    max-width: 300px;
  }
}

@media (max-width: 720px) {
  .o-section--services .c-article-list .c-article {
    margin-bottom: 20px;
  }
}

.o-section--services .c-article-list .c-article:last-of-type {
  margin-bottom: 0;
}

.o-section--services .c-article-list .c-article .u-b0 {
  transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.o-section--services .c-article-list .c-article .u-b4 {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 1140px) {
  .o-section--services .c-article-list .c-article:hover .o-media-wrapper {
    transform: scale(1.15);
  }
  .o-section--services .c-article-list .c-article:hover .u-b0 {
    transform: translateX(10px);
  }
  .o-section--services .c-article-list .c-article:hover .u-b4 {
    transform: translateX(10px);
  }
}

.o-section--services-dark {
  background-color: #121a2d;
  color: #ffffff;
  padding-top: 10vh;
}

.o-section--services-dark .c-article-list {
  padding-top: 0;
  padding-bottom: 3rem;
}

.o-section--services-quote {
  background-color: #edf0f7;
}

.o-section--services-quote .c-quotes {
  max-width: 990px;
  float: left;
  margin-bottom: 5rem;
}

.o-section--services-quote .c-testimonials__inner {
  align-items: center;
}

.o-section--services-quote .c-testimonials__text {
  padding: 2rem 0;
  margin-bottom: 0;
}

@media (max-width: 720px) {
  .o-section--services-quote .c-testimonials__text {
    padding-bottom: 0;
  }
}

.o-section--our-expertise {
  padding-top: 15vh;
  padding-bottom: 20vh;
}

@media (max-width: 1140px) {
  .o-section--our-expertise {
    padding-bottom: 15vh;
  }
}

@media (max-width: 720px) {
  .o-section--our-expertise {
    padding-top: 3rem;
    padding-bottom: 0;
  }
}

.o-section--our-expertise .u-b0 {
  line-height: 1.5;
  font-size: 1rem;
}

@media (max-width: 720px) {
  .o-section--our-expertise .c-article {
    margin-bottom: 2rem;
  }
}

.o-section--languages-frameworks {
  padding-top: 15vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section--languages-frameworks {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--languages-frameworks .c-section-heading .u-a4 {
  max-width: 360px;
  margin-bottom: 0.5rem;
}

.o-section--languages-frameworks .c-section-heading .u-b2 {
  max-width: 540px;
}

.o-section--languages-frameworks .c-list {
  margin-bottom: 40px;
}

@media (max-width: 720px) {
  .o-section--languages-frameworks .c-list {
    margin-bottom: 0;
  }
}

@media (max-width: 720px) {
  .is-mobile .o-section--languages-frameworks .c-list {
    padding-top: 2rem;
    padding-bottom: 0;
  }
}

@media (max-width: 720px) {
  .is-mobile .o-section--languages-frameworks .c-list-column {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.is-mobile .o-section--languages-frameworks .c-list-column .c-expander__trigger {
  width: 100%;
}

.o-section--approach {
  padding-top: 15vh;
}

@media (max-width: 1140px) {
  .o-section--approach {
    padding-bottom: 12.5vh;
  }
}

@media (max-width: 720px) {
  .o-section--approach {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--working-with-us {
  margin-top: -10vh;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media (max-width: 1140px) {
  .o-section--working-with-us {
    margin-top: 0;
    padding-top: 12.5vh;
  }
}

@media (max-width: 720px) {
  .o-section--working-with-us {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--working-with-us .c-list {
  padding-right: 2.85714%;
}

@media (max-width: 720px) {
  .o-section--working-with-us .c-list {
    padding-top: 0;
  }
}

@media (max-width: 720px) {
  .is-mobile .o-section--working-with-us .c-list {
    padding-right: 0;
  }
}

.o-section--working-with-us .c-list--multicolumn-2 .c-list-column {
  width: 43.75%;
}

@media (max-width: 720px) {
  .is-mobile .o-section--working-with-us .c-list--multicolumn-2 .c-list-column {
    width: 100%;
  }
}

.o-section--working-with-us .c-article {
  margin-top: 3rem;
}

@media (max-width: 720px) {
  .is-mobile .o-section--working-with-us .c-article {
    margin-top: 2rem;
  }
}

.o-section--working-with-us .c-article .u-a1 {
  margin-bottom: 0.5rem;
}

@media (max-width: 720px) {
  .is-mobile .o-section--working-with-us .c-article .u-a1 {
    margin-bottom: 0;
  }
}

.o-section--working-with-us .c-button--bordered:after {
  background-color: #4b4b4b;
}

.o-section--strategic-sessions {
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .o-section--strategic-sessions {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (max-width: 720px) {
  .o-section--strategic-sessions-home .strategic-sessions__left {
    margin-bottom: 2rem;
  }
}

.o-section--strategic-sessions-home .strategic-sessions__right .o-media-wrapper__mask {
  background-color: #0d0d0d;
}

@media (max-width: 720px) {
  .o-section--strategic-sessions-home .strategic-sessions__right {
    margin-bottom: 0;
  }
}

.o-section--case-study-coming {
  background-color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

.o-section--culture {
  background-color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

.o-section--culture .o-media-wrapper__mask {
  background-color: #f5f7fd;
}

.o-section--culture.u-clip {
  margin-top: calc(-3vw - 1px);
  margin-bottom: -1px;
}

.o-section--activities {
  overflow: hidden;
  margin-top: 12.5vh;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .o-section--activities {
    margin-top: 0;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}

.o-section--awards {
  padding-top: 12.5vh;
  padding-bottom: 15vh;
}

@media (max-width: 720px) {
  .o-section--awards {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
}

.o-section--our-approach {
  background-color: #ffffff;
}

.o-section--blog-list {
  background-color: #dbe2ec;
}

.o-section--blog-list .o-container {
  position: relative;
}

.o-section--blog-list .c-pagination__loader {
  width: auto;
  left: 7.5%;
  right: 5%;
  margin-left: -1px;
  margin-right: -1px;
}

@media (max-width: 1140px) {
  .o-section--blog-list .c-pagination__loader {
    left: 6.66667%;
    right: 4.44444%;
  }
}

@media (max-width: 720px) {
  .o-section--blog-list .c-pagination__loader {
    left: 0;
    right: 0;
  }
}

.o-section--blog-list .c-article-list {
  padding-top: 0;
}

@media (min-width: 1140px) {
  .o-section--blog-list .c-article-list .c-article:hover .o-media-wrapper {
    transform: scale(1.15);
  }
  .o-section--blog-list .c-article-list .c-article:hover .caption__header {
    transform: translateX(10px);
  }
  .o-section--blog-list .c-article-list .c-article:hover .caption__content {
    transform: translateX(10px);
  }
}

.o-section--blog-list .c-article-list--2 .caption__content {
  padding-bottom: 2rem;
}

.o-section--blog-single-content {
  padding-top: 2rem;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section--blog-single-content {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}

.o-section--resources-single-content {
  padding-top: 2rem;
  padding-bottom: 5vh;
  display: flex !important;
}

@media (max-width: 1140px) {
  .o-section--resources-single-content {
    flex-direction: column-reverse;
  }
}

@media (max-width: 720px) {
  .o-section--resources-single-content {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}

@media (max-width: 1140px) {
  .o-section--resources-single-content .resources-single-container__image {
    margin-left: 6.66667%;
  }
}

@media (max-width: 720px) {
  .o-section--resources-single-content .resources-single-container__image {
    margin-left: 0;
  }
}

.o-section--generic-single-content {
  padding-top: 2rem;
  padding-bottom: 5vh;
}

@media (max-width: 720px) {
  .o-section--generic-single-content {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}

.is-protected .o-section--generic-single-content .c-editor-content form p {
  max-width: 480px;
}

.is-protected .o-section--generic-single-content .c-editor-content p label {
  display: inline-block;
  position: relative;
  max-width: 480px;
  width: 100%;
  color: #121a2d;
  line-height: 1.5;
  font-size: 0.6rem;
}

.is-protected .o-section--generic-single-content .c-editor-content p label input[type="password"] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  display: block;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.is-protected .o-section--generic-single-content .c-editor-content p label input[type="password"]:focus {
  border-bottom: 1px solid #121a2d;
}

.is-protected .o-section--generic-single-content .c-editor-content p input[type="submit"] {
  margin-top: 40px;
  display: block;
  width: 100%;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  position: relative;
  border: none;
  outline: none;
  line-height: 1.3125;
  font-size: 0.8rem;
  background-color: #121a2d;
  color: #ffffff;
  padding: 1rem;
  cursor: pointer;
}

@media (max-width: 1140px) {
  .is-protected .o-section--generic-single-content .c-editor-content p input[type="submit"] {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

@media (min-width: 1140px) {
  .is-protected .o-section--generic-single-content .c-editor-content p input[type="submit"]:hover {
    background-color: #4b4b4b;
  }
}

.o-section--clients-logos {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  background-color: #121a2d;
}

.o-section--clients-logos .c-section-heading {
  color: #ffffff;
}

.o-section--about-quotes {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  background-color: #121a2d;
}

.o-section--about-quotes .c-about-quotes__quote-text {
  color: #ffffff;
}

.o-section--about-quotes .c-about-quotes__quote-author p {
  color: #ffffff;
}

.o-section--about-quotes .c-about-quotes__nav-line {
  background-color: #ffffff;
}

.o-section--about-quotes .c-about-quotes__nav-arrow-left svg g,
.o-section--about-quotes .c-about-quotes__nav-arrow-left svg path,
.o-section--about-quotes .c-about-quotes__nav-arrow-left svg polyline {
  stroke: #ffffff;
}

.o-section--about-quotes .c-about-quotes__nav-arrow-right svg g,
.o-section--about-quotes .c-about-quotes__nav-arrow-right svg path,
.o-section--about-quotes .c-about-quotes__nav-arrow-right svg polyline {
  stroke: #ffffff;
}

.o-section--what-write-about-us {
  padding-top: 12.5vh;
  padding-bottom: 20vh;
  background-color: #121a2d;
}

.o-section--what-write-about-us .c-section-heading {
  color: #ffffff;
}

.o-section--what-write-about-us .o-media-wrapper__mask {
  background-color: #121a2d;
}

.o-section--what-write-about-us .c-article__caption {
  color: #ffffff;
}

.o-section--what-we-do {
  padding-top: 5rem;
}

.o-section--what-we-do .c-list-column .c-article--services {
  max-width: 90%;
  margin-top: 0;
}

.o-section--what-we-do .c-list-column .c-article--services .u-b0 {
  color: #000000;
}

.o-section--awards {
  background-color: #f5f7fd;
}

.o-section--work-list {
  padding-top: 20vh;
  padding-bottom: 20vh;
  color: #121a2d;
  background-color: #ffffff;
}

@media (max-width: 720px) {
  .o-section--work-list {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--work-list .c-featured-work__list-item {
  height: auto;
  padding: 2rem 0;
  color: #121a2d;
}

.is-mobile .o-section--work-list--desktop {
  display: none;
}

.is-desktop .o-section--work-list--mobile {
  display: none;
}

.o-section--work-details {
  background-color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .o-section--work-details {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--work-links {
  padding-top: 12.5vh;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .o-section--work-links {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--work-single-content {
  background-color: #ffffff;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.o-section--work-single-content.u-clip {
  margin-top: calc(3vw - 1px);
  margin-bottom: -1px;
}

.o-section--contact {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.o-section--full-width-image {
  padding-left: 0;
  padding-right: 0;
}

.o-section--careers-intro {
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section--careers-intro {
    padding-bottom: 3rem;
  }
}

.o-section--careers-people {
  padding-top: 12.5vh;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .o-section--careers-people {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--careers-selection-process {
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .o-section--careers-selection-process {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--careers-testimonials {
  padding-top: 3rem;
  background-color: #edf0f7;
  position: relative;
}

.o-section--careers-testimonials .o-section__mask {
  background-color: #dce0ea;
}

@media (max-width: 720px) {
  .o-section--careers-testimonials {
    padding-top: 3rem;
  }
}

.o-section--careers-students {
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section--careers-students {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--careers-open-positions {
  padding-top: 20vh;
  padding-bottom: 20vh;
  background-color: #0d0d0d;
  color: #ffffff;
}

@media (max-width: 720px) {
  .o-section--careers-open-positions {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--careers-single-image .o-media-wrapper img {
  top: 0;
}

.o-section--careers-form {
  padding-top: 20vh;
  background-color: #f4f3f1;
}

@media (max-width: 720px) {
  .o-section--careers-form {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--careers-form .u-a4 {
  padding-bottom: 3rem;
}

.o-section--service-icon-list {
  padding-top: 2.5vh;
  padding-bottom: 12.5vh;
  color: #ffffff;
}

@media (max-width: 720px) {
  .o-section--service-icon-list {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--service-icon-list .c-button {
  border: 1px solid #ffffff;
  color: #121a2d;
  background-color: #ffffff;
  margin-top: 5vh;
}

.o-section--service-icon-list .c-button:before {
  background-color: #121a2d;
}

.o-section--service-icon-list .c-button:hover span {
  color: #ffffff;
}

.o-section--service-project-list {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
  color: #ffffff;
}

@media (max-width: 720px) {
  .o-section--service-project-list {
    padding-top: 3rem;
    padding-bottom: 0;
  }
}

.o-section--service-project-list h4 {
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section--service-project-list h4 {
    padding-bottom: 3rem;
  }
}

.o-section--service-cta {
  padding-top: 20vh;
  padding-bottom: 20vh;
  color: #121a2d;
  background-color: #ffffff;
}

@media (max-width: 720px) {
  .o-section--service-cta {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--service-partnership {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
  color: #ffffff;
  background-color: #121a2d;
}

@media (max-width: 720px) {
  .o-section--service-partnership {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--service-quotes {
  padding-top: 12.5vh;
  padding-bottom: 20vh;
  color: #ffffff;
  background-color: #121a2d;
}

@media (max-width: 720px) {
  .o-section--service-quotes {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section--blog-single-newsletter {
  padding-right: 4.16667%;
}

.o-section--resources-single-newsletter {
  padding-right: 4.16667%;
  padding-bottom: 10vh;
  padding-left: 0;
}

@media (max-width: 720px) {
  .o-section--resources-single-newsletter {
    padding-right: 0;
  }
}

.o-section--resources-single-newsletter-white {
  background-color: #ffffff;
}

.o-section-modular--gray {
  background-color: #fafafa;
}

.o-section-modular--client-quotes {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section-modular--client-quotes {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--small-cta {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--small-cta {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--cards-grid {
  padding-top: 12.5vh;
  padding-bottom: 5vh;
}

@media (max-width: 720px) {
  .o-section-modular--cards-grid {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--awwwards {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section-modular--awwwards {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--companies {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section-modular--companies {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--statistic-numbers {
  padding-top: 12.5vh;
  padding-bottom: 15vh;
}

@media (max-width: 720px) {
  .o-section-modular--statistic-numbers {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--skills {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section-modular--skills {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--read-more {
  padding-top: 10vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .o-section-modular--read-more {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--development-process {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--development-process {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--accordion-block {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--accordion-block {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--content-slider {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--content-slider {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--list-with-content {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--list-with-content {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--rte-block {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--rte-block {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--image-title-block {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--image-title-block {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-section-modular--split-text-block {
  padding-top: 12.5vh;
  padding-bottom: 10vh;
}

@media (max-width: 720px) {
  .o-section-modular--split-text-block {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.o-container {
  float: right;
  width: 100%;
  max-width: 91.11111%;
  padding-left: 5.77778%;
  padding-right: 8.88889%;
}

@media (max-width: 1140px) {
  .o-container {
    max-width: 95.55556%;
  }
}

@media (max-width: 720px) {
  .o-container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.o-container--centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.o-media-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.o-media-wrapper picture,
.o-media-wrapper img {
  display: block;
  width: 100%;
  vertical-align: top;
  backface-visibility: hidden;
  z-index: 1;
  bottom: 1px;
  position: absolute;
  top: 1px;
  left: 0;
}

.o-media-wrapper .c-video {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  height: 100%;
  display: block;
  transform: translateX(-50%);
}

.o-media-wrapper .canvas {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.o-media-wrapper .canvas canvas {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.o-media-wrapper__mask {
  display: block;
  position: absolute;
  z-index: 9;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background-color: #ffffff;
}

.o-media-caption {
  display: block;
  color: #121a2d;
  margin-top: 0.5rem;
  width: 100%;
  max-width: 210px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 0.6rem;
  z-index: 2;
}

.o-media-wrapper--4-3 {
  height: 0;
  padding-bottom: 66.66667%;
}

.o-media-wrapper--1-1 {
  height: 0;
  padding-bottom: 100%;
}

.o-media-wrapper--1-2 {
  height: 0;
  padding-bottom: 200%;
}

.o-media-wrapper--1-2-a {
  height: 0;
  padding-bottom: 153.84615%;
}

.o-media-wrapper--team {
  height: 0;
  padding-bottom: 131.25%;
}

.o-media-wrapper--testimonials {
  height: 0;
  padding-bottom: 140.47619%;
}

.o-media-wrapper--16-9 {
  height: 0;
  padding-bottom: 56.25%;
}

.o-media-wrapper--16-6 {
  height: 0;
  padding-bottom: 40.97222%;
}

.o-media-wrapper--auto {
  height: auto;
  padding-bottom: 0;
}

.o-media-wrapper--auto picture {
  position: relative;
  bottom: 0;
  top: 0;
}

.o-media-wrapper--auto img {
  margin-bottom: 0 !important;
  position: relative;
  top: 0;
}

.o-media-wrapper--resources {
  height: 0;
  padding-bottom: 140%;
}

[data-parallax] {
  backface-visibility: hidden;
}

.o-horizontal-separator {
  display: block;
  width: 100%;
  border-top: 1px solid #ffffff;
}

.o-horizontal-separator--dark {
  border-color: #121a2d;
}

.o-pin {
  display: block;
}

.o-pin--activities-scroller {
  position: absolute;
  top: 5rem;
}

@media (max-width: 720px) {
  .o-pin--activities-scroller {
    top: -2rem;
  }
}

.c-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

.c-table__wrapper {
  overflow-x: scroll;
  margin-bottom: 2rem;
}

.c-table td,
.c-table th {
  font-size: 1rem;
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

@media (max-width: 768px) {
  .c-table td,
  .c-table th {
    font-size: .75rem;
  }
}

.c-table th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.c-column {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4rem;
}

@media (max-width: 720px) {
  .c-column {
    flex-flow: column;
    margin-bottom: 2rem;
  }
}

.c-column__item {
  padding-right: 30px;
}

.c-column__item:last-of-type {
  padding-right: 0;
  padding-bottom: 0;
}

@media (max-width: 720px) {
  .c-column__item {
    width: 100%;
    padding-bottom: 1rem;
  }
}

.c-column__item strong {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: block;
}

.c-column__item span {
  color: #B2B2B2;
  display: block;
}

.c-main-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 100;
  overflow: hidden;
}

.c-main-loader__logo {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  z-index: 22;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}

@media (max-width: 1140px) {
  .c-main-loader__logo {
    max-width: 320px;
  }
}

@media (max-width: 720px) {
  .c-main-loader__logo {
    max-width: 180px;
  }
}

.c-main-loader__logo img,
.c-main-loader__logo svg {
  max-width: 100%;
  display: block;
}

.c-main-loader__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  will-change: visibility, opacity, transform;
  backface-visibility: hidden;
}

.c-main-loader__image {
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transform: scale(1.15);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  will-change: opacity, visibility;
  backface-visibility: hidden;
}

.c-main-loader__curtain {
  display: block;
  height: 5px;
  width: 0;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 99;
}

.c-page-transitions {
  height: 100vh;
  width: 0;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 22;
  pointer-events: none;
  transform: translateY(99.5%);
  will-change: transform, width;
}

.c-tag__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.c-tag__item {
  border-radius: 2px;
  padding: 2px 8px;
  margin-bottom: 5px;
  display: flex;
}

.c-tag__item span {
  color: #ffffff;
  margin: 0;
}

.c-tag__item--development {
  background-color: rgba(18, 26, 45, 0.8);
}

.c-tag__item--design {
  background-color: rgba(18, 26, 45, 0.7);
}

.c-tag__item--marketing {
  background-color: rgba(18, 26, 45, 0.6);
}

.c-tag__item--consultation {
  background-color: rgba(18, 26, 45, 0.5);
}

.c-tag__item:not(:last-child) {
  margin-right: 5px;
}

.c-featured-work .c-tag {
  margin-top: 1rem;
}

.work-single-info .c-tag__item--development {
  background-color: rgba(0, 0, 0, 0.5);
}

.work-single-info .c-tag__item--design {
  background-color: rgba(0, 0, 0, 0.4);
}

.work-single-info .c-tag__item--marketing {
  background-color: rgba(0, 0, 0, 0.3);
}

.work-single-info .c-tag__item--consultation {
  background-color: rgba(0, 0, 0, 0.2);
}

.c-cursor__circle {
  width: 4.3125rem;
  height: 4.3125rem;
  font-size: .8125rem;
  border-radius: 50%;
  background-color: #121a2d;
  color: #ffffff;
  padding: 0 1rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 835px) {
  .c-cursor__circle {
    width: 8.26389vw;
    height: 8.26389vw;
    font-size: .5125rem;
    line-height: 1.1;
  }
}

@media only screen and (min-width: 1102px) {
  .c-cursor__circle {
    font-size: 1vw;
  }
}

.c-cursor {
  position: fixed;
  z-index: 10010;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity .5s ease;
  -o-transition: opacity .5s ease;
  transition: opacity .5s ease;
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.c-cursor.hidden {
  opacity: 0;
}

.c-cursor.active .c-cursor__circle {
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.c-cursor .c-cursor__circle {
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: scale(0.33);
  -o-transform: scale(0.33);
  transform: scale(0.33);
  overflow-wrap: break-word;
}

.c-cursor__arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -25px;
  margin-left: -16px;
  width: 32px;
  height: auto;
  padding-left: 5px;
  fill: #fff;
  -webkit-transition: -webkit-transform .25s ease;
  transition: -webkit-transform .25s ease;
  -o-transition: -o-transform .25s ease;
  transition: transform .25s ease;
  transition: transform .25s ease,-webkit-transform .25s ease,-o-transform .25s ease;
}

.active .c-cursor__arrow {
  -webkit-transform: scaleX(1) scaleY(1);
  -o-transform: scaleX(1) scaleY(1);
  transform: scaleX(1) scaleY(1);
}

.active .c-cursor__arrow.left {
  -webkit-transform: scaleX(-1) scaleY(1);
  -o-transform: scaleX(-1) scaleY(1);
  transform: scaleX(-1) scaleY(1);
}

.c-cursor__arrow.hidden {
  -webkit-transform: scaleX(0) scaleY(0) !important;
  -o-transform: scaleX(0) scaleY(0) !important;
  transform: scaleX(0) scaleY(0) !important;
}

.is-desktop .c-cursor__circle {
  -webkit-box-shadow: 0 0 2vw rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 2vw rgba(0, 0, 0, 0.3);
}

.is-mobile .c-cursor {
  display: none;
}

html.cursor-loaded:not(.is-mobile) body,
html.cursor-loaded:not(.is-mobile) html,
html.cursor-loaded:not(.is-mobile) a,
html.cursor-loaded:not(.is-mobile) a > *,
html.cursor-loaded:not(.is-mobile) button,
html.cursor-loaded:not(.is-mobile) .c-button,
html.cursor-loaded:not(.is-mobile) .c-header__home-arrow,
html.cursor-loaded:not(.is-mobile) .c-menu-trigger {
  cursor: none !important;
}

html.cursor-loaded:not(.is-mobile).is-locked .c-cursor {
  display: none;
}

html.cursor-loaded:not(.is-mobile) .sf-dump {
  cursor: initial !important;
}

html.cursor-loaded:not(.is-mobile) .sf-dump .sf-dump-toggle {
  cursor: pointer !important;
}

html.cursor-loaded:not(.is-mobile) .sf-dump .c-cursor {
  display: none;
}

.c-word-set {
  display: none;
}

.c-word-insert {
  overflow: hidden;
  line-height: 1.15;
}

.c-word-insert > * {
  display: block;
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  -o-transition: -o-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease,-webkit-transform .5s ease,-o-transform .5s ease;
}

.c-word-insert.show-on > * {
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: .25s;
  -o-transition-delay: .25s;
  transition-delay: .25s;
  -webkit-transition: -webkit-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
  transition: -webkit-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
  -o-transition: -o-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
  transition: transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
  transition: transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease, -webkit-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease, -o-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
}

.c-word-insert.show-off > * {
  -webkit-transform: translateY(105%);
  -o-transform: translateY(105%);
  transform: translateY(105%);
}

.c-wire-text {
  color: #ffffff;
  -webkit-text-stroke: 3px rgba(255, 255, 255, 0);
}

@media (max-width: 720px) {
  .c-wire-text {
    -webkit-text-stroke: 2px rgba(255, 255, 255, 0);
  }
}

.c-wire-text.show {
  transition: -webkit-text-stroke .75s ease,color .75s ease;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-color: #ffffff;
}

.c-button {
  display: inline-block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
}

.c-button.btn--disabled, .c-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.c-button.is-hidden {
  opacity: 0;
  visibility: hidden;
}

.c-button:before {
  border-radius: 4px;
}

.c-button__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 1;
  border-radius: 4px;
}

.c-button .icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  position: relative;
  z-index: 2;
  margin-left: 0.5rem;
}

.c-button--primary {
  border: 2px solid #121a2d;
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: uppercase;
  line-height: 1.5;
  font-size: 0.6rem;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  will-change: background-color;
  background-color: #121a2d;
  color: #ffffff;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.c-button--primary span {
  display: inline-block;
  padding: 3px 0;
  will-change: color;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  position: relative;
  z-index: 2;
}

.c-button--primary:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: #ffffff;
  will-change: transform, background-color;
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  transition: transform 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 1140px) {
  .c-button--primary:hover span {
    color: #121a2d;
  }
  .c-button--primary:hover:before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 50%;
    transition-timing-function: ease;
  }
}

.c-button--primary.is-hovered span {
  color: #121a2d;
}

.c-button--primary.is-hovered:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
  transition-timing-function: ease;
}

.c-button--icon .icon {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}

.c-button--icon .icon.icon-arrow-left {
  margin-left: 0;
  margin-right: 0.5rem;
}

.c-button--icon .icon.icon-arrow-right {
  margin-right: 0;
  margin-left: 0.5rem;
}

.c-button--icon:hover .icon-arrow-left {
  transform: translateX(-8px);
}

.c-button--icon:hover .icon-arrow-right {
  transform: translateX(8px);
}

.c-button--secondary {
  border: none;
  outline: none;
  line-height: 1.3125;
  font-size: 0.8rem;
  background-color: #346CF0;
  color: #ffffff;
  padding: 1rem;
  text-transform: uppercase;
  line-height: 1.5;
  font-size: 0.6rem;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
}

@media (max-width: 1140px) {
  .c-button--secondary {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.c-button--secondary span {
  position: relative;
  z-index: 2;
}

.c-button--secondary:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: #ffffff;
  will-change: transform, background-color;
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 1140px) {
  .c-button--secondary:hover span {
    color: #121a2d;
  }
  .c-button--secondary:hover:before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 50%;
    transition-timing-function: ease;
  }
}

.c-button--secondary.is-hovered span {
  color: #121a2d;
}

.c-button--secondary.is-hovered:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
  transition-timing-function: ease;
}

.c-button--white {
  border: none;
  outline: none;
  line-height: 1.3125;
  font-size: 0.8rem;
  background-color: #ffffff;
  color: #121a2d;
  padding: 1rem;
}

@media (max-width: 1140px) {
  .c-button--white {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.c-button--white span {
  position: relative;
  z-index: 2;
}

.c-button--white:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: #e6e6e6;
  will-change: transform, background-color;
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 1140px) {
  .c-button--white:hover span {
    color: #121a2d;
  }
  .c-button--white:hover:before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 50%;
    transition-timing-function: ease;
  }
}

.c-button--white.is-hovered span {
  color: #121a2d;
}

.c-button--white.is-hovered:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
  transition-timing-function: ease;
}

.c-button--block {
  display: block;
  width: 100%;
}

.c-button--round {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 100px;
}

.c-button--round .icon {
  margin: 0;
  margin-top: -1px;
}

.c-button--round .c-button__layer {
  backface-visibility: hidden;
  will-change: transform;
  border-radius: 200px;
}

.c-button--stroke {
  line-height: 1;
  font-size: 5.25rem;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0);
}

@media (max-width: 1140px) {
  .c-button--stroke {
    line-height: 1.05714;
    font-size: 3.5rem;
  }
}

@media (max-width: 480px) {
  .c-button--stroke {
    line-height: 1.18182;
    font-size: 1.65rem;
  }
}

.c-button--stroke.c-button--large, .c-button--stroke.c-button--extra-large {
  -webkit-text-stroke-width: 2px;
}

.c-button--stroke:hover {
  color: #ffffff;
  -webkit-text-stroke-color: #121a2d;
}

.c-button--text {
  line-height: 1.5;
  font-size: 0.9rem;
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 1140px) {
  .c-button--text {
    line-height: 1.5;
    font-size: 0.8rem;
  }
}

@media (max-width: 720px) {
  .c-button--text {
    line-height: 1.5;
    font-size: 0.7rem;
  }
}

.c-button--text:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #121a2d;
  z-index: 2;
  will-change: transform, color;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
  transition: transform 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 1140px) {
  .c-button--text:hover:before {
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;
    transition-timing-function: ease;
  }
}

.c-button--text.is-active:before {
  background-color: rgba(0, 0, 0, 0.1);
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
}

.c-button--medium {
  line-height: 1.24324;
  font-size: 1.85rem;
}

@media (max-width: 1140px) {
  .c-button--medium {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-button--medium {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-button--medium:before {
  height: 3px;
  bottom: 2px;
}

.c-button--medium.c-button--bordered:before {
  height: 3px;
  bottom: 2px;
}

.c-button--large {
  line-height: 1.25;
  font-size: 2.35rem;
}

@media (max-width: 1140px) {
  .c-button--large {
    line-height: 1.25;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .c-button--large {
    line-height: 1.25;
    font-size: 1.5rem;
  }
}

.c-button--large:before {
  height: 3px;
  bottom: 2px;
}

.c-button--large.c-button--bordered:before {
  height: 3px;
  bottom: 2px;
}

.c-button--extra-large {
  line-height: 1.07692;
  font-size: 3.25rem;
}

@media (max-width: 1140px) {
  .c-button--extra-large {
    line-height: 1.04348;
    font-size: 2.3rem;
  }
}

@media (max-width: 720px) {
  .c-button--extra-large {
    line-height: 1.13333;
    font-size: 1.5rem;
  }
}

.c-button--extra-large:before {
  height: 6px;
  bottom: 4px;
}

.c-button--extra-large.c-button--bordered:before {
  height: 6px;
  bottom: 4px;
}

.c-button--small {
  line-height: 1.5;
  font-size: 0.7rem;
  padding-top: 0;
  padding-bottom: 0;
}

.c-button--small:before {
  height: 1px;
  bottom: 1px;
}

.c-button--small.c-button--bordered:before {
  height: 1px;
  bottom: 1px;
}

.c-button--bordered:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 3;
  background-color: #121a2d;
  will-change: transform, color;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
  transition: transform 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 1140px) {
  .c-button--bordered:hover:before {
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;
    transition-timing-function: ease;
  }
}

.c-button--bordered-if-active:before {
  opacity: 0;
  visibility: hidden;
}

.c-button--bordered-if-active.is-active:before {
  opacity: 1;
  visibility: visible;
}

.c-link {
  text-decoration: underline;
  display: inline;
}

.c-button--empty {
  width: 60px;
  height: 60px;
  border: 2px solid rgba(219, 219, 219, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-button--empty:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 100%;
  z-index: -1;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-button--empty:hover:before {
  transform: scale(1);
}

.c-button--empty:hover .icon {
  color: #121a2d;
}

.c-button--empty .icon {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin: 0;
  color: #ffffff;
}

.c-clip-element {
  position: fixed;
  z-index: 20;
}

.c-clip-element--logo {
  width: 65px;
  height: 65px;
  transform: translateY(20px) translateX(40px);
}

.is-mobile .c-clip-element--logo {
  position: absolute !important;
}

@media (max-width: 720px) {
  .c-clip-element--logo {
    width: 65px;
    height: 65px;
    transform: translateY(20px) translateX(16px);
  }
}

.c-clip-element--logo .crealab {
  width: 65px;
  height: 65px;
}

.c-clip-element--logo .crealab.crealab--dark {
  display: none;
}

.c-clip-element--logo .crealab.crealab--colored {
  display: none;
}

.c-clip-element--menu-trigger {
  width: 50px;
  height: 78px;
  right: 0 !important;
  left: auto !important;
  /* autoprefixer: ignore next */
  transform: translateY(10px) translateX(-40px);
}

.is-mobile .c-clip-element--menu-trigger {
  position: absolute !important;
}

@media (max-width: 720px) {
  .c-clip-element--menu-trigger {
    width: 50px;
    height: 78px;
    left: auto !important;
    transform: translateY(16px) translateX(-20px);
  }
}

.c-clip-element--email {
  width: 20px;
  height: 210px;
  margin-left: 40px;
  bottom: 0 !important;
  /* autoprefixer: ignore next */
  -ms-transform: translateY(100vh) translateY(-210px);
  transform: translateY(calc(100vh - 210px)) translateX(0);
}

@media (max-width: 720px) {
  .c-clip-element--email {
    display: none;
  }
}

.clip-header.is-black {
  z-index: 2;
}

.clip-header.is-black .crealab--dark {
  display: flex;
}

.clip-header.is-black .crealab--light {
  display: none;
}

.clip-header.is-black .crealab--colored {
  display: none;
}

.clip-header.is-colored {
  z-index: 2;
}

.clip-header.is-colored .crealab--light {
  display: none;
}

.clip-header.is-colored .crealab--dark {
  display: none;
}

.clip-header.is-colored .crealab--colored {
  display: flex;
}

.clip-header.is-white {
  z-index: 1;
}

.clip-header.is-white .crealab--dark {
  display: none;
}

.clip-header.is-white .crealab--colored {
  display: none;
}

.clip-header.is-white .crealab--light {
  display: flex;
}

.clip-header.is-white .c-menu-trigger__bar {
  background-color: #ffffff;
}

.is-menu-opened .clip-header.is-white .c-menu-trigger__bar {
  background-color: #121a2d;
}

.clip-header.is-white .c-navigation-email {
  color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation__left .c-button--text {
  color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation__left .c-button--text:before {
  background-color: rgba(255, 255, 255, 0.1);
}

.clip-header.is-white .c-secondary-navigation__left .c-button--text.is-active {
  color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation__left .c-button--text.is-active:before {
  background-color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation__right .c-button--primary {
  border: 1px solid #ffffff;
  color: #121a2d;
  background-color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation__right .c-button--primary:before {
  background-color: #121a2d;
}

.clip-header.is-white .c-secondary-navigation__right .c-button--primary:hover span {
  color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation-links-mobile .c-button--text {
  color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation-links-mobile .c-button--text.is-active {
  color: #ffffff;
}

.clip-header.is-white .c-secondary-navigation-links-mobile .c-button--text.is-active:before {
  background-color: #ffffff;
}

.clip-inner {
  overflow: hidden !important;
}

.c-navigation {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 21;
}

@media (max-width: 720px) {
  .c-navigation {
    width: 0;
  }
}

.c-navigation__trigger {
  position: absolute;
  top: 10px;
  z-index: 30;
  width: 100%;
  left: calc(100% - 80px);
}

@media (max-width: 720px) {
  .c-navigation__trigger {
    left: calc(100% - 60px);
  }
}

.is-mobile .c-navigation__trigger {
  padding-left: 11.11111%;
  padding-right: 6.66667%;
}

@media (max-width: 720px) {
  .is-mobile .c-navigation__trigger {
    top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

.c-navigation__logo {
  position: absolute;
  top: 1rem;
  z-index: 4;
  width: 100%;
  padding-left: 40px;
}

.is-mobile .c-navigation__logo {
  padding-left: 11.11111%;
  padding-right: 6.66667%;
}

@media (max-width: 720px) {
  .is-mobile .c-navigation__logo {
    top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.c-navigation__logo img {
  width: 300px;
  height: auto;
}

.c-navigation__menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 1;
  transform: translateX(-100%);
  will-change: transform;
  overflow: hidden;
}

.c-navigation__menu-left {
  width: 46.666666662vh;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  will-change: transform;
  overflow: hidden;
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 1140px) {
  .c-navigation__menu-left {
    width: 36.666666662vh;
  }
}

@media (max-width: 720px) {
  .c-navigation__menu-left {
    width: 0;
  }
}

.c-navigation__menu-left .c-logo {
  margin: 0 auto;
}

@media (max-width: 480px) {
  .c-navigation__menu-left .c-logo {
    display: none;
  }
}

.c-navigation__menu-right {
  background-color: #ffffff;
  width: 100%;
  padding-left: 46.666666662vh;
  height: 100vh;
  will-change: transform;
  position: relative;
  z-index: 3;
}

@media (max-width: 1140px) {
  .c-navigation__menu-right {
    padding-left: 36.666666662vh;
  }
}

@media (max-width: 720px) {
  .c-navigation__menu-right {
    padding-left: 20px;
  }
}

.c-navigation__menu-right:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 90px;
  right: 46.666666662vh;
  background-color: #ffffff;
  display: block;
  z-index: 3;
}

.c-navigation__menu-right .c-cookie-privacy-info {
  width: auto;
  position: absolute;
  bottom: 40px;
  right: 46.666666662vh;
  margin-right: 4.16667%;
}

.is-mobile .c-navigation__menu-right .c-cookie-privacy-info {
  right: 6.66667%;
  margin-right: 0;
}

.c-navigation__menu-right .c-button--text:before {
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
}

@media (min-width: 1140px) {
  .c-navigation__menu-right .c-button--text:hover:before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 50%;
    transition-timing-function: ease;
  }
}

.c-navigation__menu-right .c-button--text.is-active:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

.navigation-links-wrapper {
  width: 100%;
  position: relative;
  height: 100vh;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 8.82353%;
  display: flex;
  align-items: center;
}

.navigation-links-wrapper__inner {
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 100%;
  padding-top: 120px;
  padding-bottom: 60px;
  padding-left: 8.82353%;
  padding-right: 8.82353%;
  overflow: auto;
}

@media (max-width: 1140px) {
  .navigation-links-wrapper__inner {
    padding-top: 90px;
  }
}

@media (max-width: 720px) {
  .navigation-links-wrapper__inner {
    padding-top: 60px;
  }
}

.is-mobile .navigation-links-wrapper__inner {
  padding-left: 0;
  padding-right: 0;
}

.is-ie .navigation-links-wrapper__inner {
  top: 0;
}

.navigation-links.navigation-links--top {
  padding-bottom: 1rem;
}

.navigation-links.navigation-links--top .navigation-links__item {
  margin-bottom: 1rem;
}

@media (max-width: 720px) {
  .navigation-links.navigation-links--top .navigation-links__item {
    margin-bottom: 0.5rem;
  }
}

.navigation-links.navigation-links--top .navigation-links__item .c-button {
  padding-top: 4px;
  padding-bottom: 4px;
}

@media (max-width: 720px) {
  .navigation-links.navigation-links--bottom .navigation-links__item {
    margin-bottom: 0;
  }
}

.navigation-links.navigation-links--locale {
  display: flex;
}

.navigation-links.navigation-links--locale .navigation-links__item {
  transition: transform 0.35s;
}

.navigation-links.navigation-links--locale .navigation-links__item:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 720px) {
  .navigation-links.navigation-links--locale .navigation-links__item {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.navigation-links.navigation-links--locale .navigation-links__item a {
  display: block;
}

.navigation-links.navigation-links--locale .navigation-links__item a img {
  height: 30px;
  width: auto;
}

.navigation-links.navigation-links--locale .navigation-links__item.active a img {
  opacity: .5;
}

.c-navigation-email {
  display: block;
  width: 20px;
  height: 180px;
  writing-mode: vertical-rl;
  transform-origin: center;
  transform: rotate(-180deg);
}

.c-navigation-email a {
  display: block;
}

.c-menu-bg__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 46.766666662vh;
  height: 100%;
  background-color: #ffffff;
  z-index: 5;
  pointer-events: none;
}

.c-menu-bg__canvas {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%) translateY(-50%);
}

.c-menu-bg__canvas .canvas, .c-menu-bg__canvas canvas, .c-menu-bg__canvas img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.c-menu-bg__canvas .canvas {
  z-index: 2;
}

.c-menu-bg__canvas img {
  z-index: 1;
}

.c-menu-bg__list {
  display: none;
}

.c-menu-bg__image {
  opacity: 0;
  visibility: hidden;
  background-size: cover;
  background-position: center;
}

.c-menu-bg__logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  vertical-align: middle;
}

.c-menu-bg__logo img {
  max-width: 100%;
  display: inline-block;
}

.c-menu-bg__logo-bg {
  height: 100vh;
  width: 100%;
  background-color: #e54360;
  position: relative;
  top: 50%;
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%) translateY(-50%);
}

.c-menu-bg,
.c-menu-bg__item,
.c-menu-bg__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.c-secondary-navigation {
  height: 38px;
  position: fixed;
  transform: translateY(26px) translateX(0);
  left: 0;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  z-index: 18;
  padding-left: 10.41667%;
  padding-right: 2.08333%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}

@media (max-width: 720px) {
  .c-secondary-navigation {
    transform: translateY(26px) translateX(0);
  }
}

.c-secondary-navigation__left {
  pointer-events: all;
}

@media (max-width: 720px) {
  .c-secondary-navigation__left {
    width: 100%;
    text-align: center;
  }
}

.c-secondary-navigation__right {
  pointer-events: all;
  position: relative;
}

.c-secondary-navigation .c-button--text:before {
  display: none;
}

.c-secondary-navigation .clip-inner {
  width: 100%;
  padding-left: 10.41667%;
  padding-right: 2.08333%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1140px) {
  .c-secondary-navigation .clip-inner {
    padding-left: 12.5%;
  }
}

@media (max-width: 720px) {
  .c-secondary-navigation .clip-inner {
    padding-left: 16.66667%;
    padding-right: 16.66667%;
  }
}

.c-secondary-navigation .c-button--text {
  color: #121a2d;
}

.c-secondary-navigation .c-button--text:before {
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  background-color: rgba(0, 0, 0, 0.1);
}

.c-secondary-navigation .c-button--text:hover:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

.c-secondary-navigation .c-button--text.is-active {
  color: #121a2d;
}

.c-secondary-navigation .c-button--text.is-active:before {
  display: block;
  background-color: #121a2d;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

.c-secondary-navigation .c-button--bordered.is-active:after {
  background-color: #121a2d;
}

.c-secondary-navigation-links {
  padding-left: 1rem;
}

@media (max-width: 1140px) {
  .c-secondary-navigation-links {
    padding-left: 0;
  }
}

.c-secondary-navigation-links__item {
  display: inline-block;
  position: relative;
  margin-right: 1rem;
}

@media (max-width: 720px) {
  .c-secondary-navigation-links__item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.c-secondary-navigation-mobile {
  width: 100%;
  position: absolute !important;
  top: 5rem !important;
  left: 0;
  padding: 0 20px;
  z-index: 3;
}

.c-secondary-navigation-mobile .c-button .icon {
  margin-left: 0;
}

.c-secondary-navigation-mobile .clip-inner {
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-secondary-navigation-mobile .c-button--text {
  color: #121a2d;
}

.c-secondary-navigation-mobile .c-button--text:before {
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
  background-color: rgba(0, 0, 0, 0.1);
}

.c-secondary-navigation-mobile .c-button--text.is-active {
  color: #121a2d;
}

.c-secondary-navigation-mobile .c-button--text.is-active:before {
  display: block;
  background-color: #121a2d;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

.c-secondary-navigation-links-mobile__item {
  display: inline-block;
  margin-right: 1rem;
}

.c-navbar {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-navbar.is-hidden {
  top: -100%;
}

.c-navbar.is-visible {
  top: 0;
}

.c-navbar__inner {
  position: relative;
  height: 100%;
  display: flex;
}

.c-navbar .c-clip-element--link {
  top: 50%;
  position: absolute;
  transform: translateY(-50%) translateX(160px);
  margin-top: -12px;
}

.c-navbar .c-clip-element--link .c-button--text {
  color: #ffffff;
}

.c-navbar .c-clip-element--link .c-button--text:before {
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.c-navbar .c-clip-element--link .c-button--text:hover:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

.c-navbar .c-clip-element--link .c-button--text.is-active {
  color: #ffffff;
}

.c-navbar .c-clip-element--link .c-button--text.is-active:before {
  display: block;
  background-color: #ffffff;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

.c-navbar .c-clip-element--link .c-button--bordered.is-active:after {
  background-color: #ffffff;
}

.c-hide-on-scroll-down {
  z-index: 10;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-hide-on-scroll-down.is-hidden {
  top: -100%;
  position: fixed;
}

.c-hide-on-scroll-down.is-visible {
  top: 0;
  position: fixed;
}

.c-logo {
  text-align: center;
}

.c-logo--navigation a {
  display: block;
  position: relative;
}

.c-logo--large .crealab {
  width: 176px;
  heicght: 176px;
}

.crealab {
  display: inline-block;
  margin: 0 auto;
  position: relative;
}

.crealab img {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  height: 65px;
  max-width: 100%;
}

.crealab canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
.icon-blockchain-1 {
  width: 1.5em;
}

.icon-blockchain-2 {
  width: 1.5em;
}

.icon-blockchain-3 {
  width: 1.5em;
}

.icon-custom-software-1 {
  width: 1.5em;
}

.icon-custom-software-2 {
  width: 1.5em;
}

.icon-custom-software-3 {
  width: 1.5em;
}

.icon-digital-products-2 {
  width: 1.5em;
}

.icon-digital-products-3 {
  width: 1.5em;
}

.icon-digital-products-1 {
  width: 1.5em;
}

.icon-interactive-1 {
  width: 1.5em;
}

.icon-interactive-2 {
  width: 1.5em;
}

.icon-interactive-3 {
  width: 1.5em;
}

.icon-mobile-apps-1 {
  width: 1.5em;
}

.icon-mobile-apps-2 {
  width: 1.5em;
}

.icon-Mobile-apps-3 {
  width: 1.5em;
}

.icon-websites-1 {
  width: 1.5em;
}

.icon-websites-2 {
  width: 1.5em;
}

.icon-websites-3 {
  width: 1.5em;
}

.c-menu-trigger {
  display: block;
  padding: 20px 0;
  width: 50px;
  height: 78px;
  cursor: pointer;
}

.c-menu-trigger__bar {
  display: inline-block;
  width: 50px;
  height: 4px;
  float: left;
  margin-bottom: 12px;
  padding: 0;
  background-color: #121a2d;
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  backface-visibility: hidden;
  border-radius: 4px;
  overflow: hidden;
}

.c-menu-trigger__bar:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #121a2d;
  will-change: transform, color;
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-menu-trigger__bar.c-menu-trigger__bar--top:before {
  transition: transform 0.35s;
}

.c-menu-trigger__bar.c-menu-trigger__bar--middle:before {
  transition: transform 0.35s 0.1s;
}

.c-menu-trigger__bar.c-menu-trigger__bar--bottom {
  margin-bottom: 0;
}

.c-menu-trigger__bar.c-menu-trigger__bar--bottom:before {
  transition: transform 0.35s 0.2s;
}

@media (min-width: 1140px) {
  .c-menu-trigger:hover .c-menu-trigger__bar:before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 50%;
    transition-timing-function: ease;
  }
}

.c-menu-trigger.is-disabled {
  pointer-events: none;
}

.c-menu-trigger.is-opened .c-menu-trigger__bar--top {
  transform: translateY(15px) rotate(-45deg);
}

.c-menu-trigger.is-opened .c-menu-trigger__bar--middle {
  transform: scaleX(0);
}

.c-menu-trigger.is-opened .c-menu-trigger__bar--bottom {
  transform: translateY(-15px) rotate(45deg);
}

.c-menu-trigger.is-opened:hover .c-menu-trigger__bar:before {
  background-color: #e54360;
}

.c-header {
  padding: 12.5vh 0;
  position: relative;
  height: 100vh;
}

@media (max-width: 720px) {
  .c-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.c-header .o-container {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.c-header .c-work-single-visual {
  z-index: 1;
}

.c-header__home-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transform: rotate(90deg);
  width: 40px;
  height: 40px;
  position: absolute;
  left: auto;
  top: calc(100vh - 60px);
  right: 30px;
  cursor: pointer;
  z-index: 3;
  animation-name: arrowAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.c-header__home-arrow .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 720px) {
  .c-header__home-arrow {
    display: none;
  }
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(-20px) rotate(90deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translateY(10px) rotate(90deg);
    opacity: 0;
  }
}

.c-header__intro {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 630px;
  margin-top: 5rem;
}

@media (max-width: 720px) {
  .c-header__intro {
    margin-top: 3rem;
  }
}

@media (max-width: 480px) {
  .c-header__intro {
    display: block;
  }
}

.c-header__intro-column {
  max-width: 300px;
  padding-right: 30px;
}

@media (max-width: 480px) {
  .c-header__intro-column {
    width: 100%;
    padding-right: 0;
    max-width: 320px;
    margin-bottom: 1rem;
  }
  .c-header__intro-column:last-of-type {
    margin-bottom: 0;
  }
}

.c-header__cta {
  padding-top: 40px;
}

.c-header__heading {
  width: 100%;
}

.c-header__slider {
  margin-top: 2rem;
}

.c-header__slider-nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  margin-top: 1.5rem;
}

.c-header__slider-prev {
  margin-right: 13px;
}

.c-header .c-header__intro--home {
  margin-top: 0;
}

body:not(.home) .c-header__home-arrow {
  display: none;
}

.c-header--home {
  padding-top: 30vh;
  padding-bottom: 30vh;
  background-color: #e54360;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

@media (max-width: 720px) {
  .c-header--home {
    padding-top: 25vh;
    padding-bottom: 25vh;
  }
}

.c-header--home .c-button--primary {
  border: 1px solid #ffffff;
  color: #121a2d;
  background-color: #ffffff;
  margin-top: 10px;
  min-width: 135px;
}

.c-header--home .c-button--primary:before {
  background-color: #121a2d;
}

.c-header--home .c-button--primary:hover span {
  color: #ffffff;
}

.c-header--home .u-a7 {
  line-height: 1;
  font-size: 5.25rem;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  letter-spacing: -1.2px;
}

@media (max-width: 1140px) {
  .c-header--home .u-a7 {
    line-height: 1.05714;
    font-size: 3.5rem;
  }
}

@media (max-width: 480px) {
  .c-header--home .u-a7 {
    line-height: 1.04;
    font-size: 2.5rem;
  }
}

.c-header--home .u-a7.u-a7--fluid {
  font-size: 5.55556vw;
  max-width: 1200px;
}

@media (min-width: 1400px) {
  .c-header--home .u-a7.u-a7--fluid {
    letter-spacing: -0.06944vw;
  }
}

@media (max-width: 1140px) {
  .c-header--home .u-a7.u-a7--fluid {
    line-height: 1.05556;
    font-size: 4.5rem;
    letter-spacing: -1.2px;
  }
}

@media (max-width: 1140px) and (max-width: 1400px) {
  .c-header--home .u-a7.u-a7--fluid {
    line-height: 1.05263;
    font-size: 3.8rem;
  }
}

@media (max-width: 1140px) and (max-width: 1140px) {
  .c-header--home .u-a7.u-a7--fluid {
    line-height: 1.08333;
    font-size: 3rem;
  }
}

@media (max-width: 1140px) and (max-width: 720px) {
  .c-header--home .u-a7.u-a7--fluid {
    line-height: 1.11111;
    font-size: 2.25rem;
  }
}

@media (max-width: 1140px) and (max-width: 480px) {
  .c-header--home .u-a7.u-a7--fluid {
    line-height: 1.12821;
    font-size: 1.95rem;
  }
}

.c-header--home .u-a7 span {
  display: block;
}

@media (max-width: 1140px) {
  .c-header--home .u-a7 span {
    display: inline;
  }
}

.c-header--home .u-a7 > div {
  overflow: hidden;
}

.c-header--home .c-header__heading {
  max-width: 87.57062%;
}

.u-lang--sk .c-header .u-a7,
.u-lang--sk .c-header .u-a7--fluid,
.u-lang--sk .c-header--home .u-a7,
.u-lang--sk .c-header--home .u-a7--fluid {
  font-size: 4.86111vw;
}

@media (max-width: 480px) {
  .u-lang--sk .c-header .u-a7,
  .u-lang--sk .c-header .u-a7--fluid,
  .u-lang--sk .c-header--home .u-a7,
  .u-lang--sk .c-header--home .u-a7--fluid {
    font-size: 10.41667vw;
  }
}

.c-header--services {
  padding-top: 20vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .c-header--services {
    padding-top: 25vh;
  }
}

.c-header--services .u-a7 {
  max-width: 94.28571%;
}

@media (max-width: 720px) {
  .c-header--services .u-a7 {
    max-width: 100%;
  }
}

.c-header--service {
  padding-top: 20vh;
  padding-bottom: 2.5vh;
  color: #ffffff;
}

.c-header--service h1 {
  padding-bottom: 25px;
}

.c-header--service h1,
.c-header--service h2 {
  max-width: 960px;
  width: 100%;
}

.c-header--culture {
  padding-top: 20vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .c-header--culture {
    padding-top: 25vh;
  }
}

.c-header--culture .u-a7 {
  max-width: 91.42857%;
}

.c-header--our-approach {
  padding-top: 20vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .c-header--our-approach {
    padding-top: 25vh;
  }
}

.c-header--our-approach .u-a7 {
  margin-bottom: 0.4em;
  max-width: 80%;
}

.c-header--our-approach .u-a3 {
  max-width: 840px;
}

.c-header--blog {
  padding-top: 20vh;
  padding-bottom: 0;
  background-color: #dbe2ec;
}

.c-header--resources {
  padding-top: 20vh;
  padding-bottom: 0;
}

.c-header--blog-single {
  padding-top: 20vh;
  padding-bottom: 0.5rem;
}

.c-header--blog-single .u-a5 {
  margin-bottom: 1.1em;
  max-width: 840px;
}

.c-header--blog-single .c-details-info {
  display: flex;
}

@media (max-width: 720px) {
  .c-header--blog-single .c-details-info {
    display: block;
  }
}

.c-header--careers {
  padding-top: 20vh;
  padding-bottom: 10vh;
}

.c-header--careers .u-a7 {
  padding-bottom: 2rem;
}

.c-header--careers .u-a3 {
  max-width: 720px;
}

.c-header--careers-single {
  background-color: #0d0d0d;
  color: #ffffff;
  padding-top: 20vh;
  padding-bottom: 10vh;
}

.c-header--careers-single .u-b4 {
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 2rem;
}

@media (max-width: 720px) {
  .c-header--careers-single .u-b4 {
    margin-bottom: 0;
  }
}

.c-header--careers-single .u-b4.c-details-info__item {
  color: #ffffff;
}

.c-header--careers-single .u-b0 {
  padding-top: 15px;
}

.c-header--careers-single .c-details-info {
  margin-top: 3rem;
  padding-top: 1rem;
}

@media (max-width: 720px) {
  .c-header--careers-single .c-details-info {
    margin-bottom: 0;
  }
}

.c-header--work {
  padding-top: 20vh;
  padding-bottom: 20vh;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.c-header--work .u-a7 {
  max-width: 68.57143%;
}

.c-header--work-single-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  position: relative;
}

@media (max-width: 720px) {
  .c-header--work-single-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 720px) {
  .c-header--work-single-wrapper {
    flex-flow: column;
  }
}

.c-header--work-single-wrapper .c-header--work-single.c-header--work-prev, .c-header--work-single-wrapper .c-header--work-single.c-header--work-next {
  width: 100%;
  float: left;
}

.c-header--work-single-wrapper--columns-1 {
  flex-flow: column;
}

.c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-prev, .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-next {
  width: 50%;
}

@media (max-width: 720px) {
  .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-prev, .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-next {
    width: 100%;
  }
}

.c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-prev .u-a7.smaller, .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-next .u-a7.smaller {
  white-space: nowrap;
  font-size: 4.86111vw;
  letter-spacing: -0.06944vw;
}

@media (max-width: 720px) {
  .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-prev .u-a7.smaller, .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-next .u-a7.smaller {
    font-size: 6.94444vw;
  }
}

.c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-prev .u-a2.smaller, .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-next .u-a2.smaller {
  font-size: 1.45833vw;
}

@media (max-width: 720px) {
  .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-prev .u-a2.smaller, .c-header--work-single-wrapper--columns-2 .c-header--work-single.c-header--work-next .u-a2.smaller {
    font-size: 4.23611vw;
  }
}

@media (min-width: 1140px) {
  .c-header--work-single-wrapper--columns-1 .c-header--work-single:not(.is-loading) .c-work-single-visual, .c-header--work-single-wrapper--columns-2 .c-header--work-single:not(.is-loading) .c-work-single-visual {
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

@media (min-width: 1140px) {
  .c-header--work-single-wrapper--columns-1 .c-header--work-single:not(.is-loading):hover .c-work-single-visual, .c-header--work-single-wrapper--columns-2 .c-header--work-single:not(.is-loading):hover .c-work-single-visual {
    transform: translateY(-10%);
  }
}

@media (min-width: 1140px) {
  .c-header--work-single-wrapper--columns-1 .c-header--work-single:not(.is-loading):hover .c-work-single-visual {
    transform: translateY(-5%);
  }
}

.c-header--work-single {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  color: #ffffff;
  position: relative;
  z-index: 2;
  height: 100vh;
}

.c-header--work-single.text-dark {
  color: #000000;
}

.c-header--work-single.text-light {
  color: #ffffff;
}

.c-header--work-single .u-a7 {
  white-space: nowrap;
}

@media (max-width: 720px) {
  .c-header--work-single {
    height: auto;
    min-height: 100vh;
    padding-bottom: 56.25%;
  }
}

@media (max-width: 720px) {
  .c-header--work-single .o-container {
    padding-top: 3rem;
  }
}

.c-header--work-single.is-loading {
  z-index: 3;
}

.c-header--work-single.c-header--work-prev, .c-header--work-single.c-header--work-next {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  position: relative;
  height: 80vh;
}

@media (max-width: 720px) {
  .c-header--work-single.c-header--work-prev, .c-header--work-single.c-header--work-next {
    height: 100vh;
    padding-bottom: 56.25%;
  }
}

.c-header--work-single.c-header--work-prev a, .c-header--work-single.c-header--work-next a {
  display: block;
  height: 80vh;
}

@media (max-width: 720px) {
  .c-header--work-single.c-header--work-prev a, .c-header--work-single.c-header--work-next a {
    height: 100vh;
  }
}

@media (min-width: 1140px) {
  .c-header--work-single.c-header--work-prev:hover, .c-header--work-single.c-header--work-next:hover {
    /*
                &:before {
                    transform: translateY(-100%);
                }
*/
  }
  .c-header--work-single.c-header--work-prev:hover .u-a5, .c-header--work-single.c-header--work-next:hover .u-a5 {
    transform: translateY(-10px);
  }
  .c-header--work-single.c-header--work-prev:hover .u-a5:before, .c-header--work-single.c-header--work-next:hover .u-a5:before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 50%;
    transition-timing-function: ease;
  }
}

.c-header--work-single.c-header--work-prev .u-a5, .c-header--work-single.c-header--work-next .u-a5 {
  position: relative;
  display: inline-block;
  transition: transform 0.65s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.c-header--work-single.c-header--work-prev .u-a5:before, .c-header--work-single.c-header--work-next .u-a5:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ffffff;
  z-index: 2;
  will-change: transform, color;
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

.c-header--team {
  padding-top: 20vh;
  padding-bottom: 3rem;
}

@media (max-width: 720px) {
  .c-header--team {
    padding-top: 25vh;
  }
}

.c-header--contact {
  padding-top: 20vh;
  padding-bottom: 0;
}

.c-header--contact .u-a7 {
  max-width: 91.42857%;
}

.c-header--error-page {
  padding-top: 30vh;
  padding-bottom: 30vh;
  background-color: #e54360;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

@media (max-width: 720px) {
  .c-header--error-page {
    padding-top: 25vh;
    padding-bottom: 25vh;
  }
}

.c-header--error-page .u-a7 {
  line-height: 1;
  font-size: 5.25rem;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  letter-spacing: -1.2px;
}

@media (max-width: 1140px) {
  .c-header--error-page .u-a7 {
    line-height: 1.05714;
    font-size: 3.5rem;
  }
}

@media (max-width: 480px) {
  .c-header--error-page .u-a7 {
    line-height: 1.04;
    font-size: 2.5rem;
  }
}

.c-header--error-page .u-a7.u-a7--fluid {
  font-size: 7.63889vw;
  max-width: 1200px;
}

@media (min-width: 1400px) {
  .c-header--error-page .u-a7.u-a7--fluid {
    letter-spacing: -0.06944vw;
  }
}

@media (max-width: 1140px) {
  .c-header--error-page .u-a7.u-a7--fluid {
    line-height: 1.05556;
    font-size: 4.5rem;
    letter-spacing: -1.2px;
  }
}

@media (max-width: 1140px) and (max-width: 1400px) {
  .c-header--error-page .u-a7.u-a7--fluid {
    line-height: 1.05263;
    font-size: 3.8rem;
  }
}

@media (max-width: 1140px) and (max-width: 1140px) {
  .c-header--error-page .u-a7.u-a7--fluid {
    line-height: 1.08333;
    font-size: 3rem;
  }
}

@media (max-width: 1140px) and (max-width: 720px) {
  .c-header--error-page .u-a7.u-a7--fluid {
    line-height: 1.11111;
    font-size: 2.25rem;
  }
}

@media (max-width: 1140px) and (max-width: 480px) {
  .c-header--error-page .u-a7.u-a7--fluid {
    line-height: 1.12821;
    font-size: 1.95rem;
  }
}

.c-header--error-page .u-a7 span {
  display: block;
}

@media (max-width: 1140px) {
  .c-header--error-page .u-a7 span {
    display: inline;
  }
}

.c-header--error-page .u-a7 > div {
  overflow: hidden;
}

.c-header--error-page .c-header__heading {
  max-width: 87.57062%;
}

.u-lang--sk .c-header .u-a7,
.u-lang--sk .c-header .u-a7--fluid,
.u-lang--sk .c-header--error-page .u-a7,
.u-lang--sk .c-header--error-page .u-a7--fluid {
  font-size: 4.86111vw;
}

@media (max-width: 480px) {
  .u-lang--sk .c-header .u-a7,
  .u-lang--sk .c-header .u-a7--fluid,
  .u-lang--sk .c-header--error-page .u-a7,
  .u-lang--sk .c-header--error-page .u-a7--fluid {
    font-size: 10.41667vw;
  }
}

.c-header--error-page {
  /*height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    padding-left: 0;
    background-color: getColor('green', 'sections');

    //overrides
    .o-container {
        max-width: 100%;
        padding: percentage(1/24);
    }

    .u-a7 {

    }

    .u-b0 {
        max-width: 13 * $settings-grid-column-width;
        margin: 0 auto;
    }

    .c-button {
        margin-top: 40px;
    }*/
}

.c-header--work-list {
  padding-top: 20vh;
  padding-bottom: 5rem;
  height: auto;
}

.c-header--about {
  padding-top: 20vh;
  padding-bottom: 5rem;
  height: auto;
}

.c-header--about .c-svg-hand {
  margin-left: 1rem;
}

@media (max-width: 480px) {
  .c-header--about .c-svg-hand {
    margin-left: 0.5rem;
  }
}

.c-header--generic {
  padding-bottom: 0;
  height: auto;
}

.is-protected .c-header--generic .u-b4,
.is-protected .c-header--generic .u-a5 {
  max-width: 660px;
}

.c-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.c-header__bg-inner {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  perspective-origin: 50% 50%;
}

.c-header__bg-item {
  position: absolute;
  bottom: -100vh;
  bottom: -100%;
  transform-style: preserve-3d;
}

.c-header__bg-item img {
  max-height: 100%;
  width: auto;
}

.c-header__bg .c-header__bg-item:nth-child(1) {
  height: 15px;
  width: auto;
  opacity: .35;
  left: 7%;
  animation: raise1 12s linear infinite;
  animation-delay: 0s;
  transform: scale(-0.3) rotate(192deg);
  filter: blur(-5px);
}

@keyframes raise1 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(-0.3) rotate(138deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(2) {
  height: 30px;
  width: auto;
  opacity: .35;
  left: 40%;
  animation: raise2 9s linear infinite;
  animation-delay: -2s;
  transform: scale(0) rotate(149deg);
  filter: blur(-4px);
}

@keyframes raise2 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(0) rotate(14deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(3) {
  height: 45px;
  width: auto;
  opacity: .35;
  left: 87%;
  animation: raise3 19s linear infinite;
  animation-delay: 0s;
  transform: scale(0.3) rotate(87deg);
  filter: blur(-3px);
}

@keyframes raise3 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(0.3) rotate(252deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(4) {
  height: 60px;
  width: auto;
  opacity: .35;
  left: -3%;
  animation: raise4 12s linear infinite;
  animation-delay: -2s;
  transform: scale(0.6) rotate(289deg);
  filter: blur(-2px);
}

@keyframes raise4 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(0.6) rotate(39deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(5) {
  height: 75px;
  width: auto;
  opacity: .35;
  left: 92%;
  animation: raise5 8s linear infinite;
  animation-delay: -2s;
  transform: scale(0.9) rotate(316deg);
  filter: blur(-1px);
}

@keyframes raise5 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(0.9) rotate(111deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(6) {
  height: 90px;
  width: auto;
  opacity: .35;
  left: -18%;
  animation: raise6 12s linear infinite;
  animation-delay: -4s;
  transform: scale(1.2) rotate(188deg);
  filter: blur(0px);
}

@keyframes raise6 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(1.2) rotate(315deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(7) {
  height: 105px;
  width: auto;
  opacity: .35;
  left: -16%;
  animation: raise7 10s linear infinite;
  animation-delay: -1s;
  transform: scale(1.5) rotate(78deg);
  filter: blur(1px);
}

@keyframes raise7 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(1.5) rotate(15deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(8) {
  height: 120px;
  width: auto;
  opacity: .35;
  left: 0%;
  animation: raise8 12s linear infinite;
  animation-delay: -2s;
  transform: scale(1.8) rotate(255deg);
  filter: blur(2px);
}

@keyframes raise8 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(1.8) rotate(322deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(9) {
  height: 135px;
  width: auto;
  opacity: .35;
  left: 53%;
  animation: raise9 18s linear infinite;
  animation-delay: -4s;
  transform: scale(2.1) rotate(291deg);
  filter: blur(3px);
}

@keyframes raise9 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(2.1) rotate(273deg);
    opacity: -.2;
  }
}

.c-header__bg .c-header__bg-item:nth-child(10) {
  height: 150px;
  width: auto;
  opacity: .35;
  left: 63%;
  animation: raise10 8s linear infinite;
  animation-delay: -2s;
  transform: scale(2.4) rotate(358deg);
  filter: blur(4px);
}

@keyframes raise10 {
  to {
    bottom: 150vh;
    bottom: 150%;
    transform: scale(2.4) rotate(242deg);
    opacity: -.2;
  }
}

.c-article {
  display: block;
  text-align: left;
}

.c-article__image {
  overflow: hidden;
}

.c-article__image .o-media-wrapper {
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-article__image--resources {
  max-width: 150px;
}

.c-article__image--resources--big {
  max-width: 240px;
  margin-bottom: 40px;
}

.c-article--resources {
  padding-bottom: 32px;
}

.c-article--resources:hover .o-media-wrapper {
  transform: scale(1.15);
}

.c-article--resources:hover .caption__header {
  transform: translateX(10px);
}

.c-article--resources:hover .resources__content {
  transform: translateX(10px);
}

.caption__header {
  padding-top: 1rem;
  transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.caption__content {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.caption__content .u-b4 {
  margin-bottom: 0;
}

.caption__category {
  display: inline-block;
  margin-right: 0.5rem;
}

.caption__date {
  display: inline-block;
  margin-right: 0.5rem;
}

.c-article-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-article-list--2:after {
  content: "";
  width: 45.45455%;
  flex: auto;
}

.c-article-list--2 .c-article {
  width: 45.45455%;
}

@media (max-width: 1140px) {
  .c-article-list--2 .c-article {
    width: 48.57143%;
  }
}

@media (max-width: 480px) {
  .c-article-list--2 .c-article {
    width: 100%;
  }
}

.c-article-list--2 .caption__content {
  padding-top: 0.5rem;
  max-width: 420px;
}

.c-article-list--3:after {
  content: "";
  width: 31.42857%;
}

.c-article-list--3 .c-article {
  width: 31.42857%;
}

@media (max-width: 720px) {
  .c-article-list--3 .c-article {
    width: 100%;
  }
}

.c-article-list--3 .caption__content {
  padding-top: 1rem;
  max-width: 300px;
}

.c-article-list--3 .resources__content {
  padding-top: 1rem;
  max-width: 264px;
}

.c-article-list--3 .resources__content--e-books, .c-article-list--3 .resources__content--e-book {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-article-list--4:after {
  content: "";
  width: 22.85714%;
  flex: auto;
}

.c-article-list--4 .c-article {
  width: 22.85714%;
  margin-bottom: 2rem;
}

.c-article-list--4 .c-article:last-child {
  margin-left: 2.85714%;
}

.c-article-list--4 .caption__content {
  padding-top: 1rem;
  max-width: 240px;
}

.c-article-list--team {
  padding-top: 0;
  padding-bottom: 20vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, 31%);
  grid-gap: 30px;
  justify-content: space-between;
}

@media (min-width: 1441px) {
  .c-article-list--team {
    grid-template-columns: repeat(auto-fill, 21.5%);
  }
}

@media (max-width: 1140px) {
  .c-article-list--team {
    grid-template-columns: repeat(auto-fill, 30%);
    grid-gap: 15px;
  }
}

@media (max-width: 720px) {
  .c-article-list--team {
    grid-template-columns: repeat(auto-fill, 45%);
    grid-gap: 15px;
    padding-bottom: 3rem;
  }
}

@media (max-width: 375px) {
  .c-article-list--team {
    grid-template-columns: repeat(auto-fill, 100%);
    grid-gap: 0;
  }
}

.c-article-list--team .c-article {
  width: 100%;
  max-width: 320px;
  margin-bottom: 3rem;
}

@media (max-width: 720px) {
  .c-article-list--team .c-article {
    margin-bottom: 2rem;
  }
}

@media (max-width: 375px) {
  .c-article-list--team .c-article {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}

.c-article-list--blog-posts {
  background-color: #dbe2ec;
  position: relative;
}

.c-article--team {
  display: block;
}

.c-article--team.is-hidden {
  opacity: 0;
  visibility: hidden;
  display: none;
  border: 2px solid red;
}

.opensource {
  display: block;
  border-bottom: 1px solid #121a2d;
  margin-bottom: 32px;
}

.opensource div.u-b4 {
  margin-bottom: 0;
}

.opensource .c-editor-content p.p1 {
  margin-bottom: 0;
}

.opensource .c-article__caption .caption__content {
  max-width: none;
  padding-top: 0;
  padding-bottom: 0;
}

.opensource .c-article__caption h4.u-b0 {
  margin-bottom: 0;
}

.opensource .source-wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #121a2d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.opensource .source-wrapper .icon {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  font-size: 18px;
}

.opensource .c-article {
  width: auto;
}

.c-list {
  padding: 3rem 0;
}

.c-list__item {
  padding: 0.5rem 0;
}

.c-list-column__heading {
  padding: 0.65rem 0;
}

.c-list-item {
  padding: 0.65rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.c-list-item--no-border {
  border-bottom: 0;
}

.c-list--multicolumn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.c-list--bottom {
  align-items: flex-end;
}

.c-list--multicolumn-2 .c-list-column {
  width: 45.71429%;
}

.c-list--multicolumn-3 {
  justify-content: flex-start;
}

.c-list--multicolumn-3 .c-list-column {
  width: 33.33333%;
}

@media (max-width: 720px) {
  .is-mobile .c-list--multicolumn-3 .c-list-column {
    width: 100%;
  }
}

.c-list--multicolumn-3 .c-list-column:last-of-type {
  margin-right: 0;
}

.c-list--multicolumn-4 {
  justify-content: flex-start;
}

.c-list--multicolumn-4 .c-list-column {
  width: 50%;
  max-width: 150px;
  margin-right: 5.71429%;
}

@media (max-width: 1140px) {
  .c-list--multicolumn-4 .c-list-column {
    max-width: 132px;
  }
}

@media (max-width: 720px) {
  .is-mobile .c-list--multicolumn-4 .c-list-column {
    width: 100%;
    max-width: 100%;
  }
}

.c-list--multicolumn-4 .c-list-column:last-of-type {
  margin-right: 0;
}

.c-list--multicolumn-5 .c-list-column {
  width: 20%;
  padding-right: 2rem;
}

.c-underlined-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  max-width: 570px;
  padding-top: 2rem;
  margin-bottom: 0;
}

.c-underlined-list__item {
  width: 100%;
  padding: 0.65rem 0;
  border-bottom: 1px solid #e7e7e7;
}

.c-underlined-list--multicolumn .c-underlined-list__item {
  width: 47.36842%;
}

@media (max-width: 480px) {
  .c-underlined-list--multicolumn .c-underlined-list__item {
    width: 100%;
  }
}

.c-icon-list .u-a1 {
  margin-bottom: 0.5rem;
}

@media (max-width: 720px) {
  .c-icon-list .u-b2 {
    max-width: 320px;
  }
}

.c-icon-list__item {
  padding-right: 120px;
  position: relative;
  margin-bottom: 3rem;
}

@media (max-width: 720px) {
  .is-mobile .c-icon-list__item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 720px) {
  .is-mobile .c-icon-list__item:last-of-type {
    margin-bottom: 0;
  }
}

.c-icon-list__icon {
  position: absolute;
  left: 0;
  width: 60px;
  top: 0.5rem;
}

.c-icon-list__icon .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: #121a2d;
}

.c-section-heading {
  width: 100%;
  margin-bottom: 5rem;
}

@media (max-width: 720px) {
  .c-section-heading {
    margin-bottom: 3rem;
  }
}

.c-section-heading--narrow {
  max-width: 360px;
}

.c-section-heading--narrow.c-section-heading--fluid {
  max-width: 34.28571%;
}

.c-form-wrapper {
  position: relative;
  width: 100%;
}

input,
textarea,
select {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 0.5rem 0.65rem;
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-weight: 600;
}

@media (max-width: 480px) {
  input,
  textarea,
  select {
    line-height: 1.33333;
    font-size: 0.75rem;
  }
}

.in-message.in-message--danger,
.form-errors {
  position: absolute;
  color: #ff1a1a;
  line-height: 1.5;
  font-size: 0.6rem;
  bottom: 4px;
  right: 7px;
}

@media (max-width: 767px) {
  .in-message.in-message--danger,
  .form-errors {
    top: 23px;
  }
}

.c-form {
  width: 100%;
  max-width: 750px;
  position: relative;
}

.c-form.is-successful .c-form__thank-you {
  opacity: 1;
  visibility: visible;
}

.c-form__row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

@media (max-width: 720px) {
  .c-form__row {
    display: block;
  }
}

.c-form__row--intro, .c-form__row--message {
  padding: 40px 0;
}

.c-form__row--intro .u-b6, .c-form__row--message .u-b6 {
  max-width: 500px;
}

.c-form__row--2 .c-fieldset {
  width: 48%;
}

@media (max-width: 720px) {
  .c-form__row--2 .c-fieldset {
    width: 100%;
  }
}

.c-form__row--2 .c-fieldset .ss-main {
  max-width: 360px;
}

@media (max-width: 720px) {
  .c-form__row--2 .c-fieldset .ss-main {
    max-width: 480px;
  }
}

.c-form__row--submit {
  text-align: center;
}

.c-form__row--submit .c-button {
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-weight: 600;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .c-form__row--submit .c-button {
    line-height: 1.33333;
    font-size: 0.75rem;
  }
}

.c-form__row--submit .c-button--text {
  color: rgba(0, 0, 0, 0.4);
}

.c-form__row--submit .c-button--text:before {
  display: none;
}

.c-form__row--submit .c-button--text:hover {
  color: #121a2d;
}

.c-form__row--main {
  position: relative;
  z-index: 4;
}

.c-form__footer {
  text-align: center;
  padding-top: 12.5vh;
}

.c-form__footer .u-b6 {
  display: inline-block;
  margin: 0 auto;
  max-width: 600px;
}

.c-form__container {
  position: relative;
  height: auto;
  overflow: hidden;
}

.c-form__container.is-expanded {
  margin-bottom: 2rem;
}

.c-form__thank-you {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background-color: inherit;
  opacity: 0;
  visibility: hidden;
  z-index: 5;
}

.c-form__message {
  display: inline-block;
  width: 100%;
  max-width: 600px;
}

.c-form__message .u-a4 {
  margin-bottom: 0.4em;
}

.c-form__message i {
  line-height: 1.5;
  font-size: 1rem;
}

@media (max-width: 1140px) {
  .c-form__message i {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

@media (max-width: 720px) {
  .c-form__message i {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.c-form__message p, .c-form__message .u-b0 {
  margin-bottom: 0.6em;
}

.c-form__form--apply .ss-main .ss-content .ss-search {
  margin: 10px 0;
}

.c-form__form--apply .ss-main .ss-content .ss-search input {
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.c-form__form--apply .ss-main .ss-content .ss-search input:focus {
  box-shadow: none;
}

.c-form__form--apply .ss-search {
  display: flex;
  opacity: 1;
  visibility: visible;
}

.c-form__error-message {
  color: #ff1a1a;
  line-height: 1.5;
  font-size: 0.6rem;
  text-align: right;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  transform: translateY(-8px);
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity, vibility;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.c-form .ss-main {
  color: inherit;
}

.c-form .ss-main .ss-single-selected {
  height: auto;
  display: block;
  background-color: #ffffff;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
}

.c-form .ss-main .ss-single-selected .ss-arrow {
  position: absolute;
  height: 100%;
  right: 5px;
  top: 0;
  margin: 0;
  width: 17px;
}

.c-form .ss-main .ss-single-selected .ss-arrow span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8dGl0bGU+YXJyb3ctZG93bjwvdGl0bGU+CjxwYXRoIGQ9Ik0xNiAzMC4yYzAuMiAwIDAuMiAwIDAuNCAwczAuMi0wLjIgMC40LTAuMmw3LjItNy4yYzAuNi0wLjYgMC42LTEuMiAwLTEuNnMtMS4yLTAuNi0xLjYgMGwtNS4yIDV2LTIzLjJjMC0wLjQtMC4yLTAuNi0wLjQtMC44LTAuMi0wLjQtMC40LTAuNC0wLjgtMC40cy0wLjYgMC0wLjggMC4yYy0wLjIgMC4yLTAuNCAwLjYtMC40IDF2MjMuMmwtNS4yLTVjLTAuNi0wLjYtMS4yLTAuNi0xLjYgMHMtMC42IDEuMiAwIDEuNmw3LjIgNy4yYzAgMC4yIDAuMiAwLjIgMC40IDAuMiAwIDAgMC4yIDAgMC40IDB6Ij48L3BhdGg+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
}

.c-form .ss-main .ss-single-selected .ss-arrow span.arrow-down {
  transform: none;
}

.c-form .ss-main .ss-single-selected .ss-arrow span.arrow-up {
  transform: rotateX(-180deg);
}

.c-form .ss-main .ss-single-selected .placeholder {
  display: block;
}

.c-form .ss-main .placeholder .ss-disabled {
  color: inherit;
}

.c-form .ss-main .ss-content {
  background-color: #ffffff;
  border-radius: 4px;
}

.c-form .ss-main .ss-content .ss-list {
  max-height: 320px;
  padding-bottom: 20px;
}

.c-form .ss-main .ss-content .ss-list .ss-optgroup-label {
  padding: 4px 20px;
  background-color: rgba(0, 0, 0, 0.1);
  display: none;
}

.c-form .ss-main .ss-content .ss-list .ss-option {
  padding: 12px 20px;
}

.c-form .ss-main .ss-content .ss-list .ss-option.ss-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: #121a2d;
}

.c-form .ss-main .ss-content .ss-list .ss-option:hover {
  background-color: #121a2d;
  color: #ffffff;
}

.c-form--light input,
.c-form--light textarea,
.c-form--light select {
  background-color: #ffffff;
}

.c-form--light .c-label {
  color: #121a2d;
}

.c-form--light .c-label--light {
  color: #ffffff;
}

.c-form--light fieldset.is-filled label,
.c-form--light fieldset.is-filled .c-label,
.c-form--light .c-fieldset.is-filled label,
.c-form--light .c-fieldset.is-filled .c-label {
  color: #ffffff;
}

.c-form--light .c-checkbox__label {
  color: #ffffff;
}

.c-form--light .c-checkbox [type="checkbox"]:not(:checked) + label:before,
.c-form--light .c-checkbox [type="checkbox"]:checked + label:before {
  background-color: #ffffff;
}

.c-form--dark input,
.c-form--dark textarea,
.c-form--dark select {
  background-color: rgba(0, 0, 0, 0.1);
}

.c-form--dark input.is-invalid,
.c-form--dark textarea.is-invalid,
.c-form--dark select.is-invalid {
  background-color: rgba(255, 26, 26, 0.1);
}

.c-form--dark .c-label {
  color: #121a2d;
}

.c-form--dark .c-label--dark {
  color: #121a2d;
}

.c-form--dark fieldset.is-filled label,
.c-form--dark fieldset.is-filled .c-label,
.c-form--dark .c-fieldset.is-filled label,
.c-form--dark .c-fieldset.is-filled .c-label {
  color: #121a2d;
}

.c-form--dark .c-checkbox__label {
  color: #121a2d;
}

.c-form--dark .c-checkbox [type="checkbox"]:not(:checked) + label:before,
.c-form--dark .c-checkbox [type="checkbox"]:checked + label:before {
  background-color: rgba(0, 0, 0, 0.1);
}

.c-form--dark .c-button--secondary:before {
  background-color: #121a2d;
}

.c-form--dark .c-button--secondary:hover:before {
  background-color: #121a2d;
}

.c-form--dark .c-button--secondary:hover span {
  color: #ffffff;
}

.ss-list-end {
  display: block;
  width: 100%;
  height: 40px;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
}

/*.c-form--careers {
    //overrides
    .c-form__container {
        height: auto;
        margin-bottom: getSpacing('md');
    }

    .c-form__form {
        position: relative;
        visibility: visible;
        opacity: 1;
    }

    .c-form__footer {
        padding-top: getSpacing('md');
    }

    .c-file-upload {
        margin-top: -1px;

        input {
            display: none;
        }

        .uploaded-file {
            display: none;
        }
    }

    .ss-optgroup-label {
        display: block!important;
        text-transform: uppercase;
        @include font-map('b6');
    }
}*/
.c-label {
  position: absolute;
  display: inline-block;
  height: 100%;
  max-height: 32px;
  width: 100%;
  left: 15px;
  top: 10px;
  z-index: 1;
  text-align: left;
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-weight: 600;
  color: #e54360;
  transition: transform 0.2s ease-out, font-size 0.2s ease-out, color 0.2s ease-out;
  pointer-events: none;
}

@media (max-width: 480px) {
  .c-label {
    line-height: 1.33333;
    font-size: 0.75rem;
  }
}

.c-label--dark {
  color: #121a2d;
}

.c-label--light {
  color: #ffffff;
}

.c-label--dark {
  color: #121a2d;
}

.c-input {
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  position: relative;
}

.c-input.wpcf7-not-valid {
  border-bottom: 1px solid #ff1a1a;
}

select,
.c-select {
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  display: block;
  width: 100%;
  background-color: #ffffff;
  -webkit-appearance: none;
  border-radius: 4px;
  outline: none;
  padding: 0.5rem;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 480px) {
  select,
  .c-select {
    line-height: 1.33333;
    font-size: 0.75rem;
  }
}

.wpcf7-not-valid select, .wpcf7-not-valid
.c-select {
  border-bottom: 1px solid #ff1a1a;
}

.ss-main.c-select--disabled > * {
  cursor: not-allowed;
}

.ss-main.c-select--disabled .ss-single-selected .placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.ss-main.c-select--disabled .ss-single-selected .ss-arrow {
  opacity: 0.3;
}

.ss-main .ss-single-selected {
  max-width: calc(100% - 30px);
}

textarea,
.c-textarea {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  resize: none;
}

textarea.wpcf7-not-valid,
.c-textarea.wpcf7-not-valid {
  border-bottom: 1px solid #ff1a1a;
}

fieldset,
.c-fieldset {
  width: 100%;
  display: block;
  position: relative;
  padding-top: 1rem;
  min-width: 0;
  color: #ffffff;
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-weight: 600;
  /*&.is-focused {
        &:after {
            transform: scale3d(1,1,1);
            transform-origin: 0% 50%;
            transition-timing-function: ease;
        }
    }*/
}

@media (max-width: 480px) {
  fieldset,
  .c-fieldset {
    line-height: 1.33333;
    font-size: 0.75rem;
  }
}

fieldset:after,
.c-fieldset:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  will-change: transform, background-color;
  background-color: #121a2d;
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
  transition: transform 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

fieldset__field-wrapper,
.c-fieldset__field-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
}

fieldset.is-filled label,
fieldset.is-filled .c-label,
.c-fieldset.is-filled label,
.c-fieldset.is-filled .c-label {
  transform: translateY(-30px);
  font-size: .65rem;
  color: #ffffff;
}

fieldset.is-filled label--dark,
fieldset.is-filled .c-label--dark,
.c-fieldset.is-filled label--dark,
.c-fieldset.is-filled .c-label--dark {
  color: #121a2d;
}

fieldset.is-filled label--light,
fieldset.is-filled .c-label--light,
.c-fieldset.is-filled label--light,
.c-fieldset.is-filled .c-label--light {
  color: #ffffff;
}

fieldset.is-filled .c-checkbox .c-checkbox__label,
.c-fieldset.is-filled .c-checkbox .c-checkbox__label {
  transform: none;
}

fieldset.is-invalid .c-input,
fieldset.is-invalid .c-select,
fieldset.is-invalid .c-textarea,
.c-fieldset.is-invalid .c-input,
.c-fieldset.is-invalid .c-select,
.c-fieldset.is-invalid .c-textarea {
  border-bottom: 1px solid #ff1a1a;
}

fieldset.is-invalid .c-label,
.c-fieldset.is-invalid .c-label {
  color: #ff1a1a;
}

fieldset.is-invalid .c-form__error-message,
.c-fieldset.is-invalid .c-form__error-message {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

fieldset.is-invalid.is-focused .c-input,
fieldset.is-invalid.is-focused .c-select,
fieldset.is-invalid.is-focused .c-textarea,
.c-fieldset.is-invalid.is-focused .c-input,
.c-fieldset.is-invalid.is-focused .c-select,
.c-fieldset.is-invalid.is-focused .c-textarea {
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

fieldset.is-invalid.is-focused .c-label,
.c-fieldset.is-invalid.is-focused .c-label {
  color: #121a2d;
}

fieldset.is-invalid.is-focused .c-form__error-message,
.c-fieldset.is-invalid.is-focused .c-form__error-message {
  transform: translateY(-8px);
  opacity: 0;
  visibility: hidden;
}

fieldset.c-fieldset--checkbox,
.c-fieldset.c-fieldset--checkbox {
  padding-top: 0;
}

fieldset.c-fieldset--file-upload,
.c-fieldset.c-fieldset--file-upload {
  padding-top: 20px;
}

fieldset.c-fieldset--file-upload .cf7-drop-statusbar,
.c-fieldset.c-fieldset--file-upload .cf7-drop-statusbar {
  padding: 14px 20px 12px 20px;
}

fieldset.c-fieldset--file-upload .cf7-drop-remove,
.c-fieldset.c-fieldset--file-upload .cf7-drop-remove {
  max-width: 32px;
}

.c-fieldset--large input,
.c-fieldset--large textarea,
.c-fieldset--large select {
  line-height: 1.24324;
  font-size: 1.85rem;
  padding: 6px 0;
}

@media (max-width: 1140px) {
  .c-fieldset--large input,
  .c-fieldset--large textarea,
  .c-fieldset--large select {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-fieldset--large input,
  .c-fieldset--large textarea,
  .c-fieldset--large select {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-fieldset--large .ss-main {
  max-width: 100%;
  width: 100%;
}

.c-fieldset--large .c-label {
  line-height: 1.24324;
  font-size: 1.85rem;
  padding: 6px 0;
  top: 0;
}

@media (max-width: 1140px) {
  .c-fieldset--large .c-label {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-fieldset--large .c-label {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-fieldset--large .ss-main .placeholder {
  line-height: 1.5;
  font-size: 0.9rem;
}

@media (max-width: 1140px) {
  .c-fieldset--large .ss-main .placeholder {
    line-height: 1.5;
    font-size: 0.8rem;
  }
}

@media (max-width: 720px) {
  .c-fieldset--large .ss-main .placeholder {
    line-height: 1.5;
    font-size: 0.7rem;
  }
}

.c-fieldset--small {
  padding-top: 1rem;
}

.c-fieldset--small input,
.c-fieldset--small textarea,
.c-fieldset--small select {
  line-height: 1.5;
  font-size: 0.9rem;
  padding: 6px 0;
}

@media (max-width: 1140px) {
  .c-fieldset--small input,
  .c-fieldset--small textarea,
  .c-fieldset--small select {
    line-height: 1.5;
    font-size: 0.8rem;
  }
}

@media (max-width: 720px) {
  .c-fieldset--small input,
  .c-fieldset--small textarea,
  .c-fieldset--small select {
    line-height: 1.5;
    font-size: 0.7rem;
  }
}

.c-fieldset--small .ss-main {
  max-width: 100%;
  width: 100%;
}

.c-fieldset--small .c-label {
  line-height: 1.5;
  font-size: 0.9rem;
  padding: 6px 0;
  top: 0;
}

@media (max-width: 1140px) {
  .c-fieldset--small .c-label {
    line-height: 1.5;
    font-size: 0.8rem;
  }
}

@media (max-width: 720px) {
  .c-fieldset--small .c-label {
    line-height: 1.5;
    font-size: 0.7rem;
  }
}

.c-fieldset--small .ss-main .placeholder {
  line-height: 1.24324;
  font-size: 1.85rem;
}

@media (max-width: 1140px) {
  .c-fieldset--small .ss-main .placeholder {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-fieldset--small .ss-main .placeholder {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-checkbox {
  position: relative;
  text-align: left;
}

.c-checkbox__input {
  display: none;
}

.c-checkbox__label {
  display: inline-block;
  position: relative;
  max-width: 600px;
  color: #ffffff;
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-weight: 600;
  padding-left: 45px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .c-checkbox__label {
    line-height: 1.33333;
    font-size: 0.75rem;
  }
}

.c-checkbox [type="checkbox"]:not(:checked) + label:before,
.c-checkbox [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  border-radius: 4px;
}

.c-checkbox [type="checkbox"]:not(:checked) + label:after,
.c-checkbox [type="checkbox"]:checked + label:after {
  content: '';
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSI+CiAgICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS44NzMgMS4xMDdsLS45Ni0uOTY1QS40NDUuNDQ1IDAgMCAwIDEwLjU4OCAwYS40NDUuNDQ1IDAgMCAwLS4zMjQuMTQyTDQuNDg5IDUuOTQ4IDEuNzM2IDMuMTY2YS40Ny40NyAwIDAgMC0uMzI0LS4xMjguNDcuNDcgMCAwIDAtLjMyNS4xMjhsLS45Ni45NjVBLjQ3NS40NzUgMCAwIDAgMCA0LjQ1N2MwIC4xMjMuMDQyLjIzMi4xMjcuMzI3bDQuMDM4IDQuMDc0Yy4wODQuMDk1LjE5LjE0Mi4zMTcuMTQyYS40MDkuNDA5IDAgMCAwIC4zMTgtLjE0Mmw3LjA3My03LjA5OEEuNDg0LjQ4NCAwIDAgMCAxMiAxLjQyN2EuNDM1LjQzNSAwIDAgMC0uMTI3LS4zMnoiLz4KPC9zdmc+Cg==");
  position: absolute;
  top: 8px;
  left: 3px;
  width: 14px;
  height: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  font-family: Helvetica, Arial, sans-serif;
}

.c-checkbox [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.c-checkbox [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.c-file-upload__input {
  min-height: 58px;
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.c-file-upload__hidden-input {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.c-file-upload__message {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 30px 15px 30px;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  order: 99999;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  will-change: background-color, border;
  margin-top: 1px;
}

.c-file-upload__message:hover {
  border: 1px dashed #121a2d;
  background-color: rgba(0, 0, 0, 0.05);
}

.c-file-upload__message i {
  position: relative;
  font-style: normal;
  line-height: 1.5;
  font-size: 0.7rem;
  color: #000000 !important;
  display: block;
  text-align: center;
  font-weight: 700;
}

.wpcf7-form-control-wrap {
  width: 100%;
}

.wpcf7-not-valid-tip {
  position: absolute;
  top: 100%;
  margin-top: 8px;
  right: 0;
  text-align: right;
  width: 100%;
  font-size: 0.6rem !important;
  color: #ff1a1a;
}

.c-form .cf7-dragandrophandler-container {
  margin-top: 2rem;
}

.c-form .cf7-dragandrophandler {
  padding: 0;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  will-change: background-color, border;
  margin-top: 1px;
  height: auto;
}

.c-form .cf7-dragandrophandler:hover {
  border: 1px dashed #121a2d;
  background-color: rgba(0, 0, 0, 0.05);
}

.c-form .cf7-dragandrophandler-inner {
  padding: 20px 30px 15px 30px;
  position: relative;
  height: 60px;
}

.c-form .cf7-text-drop,
.c-form .cf7-drop-abort,
.c-form .cf7-text-or {
  display: none;
}

.c-form .cf7-text-browser {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-form .cf7-text-browser a {
  position: absolute;
  padding: 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 0;
  line-height: 1.5;
  font-size: 0.7rem;
  color: #000000 !important;
}

.c-form .cf7-drop-statusbar {
  padding: 20px;
  background-color: transparent;
  margin: 1px 0 0 0;
  border: none;
  position: relative;
  line-height: 1.5;
  font-size: 0.7rem;
  align-items: center;
}

.c-form .cf7-drop-statusbar.even {
  background-color: rgba(0, 0, 0, 0.05);
}

.c-form .cf7-drop-statusbar.odd {
  background-color: rgba(0, 0, 0, 0.05);
}

.c-form .cf7-drop-statusbar .cf7-drop-progressBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
  pointer-events: none;
}

.c-form .cf7-drop-statusbar .cf7-drop-progressBar.cf7-text-error {
  bottom: 4px;
  top: auto;
  height: auto;
  padding: 0 20px;
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-form .cf7-drop-statusbar .cf7-drop-progressBar > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

.c-form .cf7-drop-filename {
  vertical-align: middle;
}

.c-form .cf7-drop-filesize {
  color: #121a2d;
  flex: 2;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;
}

.c-form .cf7-drop-remove {
  width: 32px !important;
  flex: 1;
  text-align: right;
  color: transparent;
}

.c-form .cf7-drop-remove a {
  border-radius: 100px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjVweCIgdmlld0JveD0iMCAwIDUgNSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBza2V0Y2h0b29sIDQ5ICg1MTAwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+QTRENEFCRkMtMEQzNC00NEQxLUE2QTMtNDkzODU5QzNBQzI4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJDb250YWN0LWZvcm1GaW5hbC1zdGF0ZXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDIxLjAwMDAwMCwgLTIwNDUuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMDIxLjEyMDEyLDIwNDkuODc5ODggQzEwMjEuMjgzNzYsMjA1MC4wNDM1MiAxMDIxLjQ0Mzg0LDIwNTAuMDM5OTYgMTAyMS42MDAzNiwyMDQ5Ljg2OTIxIEwxMDIzLjUsMjA0Ny45OTA5MiBMMTAyNS4zOTk2NCwyMDQ5Ljg2OTIxIEMxMDI1LjU1NjE2LDIwNTAuMDM5OTYgMTAyNS43MTYyNCwyMDUwLjA0MzUyIDEwMjUuODc5ODgsMjA0OS44Nzk4OCBDMTAyNi4wNDM1MiwyMDQ5LjcxNjI0IDEwMjYuMDM5OTYsMjA0OS41NTYxNiAxMDI1Ljg2OTIxLDIwNDkuMzk5NjQgTDEwMjMuOTkwOTIsMjA0Ny41IEwxMDI1Ljg2OTIxLDIwNDUuNjAwMzYgQzEwMjYuMDM5OTYsMjA0NS40NDM4NCAxMDI2LjA0MzUyLDIwNDUuMjgzNzYgMTAyNS44Nzk4OCwyMDQ1LjEyMDEyIEMxMDI1LjcxNjI0LDIwNDQuOTU2NDggMTAyNS41NTYxNiwyMDQ0Ljk2MDA0IDEwMjUuMzk5NjQsMjA0NS4xMzA3OSBMMTAyMy41LDIwNDcuMDA5MDggTDEwMjEuNjAwMzYsMjA0NS4xMzA3OSBDMTAyMS40NDM4NCwyMDQ0Ljk2MDA0IDEwMjEuMjgzNzYsMjA0NC45NTY0OCAxMDIxLjEyMDEyLDIwNDUuMTIwMTIgQzEwMjAuOTU2NDgsMjA0NS4yODM3NiAxMDIwLjk2MDA0LDIwNDUuNDQzODQgMTAyMS4xMzA3OSwyMDQ1LjYwMDM2IEwxMDIzLjAwOTA4LDIwNDcuNSBMMTAyMS4xMzA3OSwyMDQ5LjM5OTY0IEMxMDIwLjk2MDA0LDIwNDkuNTU2MTYgMTAyMC45NTY0OCwyMDQ5LjcxNjI0IDEwMjEuMTIwMTIsMjA0OS44Nzk4OCBaIiBpZD0iUGFnZS0xIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
  background-size: 40%;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}

.c-fieldset__field-wrapper--currency .ss-main {
  z-index: 999;
}

.c-fieldset__field-wrapper--currency .ss-main .ss-content .ss-list {
  padding-top: 60px;
}

.c-fieldset__field-wrapper--currency .ss-optgroup {
  display: none;
}

.c-fieldset__field-wrapper--currency .c-select-currency {
  position: absolute;
  top: 100%;
  left: 1px;
  right: 1px;
  margin-top: -1px;
  z-index: 1000;
}

.c-select-currency {
  padding-left: 80px;
  height: 60px;
  position: relative;
  line-height: 1.5;
  font-size: 0.6rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-out, visibility 0.15s ease-out;
}

.c-select-currency.is-visible {
  opacity: 1;
  visibility: visible;
}

.c-select-currency__label {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  left: 0;
  padding-left: 20px;
}

.c-select-currency__list {
  display: inline-block;
  width: 100%;
  right: 0;
  white-space: nowrap;
  text-align: right;
  padding-right: 10px;
  position: absolute;
  top: 50%;
  margin-top: -19px;
}

.c-select-currency__list-item {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
}

.c-select-currency__list-item.is-active {
  color: #121a2d;
}

.c-form__loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 750px;
  height: 100%;
  z-index: 22;
  opacity: 0;
  visibility: hidden;
}

.c-form__loader-line {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 4px;
  background-color: #121a2d;
}

.c-form__loader-line-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  overflow: hidden;
}

.ss-search {
  display: none;
  opacity: 0;
  visibility: hidden;
  outline: none;
  border: none;
}

div.wpcf7 .ajax-loader {
  width: 0 !important;
  height: 0 !important;
  display: block !important;
  opacity: 0 !important;
  margin: 0 !important;
  visibility: hidden !important;
}

.screen-reader-response {
  display: none !important;
}

code {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !important;
}

.c-editor-content__opensource {
  color: #4b4b4b;
}

.c-editor-content__opensource .p1 {
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-editor-content--embed {
  padding-bottom: 4rem;
}

.c-editor-content--embed h2 {
  margin-bottom: 2em;
}

@media (max-width: 720px) {
  .c-editor-content--embed {
    padding-bottom: 4rem;
  }
}

.c-editor-content h3,
.c-editor-content h4,
.c-editor-content p,
.c-editor-content ul,
.c-editor-content ol,
.c-editor-content blockquote,
.c-editor-content pre,
.c-editor-content video,
.c-editor-content iframe,
.c-editor-content code,
.c-editor-content figure,
.c-editor-content .wp-block-image,
.c-editor-content img {
  display: block;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  width: 100%;
  height: auto;
  max-width: 930px;
  margin-top: 0;
}

.c-editor-content .c-column {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  width: 100%;
  height: auto;
  max-width: 930px;
  margin-top: 0;
}

.c-editor-content h3 {
  line-height: 1.24324;
  font-size: 1.85rem;
  margin-bottom: 0.8em;
}

@media (max-width: 1140px) {
  .c-editor-content h3 {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content h3 {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-editor-content h5 {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  margin-bottom: 1.4em;
}

.c-editor-content h4 {
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0.6em;
}

@media (max-width: 1140px) {
  .c-editor-content h4 {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

.c-editor-content h4 + h5 {
  margin-top: 2em;
}

.c-editor-content p {
  line-height: 1.5;
  font-size: 1rem;
  margin-bottom: 1.1em;
}

@media (max-width: 1140px) {
  .c-editor-content p {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content p {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.c-editor-content p + h4 {
  margin-top: 2em;
}

.c-editor-content p + h5 {
  margin-top: 2.4em;
}

.c-editor-content p + ul {
  margin-top: 1.4em;
  margin-bottom: 1.4em;
}

.c-editor-content p + ol {
  margin-top: 1.4em;
  margin-bottom: 1.4em;
}

.c-editor-content p + img {
  margin-top: 2.4em;
}

.c-editor-content p + .c-blog-image {
  margin-top: 3.4em;
}

@media (max-width: 720px) {
  .c-editor-content p + .c-blog-image {
    margin-top: 2.2em;
  }
}

.c-editor-content .o-media-wrapper img {
  top: -1px !important;
}

.c-editor-content .o-media-wrapper.o-media-wrapper--auto img {
  top: 0 !important;
}

.c-editor-content .c-quotes__item .u-a4 {
  margin-bottom: 0;
}

.c-editor-content .c-quotes__item .u-b5 {
  line-height: 1.5;
  font-size: 0.6rem;
  letter-spacing: 0.2px;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}

.c-editor-content .c-quotes__item .u-b6 {
  line-height: 1.5;
  font-size: 0.6rem;
  letter-spacing: 0.2px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-bottom: 0;
}

.c-editor-content .c-quotes__info {
  margin-top: 30px;
}

@media (max-width: 720px) {
  .c-editor-content .c-quotes__info {
    margin-left: 0;
    padding-left: 0;
  }
}

.c-editor-content blockquote {
  position: relative;
  margin-left: -30px;
  max-width: 1050px;
  line-height: 1.24324;
  font-size: 1.85rem;
  padding-left: 30px;
  margin-top: 1.2em;
  margin-bottom: 1.8em;
}

@media (max-width: 1140px) {
  .c-editor-content blockquote {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content blockquote {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-editor-content blockquote p {
  line-height: 1.24324;
  font-size: 1.85rem;
  max-width: 930px;
}

@media (max-width: 1140px) {
  .c-editor-content blockquote p {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content blockquote p {
    line-height: 1.35;
    font-size: 1rem;
  }
}

@media (max-width: 1140px) {
  .c-editor-content blockquote {
    margin-left: 0;
  }
}

.c-editor-content blockquote:before {
  line-height: 1.25;
  font-size: 2.35rem;
  display: inline-block;
  content: "“";
  position: absolute;
  top: 0;
  left: -14px;
}

@media (max-width: 1140px) {
  .c-editor-content blockquote:before {
    line-height: 1.25;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content blockquote:before {
    line-height: 1.25;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content blockquote:before {
    left: 0;
  }
}

.c-editor-content ul,
.c-editor-content ol {
  display: block;
  margin-top: 0.8em;
  margin-bottom: 0.4em;
  counter-reset: bf-counter;
  line-height: 1.5;
  font-size: 1rem;
}

@media (max-width: 1140px) {
  .c-editor-content ul,
  .c-editor-content ol {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content ul,
  .c-editor-content ol {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.c-editor-content ul li,
.c-editor-content ol li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 0.4em;
}

.c-editor-content ul li:before,
.c-editor-content ol li:before {
  line-height: 1.5;
  font-size: 0.7rem;
  content: counter(bf-counter) ".";
  counter-increment: bf-counter;
  position: absolute;
  top: 6px;
  left: 11px;
}

@media (max-width: 1140px) {
  .c-editor-content ul li:before,
  .c-editor-content ol li:before {
    top: 4px;
  }
}

@media (max-width: 720px) {
  .c-editor-content ul li:before,
  .c-editor-content ol li:before {
    top: 0;
  }
}

.c-editor-content ul li a:before,
.c-editor-content ol li a:before {
  bottom: -2px;
}

@media (max-width: 720px) {
  .c-editor-content ul li a:before,
  .c-editor-content ol li a:before {
    bottom: 0;
  }
}

.c-editor-content ul li p,
.c-editor-content ul li span,
.c-editor-content ol li p,
.c-editor-content ol li span {
  font-size: inherit;
  font-weight: inherit;
}

.c-editor-content ul li:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 13px;
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background-color: #121a2d;
}

@media (max-width: 1140px) {
  .c-editor-content ul li:before {
    top: 10px;
  }
}

@media (max-width: 720px) {
  .c-editor-content ul li:before {
    top: 8px;
  }
}

.c-editor-content ul li p,
.c-editor-content ul li span {
  font-size: inherit;
  font-weight: inherit;
}

.c-editor-content ul + h4 {
  margin-top: 2em;
}

.c-editor-content li {
  line-height: 1.5em;
}

.c-editor-content img {
  margin-bottom: 2em;
  vertical-align: top;
}

.c-editor-content img + p {
  margin-top: 2em;
}

.c-editor-content pre {
  margin-top: 2em;
  margin-bottom: 3.4em;
  display: block;
  line-height: 1.5;
  font-size: 0.7rem;
  padding: 2em;
  background-color: #4b4b4b;
}

.c-editor-content pre code {
  margin-left: 0;
  font-weight: bold;
}

.c-editor-content a {
  display: inline-block;
  position: relative;
}

.c-editor-content a:before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #121a2d;
  will-change: transform, background-color;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
  transition: transform 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-editor-content a span,
.c-editor-content a i {
  font-style: normal;
  color: inherit;
}

@media (min-width: 1140px) {
  .c-editor-content a:hover span {
    color: #121a2d;
  }
  .c-editor-content a:hover:before {
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;
    transition-timing-function: ease;
  }
}

.c-editor-content .c-blog-image,
.c-editor-content .o-media-wrapper {
  max-width: 100%;
}

.c-editor-content .c-blog-image img,
.c-editor-content .o-media-wrapper img {
  max-width: 100%;
  margin-left: 0;
  vertical-align: top;
}

.c-editor-content .c-blog-image p,
.c-editor-content .o-media-wrapper p {
  margin-left: 0;
}

.c-editor-content .c-blog-image {
  width: 100vw;
  max-width: 100vw;
  margin-left: -14.58333vw;
}

@media (max-width: 720px) {
  .c-editor-content .c-blog-image {
    margin-left: -20px;
  }
}

.c-editor-content .c-blog-image + p {
  margin-top: 1em;
}

.c-editor-content .c-blog-image + h4 {
  margin-top: 1.6em;
}

.c-editor-content .o-media-caption {
  display: block;
  color: #121a2d;
  margin-top: 0.5rem;
  max-width: 210px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 0.6rem;
  z-index: 2;
  width: 100vw;
  margin-left: 14.58333vw !important;
}

@media (max-width: 720px) {
  .c-editor-content .o-media-caption {
    margin-left: 20px !important;
  }
}

.c-editor-content .wp-caption {
  width: 100% !important;
  margin-bottom: 2em;
}

.c-editor-content .wp-caption + p {
  margin-top: 0.8em;
}

.c-editor-content .wp-caption + h4 {
  margin-top: 1.6em;
}

.c-editor-content .wp-caption img {
  margin: 0;
  width: 100%;
  height: auto;
  vertical-align: top;
}

.c-editor-content .wp-caption-text {
  margin-top: 0.5rem;
  width: 100%;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 0.6rem;
  margin-left: 0;
  padding-right: 360px;
}

@media (max-width: 1140px) {
  .c-editor-content .wp-caption-text {
    padding-right: 0;
    max-width: 210px;
  }
}

.c-editor-content--work {
  padding-top: 5vh;
}

@media (max-width: 720px) {
  .c-editor-content--work {
    padding-top: 1rem;
  }
}

@media (max-width: 480px) {
  .c-editor-content--work {
    padding-top: 0.5rem;
  }
}

.c-editor-content--work h3,
.c-editor-content--work h4,
.c-editor-content--work p,
.c-editor-content--work ul,
.c-editor-content--work ol,
.c-editor-content--work blockquote,
.c-editor-content--work pre,
.c-editor-content--work code,
.c-editor-content--work img {
  display: block;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-left: 0;
  width: 100%;
  height: auto;
  max-width: 930px;
  margin-top: 0;
}

.c-editor-content--work blockquote {
  position: relative;
  margin-left: -30px;
  max-width: 1050px;
  line-height: 1.24324;
  font-size: 1.85rem;
  padding-left: 30px;
  margin-top: 1.2em;
  margin-bottom: 1.8em;
}

@media (max-width: 1140px) {
  .c-editor-content--work blockquote {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--work blockquote {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-editor-content--work blockquote p {
  line-height: 1.24324;
  font-size: 1.85rem;
  max-width: 930px;
}

@media (max-width: 1140px) {
  .c-editor-content--work blockquote p {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--work blockquote p {
    line-height: 1.35;
    font-size: 1rem;
  }
}

@media (max-width: 1140px) {
  .c-editor-content--work blockquote {
    margin-left: 0;
  }
}

.c-editor-content--work blockquote:before {
  line-height: 1.25;
  font-size: 2.35rem;
  display: inline-block;
  content: "“";
  position: absolute;
  top: 0;
  left: -14px;
}

@media (max-width: 1140px) {
  .c-editor-content--work blockquote:before {
    line-height: 1.25;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--work blockquote:before {
    line-height: 1.25;
    font-size: 1.5rem;
  }
}

@media (max-width: 1140px) {
  .c-editor-content--work blockquote:before {
    left: 0;
  }
}

.c-editor-content--work .c-blog-image img,
.c-editor-content--work .o-media-wrapper img {
  max-width: 100vw;
  vertical-align: top;
}

.c-editor-content--work .c-blog-image {
  width: 100vw;
  max-width: 100vw;
  margin-left: -14.58333vw;
}

@media (max-width: 1140px) {
  .c-editor-content--work .c-blog-image {
    margin-left: -6.75%;
  }
}

@media (max-width: 720px) {
  .c-editor-content--work .c-blog-image {
    margin-left: -20px;
  }
}

.c-editor-content--work .o-media-caption {
  width: 100vw;
  margin-left: 14.58333vw !important;
}

@media (max-width: 1140px) {
  .c-editor-content--work .o-media-caption {
    margin-left: -6.75%;
  }
}

@media (max-width: 720px) {
  .c-editor-content--work .o-media-caption {
    margin-left: 20px !important;
  }
}

.c-editor-content--work .wp-caption {
  width: 100vw !important;
}

.c-editor-content--work .wp-caption-text {
  width: 100vw;
}

.c-editor-content--resources h3 {
  line-height: 1.33333;
  font-size: 1.2rem;
}

@media (max-width: 1140px) {
  .c-editor-content--resources h3 {
    line-height: 1.5;
    font-size: 1rem;
  }
}

.c-editor-content--resources h4 {
  line-height: 1.5;
  font-size: 1rem;
}

@media (max-width: 1140px) {
  .c-editor-content--resources h4 {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.c-editor-content--resources p {
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-editor-content--resources .c-quotes__item .u-b5 {
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-editor-content--resources .c-quotes__item .u-b6 {
  line-height: 1;
  font-size: 0.5rem;
}

.c-editor-content--resources blockquote {
  line-height: 1.2;
  font-size: 1.5rem;
}

@media (max-width: 1140px) {
  .c-editor-content--resources blockquote {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

.c-editor-content--resources blockquote p {
  line-height: 1.2;
  font-size: 1.5rem;
}

@media (max-width: 1140px) {
  .c-editor-content--resources blockquote p {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

.c-editor-content--resources blockquote:before {
  line-height: 1.2;
  font-size: 1.5rem;
}

@media (max-width: 1140px) {
  .c-editor-content--resources blockquote:before {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--resources blockquote:before {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

.c-editor-content--resources ul,
.c-editor-content--resources ol {
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-editor-content--resources ul li:before,
.c-editor-content--resources ol li:before {
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-editor-content--resources pre {
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-editor-content--resources .o-media-caption {
  line-height: 1;
  font-size: 0.5rem;
}

.c-editor-content--resources .wp-caption-text {
  line-height: 1;
  font-size: 0.5rem;
}

.c-editor-content--careers {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .c-editor-content--careers {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.c-editor-content--careers h3,
.c-editor-content--careers h4,
.c-editor-content--careers p,
.c-editor-content--careers ul,
.c-editor-content--careers ol,
.c-editor-content--careers blockquote,
.c-editor-content--careers pre,
.c-editor-content--careers code,
.c-editor-content--careers img {
  display: block;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-left: 0;
  width: 100%;
  height: auto;
  max-width: 930px;
  margin-top: 0;
}

@media (max-width: 1140px) {
  .c-editor-content--careers h3,
  .c-editor-content--careers h4,
  .c-editor-content--careers p,
  .c-editor-content--careers ul,
  .c-editor-content--careers ol,
  .c-editor-content--careers blockquote,
  .c-editor-content--careers pre,
  .c-editor-content--careers code,
  .c-editor-content--careers img {
    margin-left: 0;
    padding-left: 0;
  }
}

.c-editor-content--careers blockquote {
  position: relative;
  margin-left: -30px;
  max-width: 1050px;
  line-height: 1.24324;
  font-size: 1.85rem;
  padding-left: 30px;
  margin-top: 1.2em;
  margin-bottom: 1.8em;
}

@media (max-width: 1140px) {
  .c-editor-content--careers blockquote {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--careers blockquote {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-editor-content--careers blockquote p {
  line-height: 1.24324;
  font-size: 1.85rem;
  max-width: 930px;
}

@media (max-width: 1140px) {
  .c-editor-content--careers blockquote p {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--careers blockquote p {
    line-height: 1.35;
    font-size: 1rem;
  }
}

@media (max-width: 1140px) {
  .c-editor-content--careers blockquote {
    margin-left: 0;
  }
}

.c-editor-content--careers blockquote:before {
  line-height: 1.25;
  font-size: 2.35rem;
  display: inline-block;
  content: "“";
  position: absolute;
  top: 0;
  left: -14px;
}

@media (max-width: 1140px) {
  .c-editor-content--careers blockquote:before {
    line-height: 1.25;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--careers blockquote:before {
    line-height: 1.25;
    font-size: 1.5rem;
  }
}

@media (max-width: 1140px) {
  .c-editor-content--careers blockquote:before {
    left: 0;
  }
}

.c-editor-content--careers .c-editor-content--careers {
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
}

@media (max-width: 720px) {
  .c-editor-content--careers .c-editor-content--careers {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.c-editor-content--careers .c-editor-content--careers h3,
.c-editor-content--careers .c-editor-content--careers h4,
.c-editor-content--careers .c-editor-content--careers p,
.c-editor-content--careers .c-editor-content--careers ul,
.c-editor-content--careers .c-editor-content--careers ol,
.c-editor-content--careers .c-editor-content--careers blockquote,
.c-editor-content--careers .c-editor-content--careers pre,
.c-editor-content--careers .c-editor-content--careers code,
.c-editor-content--careers .c-editor-content--careers img {
  display: block;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-left: 0;
  width: 100%;
  height: auto;
  max-width: 930px;
  margin-top: 0;
}

@media (max-width: 1140px) {
  .c-editor-content--careers .c-editor-content--careers h3,
  .c-editor-content--careers .c-editor-content--careers h4,
  .c-editor-content--careers .c-editor-content--careers p,
  .c-editor-content--careers .c-editor-content--careers ul,
  .c-editor-content--careers .c-editor-content--careers ol,
  .c-editor-content--careers .c-editor-content--careers blockquote,
  .c-editor-content--careers .c-editor-content--careers pre,
  .c-editor-content--careers .c-editor-content--careers code,
  .c-editor-content--careers .c-editor-content--careers img {
    margin-left: 0;
    padding-left: 0;
  }
}

.c-editor-content--careers .c-editor-content--careers blockquote {
  position: relative;
  margin-left: -30px;
  max-width: 1050px;
  line-height: 1.24324;
  font-size: 1.85rem;
  padding-left: 30px;
  margin-top: 1.2em;
  margin-bottom: 1.8em;
}

@media (max-width: 1140px) {
  .c-editor-content--careers .c-editor-content--careers blockquote {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--careers .c-editor-content--careers blockquote {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.c-editor-content--careers .c-editor-content--careers blockquote p {
  line-height: 1.24324;
  font-size: 1.85rem;
  max-width: 930px;
}

@media (max-width: 1140px) {
  .c-editor-content--careers .c-editor-content--careers blockquote p {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--careers .c-editor-content--careers blockquote p {
    line-height: 1.35;
    font-size: 1rem;
  }
}

@media (max-width: 1140px) {
  .c-editor-content--careers .c-editor-content--careers blockquote {
    margin-left: 0;
  }
}

.c-editor-content--careers .c-editor-content--careers blockquote:before {
  line-height: 1.25;
  font-size: 2.35rem;
  display: inline-block;
  content: "“";
  position: absolute;
  top: 0;
  left: -14px;
}

@media (max-width: 1140px) {
  .c-editor-content--careers .c-editor-content--careers blockquote:before {
    line-height: 1.25;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .c-editor-content--careers .c-editor-content--careers blockquote:before {
    line-height: 1.25;
    font-size: 1.5rem;
  }
}

@media (max-width: 1140px) {
  .c-editor-content--careers .c-editor-content--careers blockquote:before {
    left: 0;
  }
}

.padding-bottom-10vh {
  padding-bottom: 10vh;
}

.c-what-we-do {
  /*&__item-number {
        position: absolute;
        display: inline-block;
        right: 100%;
        top: 25px;
        margin-right: 25px;

        @include mq(lg) {
            position: relative;
            right: auto;
            top: auto;
            margin-bottom: getSpacing('xs');
        }
    }*/
}

.c-what-we-do__list {
  padding-bottom: 5rem;
}

@media (max-width: 720px) {
  .c-what-we-do__list {
    padding-bottom: 2rem;
  }
}

.c-what-we-do__item {
  position: relative;
  padding: 1rem 0;
}

@media (max-width: 720px) {
  .c-what-we-do__item {
    padding-bottom: 0;
  }
}

.c-client-logos__list {
  display: flex;
  flex-wrap: wrap;
}

.c-client-logos__item {
  position: relative;
  margin-bottom: 3rem;
  width: 16.66667%;
}

.c-client-logos__item:nth-child(6n) {
  margin-right: 0;
}

@media (max-width: 1140px) {
  .c-client-logos__item {
    margin-bottom: 2rem;
    width: 20%;
  }
}

@media (max-width: 720px) {
  .c-client-logos__item {
    margin-bottom: 2rem;
    width: 25%;
  }
}

@media (max-width: 480px) {
  .c-client-logos__item {
    margin-bottom: 1rem;
    width: 33.33333%;
  }
  .c-client-logos__item:nth-child(3n) {
    margin-right: 0;
  }
}

.c-client-logos__item img {
  opacity: 0.85;
  max-width: 100%;
  width: 70%;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-client-logos__item:hover img {
  opacity: 1;
}

.c-what-write-about-us__list {
  margin-bottom: 1rem;
}

.c-what-write-about-us__item {
  position: relative;
  padding: 0 3rem 3rem 0;
  display: inline-block;
  width: 15.38462%;
}

@media (max-width: 720px) {
  .c-what-write-about-us__item {
    padding-bottom: 0;
  }
}

.c-what-write-about-us__item img {
  opacity: 0.85;
  max-width: 100%;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-what-write-about-us__item:hover img {
  opacity: 1;
}

.c-work__list {
  position: relative;
  z-index: 2;
}

.c-work__item {
  display: block;
}

.c-work__item a {
  display: block;
}

.c-work-item {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 2rem 0;
  color: #121a2d;
}

.c-work-item__number {
  position: absolute;
  display: inline-block;
  right: 100%;
  top: 25px;
  margin-right: 25px;
  pointer-events: none;
  transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform, opacity;
}

.c-work-item__title {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}

.c-work-item__title .u-a6 {
  font-weight: 500;
  mix-blend-mode: difference;
}

.c-work-item__category {
  display: inline-block;
  transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}

.c-work-item__category i {
  font-style: normal;
  display: inline-block;
  margin-right: 8px;
}

.c-work-item .c-tag {
  opacity: 0;
  max-width: 500px;
  transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.c-work-item h3.u-b0 {
  line-height: 1.3;
  font-size: .8rem;
  opacity: 0;
  max-width: 500px;
  transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 1140px) {
  .c-work-item:hover .c-tag {
    opacity: 1;
    transform: translateX(10px);
  }
  .c-work-item:hover h3.u-b0 {
    opacity: 1;
    transform: translateX(10px);
  }
  .c-work-item:hover .c-work-item__title {
    transform: translateX(10px);
  }
  .c-work-item:hover .c-work-item__number {
    opacity: 0;
    transform: translateX(20px);
  }
  .c-work-item:hover .c-work-item__category {
    transform: translateX(10px);
  }
}

.c-work-preview {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;
}

.c-work-preview__canvas {
  display: block;
  position: absolute;
  top: 0;
  right: 60px;
  width: 50%;
  height: 100%;
}

.c-work-preview__canvas .canvas {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  height: 0;
  padding-bottom: 66.66667%;
  overflow: hidden;
  border-radius: 4px;
}

.c-work-preview__canvas canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-work-preview__list {
  display: block;
  position: absolute;
  top: 0;
  right: 60px;
  width: 50%;
  height: 100%;
}

.c-work-preview__item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  height: 0;
  padding-bottom: 66.66667%;
  overflow: hidden;
}

.c-work-preview__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  visibility: hidden;
}

.c-work-transitions {
  position: fixed;
  top: 50%;
  right: 60px;
  height: 0;
  width: 0;
  z-index: 100;
}

.c-work-details {
  width: 100%;
  max-width: 780px;
}

.c-work-details__heading {
  padding-bottom: 3rem;
}

@media (max-width: 720px) {
  .c-work-details__heading {
    padding-bottom: 1rem;
  }
}

.c-work-details__list-wrapper {
  margin-bottom: 10vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, 210px);
  grid-gap: 30px;
  justify-content: space-between;
}

@media (max-width: 720px) {
  .c-work-details__list-wrapper {
    grid-gap: 15px;
    margin-bottom: 3rem;
  }
}

.c-work-details__list {
  width: 100%;
  max-width: 210px;
  margin-bottom: 2rem;
  padding-right: 30px;
}

@media (max-width: 720px) {
  .c-work-details__list {
    margin-bottom: 1rem;
  }
}

.c-work-details__list .c-list {
  padding: 0;
}

.c-work-details__list .c-list-item {
  padding-top: 0;
  padding-bottom: 4px;
  line-height: 1.5;
  font-size: 0.7rem;
  border: none;
}

.c-work-details__list .u-b4 {
  margin-bottom: 0.5rem;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
}

.c-work-single-visual {
  position: absolute;
  bottom: -50px;
  right: 0;
  width: 58.33333%;
}

@media (min-width: 900px) and (max-height: 800px) {
  .c-work-single-visual {
    width: 50%;
  }
}

@media (min-width: 900px) and (max-height: 700px) {
  .c-work-single-visual {
    width: 41.66667%;
  }
}

@media (min-width: 900px) and (max-height: 600px) {
  .c-work-single-visual {
    width: 33.33333%;
  }
}

@media (max-width: 720px) {
  .c-work-single-visual {
    width: 100vw;
    bottom: -30px;
    left: 0;
  }
}

.c-work-single-visual__image {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 65.625%;
  background-size: cover;
}

.c-featured-work {
  position: relative;
}

.c-featured-work__list {
  position: absolute;
  width: 100%;
  padding-right: 70%;
  z-index: 10;
}

.c-featured-work__list-item {
  height: 100vh;
  display: flex;
  align-items: center;
}

.c-featured-work .u-b1 {
  position: absolute;
  top: 3rem;
  left: 0;
}

.c-featured-work__content {
  position: relative;
  background-color: #ffffff;
  padding: 40px 40px 40px 0;
  border-radius: 2px;
}

.c-featured-work__content a {
  display: block;
}

@media (min-width: 1140px) {
  .c-featured-work__content a:hover .c-button__layer {
    transform: scale(1.5);
  }
}

.c-featured-work__content .c-featured-work__show-more {
  margin-top: 30vh;
}

.c-featured-work__content .c-featured-work__show-more a {
  display: inline-block;
}

.c-featured-work__content .u-b1 {
  margin-bottom: 20vh;
}

.c-featured-work__content .u-a5 {
  margin-bottom: 2.5vh;
}

.c-featured-work__content .u-a5.u-a5--fluid {
  font-size: 4.86111vw;
}

.c-featured-work__content .u-b6 {
  display: inline-block;
  margin-bottom: 1rem;
  transition: all 0.2s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform, opacity;
}

.c-featured-work__content .u-b4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}

.c-featured-work__preview {
  float: right;
  position: relative;
  overflow: hidden;
  width: 80%;
}

.c-featured-work__preview-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9;
}

.c-featured-work__preview-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: background-color;
}

.c-featured-work__preview-inner {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.c-featured-work__preview-image {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 44.44444%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.c-featured-work__preview-list {
  height: 100vh;
  font-size: 0;
  white-space: nowrap;
  z-index: 2;
  position: relative;
  will-change: transform;
}

.c-featured-work__preview-list--alt {
  height: 90vh;
  position: absolute;
  top: 5vh;
  left: 10%;
  width: 90%;
  z-index: 1;
}

.c-featured-work__preview-list--alt .c-featured-work__preview-list-item {
  height: 90vh;
}

.c-featured-work__preview-list-item {
  width: 200%;
  height: 100vh;
  display: inline-block;
  position: relative;
}

.c-featured-work__preview-list-item span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.u-lang--sk .c-featured-work__content .u-a7 {
  line-height: 1;
  font-size: 4.5rem;
}

@media (max-width: 1140px) {
  .u-lang--sk .c-featured-work__content .u-a7 {
    line-height: 1.03333;
    font-size: 3rem;
  }
}

@media (max-width: 720px) {
  .u-lang--sk .c-featured-work__content .u-a7 {
    line-height: 1.04444;
    font-size: 2.25rem;
  }
}

.c-mobile-featured-work {
  padding-top: 3rem;
}

.c-mobile-featured-work .u-b6 {
  display: block;
  margin-bottom: 0.5rem;
}

.c-mobile-featured-work .u-a7 {
  line-height: 1.14894;
  font-size: 2.35rem;
  margin-bottom: 1rem;
}

@media (max-width: 1140px) {
  .c-mobile-featured-work .u-a7 {
    line-height: 1.21053;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .c-mobile-featured-work .u-a7 {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

.c-mobile-featured-work .c-button--round {
  margin-top: 1rem;
}

.c-mobile-featured-work__visual {
  width: 100vw;
  margin-left: -20px;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 1rem;
  position: relative;
}

.c-mobile-featured-work__visual img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 200px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.c-mobile-featured-work__content {
  margin-bottom: 3rem;
}

.c-about {
  padding: 3rem 0;
}

.c-about__first .u-b0 {
  margin-bottom: 3rem;
}

.c-about__first .c-about__image {
  max-width: 75%;
}

@media (max-width: 720px) {
  .c-about__first .c-about__image {
    max-width: 100%;
  }
}

.c-about__second {
  display: flex;
  align-items: center;
  padding-top: 5rem;
}

@media (max-width: 480px) {
  .c-about__second {
    display: block;
  }
}

.c-about__second .u-a4 {
  padding-right: 11.1vw;
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .c-about__second .u-a4 {
    padding: 0 0 50px;
  }
}

.c-about__second .c-about__image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 31vw;
  flex: 0 0 31vw;
  max-width: 31vw;
}

@media (max-width: 480px) {
  .c-about__second .c-about__image {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    max-width: 100%;
  }
}

.c-about__third {
  padding-top: 5rem;
}

.c-about__fourth {
  display: flex;
  align-items: center;
  padding-top: 5rem;
}

@media (max-width: 480px) {
  .c-about__fourth {
    display: block;
  }
}

.c-about__fourth-content {
  padding-left: 11.1vw;
}

@media (max-width: 480px) {
  .c-about__fourth-content {
    padding-left: 0;
  }
}

.c-about__fourth-content .u-a4 {
  padding: 0 0 50px 0;
}

@media (max-width: 480px) {
  .c-about__fourth-content .u-a4 {
    padding: 50px 0 50px 0;
  }
}

.c-about__fourth .c-about__image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 31vw;
  flex: 0 0 31vw;
  max-width: 31vw;
}

@media (max-width: 480px) {
  .c-about__fourth .c-about__image {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    max-width: 100%;
  }
}

.c-about-quotes {
  overflow: hidden;
  position: relative;
  height: 400px;
  width: 100%;
}

.c-about-quotes__content {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
}

.c-about-quotes__quotes-wrapper {
  position: relative;
  height: 100%;
  width: 80%;
}

.c-about-quotes__single-quote-wrapper {
  position: absolute;
  display: none;
}

.c-about-quotes__quote-text {
  position: relative;
  font-weight: normal;
  color: #121a2d;
  width: 88.57143%;
  /*
    span {
      span {
        opacity: 0;
        transition: opacity 0.33s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        .char1 {
          position: absolute;
        }
      }

      &.show {
        span {
          opacity: 1;
          transition: opacity 0.33s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        }
      }

      &.accelerated {
        span {
          will-change: opacity;
        }
      }
    }
    */
}

@media (max-width: 1140px) {
  .c-about-quotes__quote-text.u-a4 {
    font-size: 1.45rem;
    line-height: 1.35;
  }
}

@media (max-width: 720px) {
  .c-about-quotes__quote-text.u-a4 {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}

@media (max-width: 480px) {
  .c-about-quotes__quote-text.u-a4 {
    font-size: 1.05rem;
  }
}

@media (max-width: 375px) {
  .c-about-quotes__quote-text.u-a4 {
    font-size: 0.95rem;
  }
}

.c-about-quotes__quote-text .row {
  position: relative;
}

.c-about-quotes__quote-author {
  line-height: 1.5;
  padding-top: 2rem;
}

.c-about-quotes__quote-author p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  margin: 0;
  color: #4b4b4b;
}

.c-about-quotes__nav-arrows-wrapper {
  position: absolute;
  display: flex;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
  width: 250px;
  height: 60px;
  transform: translateX(-20px);
}

@media (max-width: 1140px) {
  .c-about-quotes__nav-arrows-wrapper {
    height: 160px;
    width: 60px;
    transform: translateX(0);
  }
}

.c-about-quotes__nav-arrows-wrapper:hover .c-about-quotes__nav-lines-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}

.c-about-quotes__nav-arrows-wrapper:hover #arrow-left-line {
  transform: scale(0.7, 1);
  transition: transform 0.2s;
}

.c-about-quotes__nav-arrows-wrapper:hover #arrow-right-line {
  transform: scale(0.7, 1);
  transition: transform 0.2s;
}

.c-about-quotes__nav-arrow-hover-area-right {
  position: absolute;
  right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1140px) {
  .c-about-quotes__nav-arrow-hover-area-right {
    top: 0;
    bottom: auto;
    left: 0;
    transform: translateY(0);
  }
}

.c-about-quotes__nav-arrow-hover-area-right:hover .c-about-quotes__nav-arrow-right {
  opacity: 0.7;
  transition: opacity 0.2s;
}

.c-about-quotes__nav-arrow-hover-area-left {
  position: absolute;
  left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1140px) {
  .c-about-quotes__nav-arrow-hover-area-left {
    top: auto;
    bottom: 0;
    left: 0;
    transform: translateY(0);
  }
}

.c-about-quotes__nav-arrow-hover-area-left:hover .c-about-quotes__nav-arrow-left {
  opacity: 0.7;
  transition: opacity 0.2s;
}

.c-about-quotes__nav-lines-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s;
  width: 130px;
  height: 48px;
  top: 50%;
  left: 60px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 1140px) {
  .c-about-quotes__nav-lines-wrapper {
    display: none;
    opacity: 0;
  }
}

.c-about-quotes__nav-line {
  background-color: #121a2d;
  width: calc(130px / attr(data-count number, 5));
  height: 3px;
  margin-right: 5px;
  border-radius: 4px;
}

.c-about-quotes__nav-line:last-child {
  margin-right: 0;
}

.c-about-quotes__nav-line.active {
  opacity: 0.2;
}

.c-about-quotes__nav-line.next-active {
  opacity: 1;
  transition: opacity 0.2s;
}

.c-about-quotes__nav-line.next-active-arrow-hover {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.c-about-quotes__nav-line.prev-active {
  opacity: 1;
  transition: opacity 0.2s;
}

.c-about-quotes__nav-line.prev-active-arrow-hover {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.c-about-quotes__nav-arrow-left {
  overflow: hidden;
  transition: width 0.2s linear, opacity 0.2s;
}

.c-about-quotes__nav-arrow-left #arrow-left-line {
  transition: transform 0.2s;
}

.c-about-quotes__nav-arrow-left svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 1280px) {
  .c-about-quotes__nav-arrow-left svg {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 1152px) and (max-width: 1279px) {
  .c-about-quotes__nav-arrow-left svg {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 1024px) and (max-width: 1151px) {
  .c-about-quotes__nav-arrow-left svg {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-about-quotes__nav-arrow-left svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 767px) {
  .c-about-quotes__nav-arrow-left svg {
    width: 20px;
    height: 20px;
  }
}

.c-about-quotes__nav-arrow-left svg g,
.c-about-quotes__nav-arrow-left svg path,
.c-about-quotes__nav-arrow-left svg polyline {
  vector-effect: non-scaling-stroke;
  stroke: #121a2d;
  stroke-width: 3px;
}

.c-about-quotes__nav-arrow-left.area-hover #arrow-left-line {
  transform: scale(0.7, 1);
  transition: transform 0.2s;
}

.c-about-quotes__nav-arrow-right {
  overflow: hidden;
  direction: rtl;
  transition: width 0.2s linear, opacity 0.2s;
}

.c-about-quotes__nav-arrow-right #arrow-right-line {
  transition: transform 0.2s;
}

.c-about-quotes__nav-arrow-right svg {
  /*width: 100%;
      height: 100%;*/
}

@media (min-width: 1280px) {
  .c-about-quotes__nav-arrow-right svg {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 1152px) and (max-width: 1279px) {
  .c-about-quotes__nav-arrow-right svg {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 1024px) and (max-width: 1151px) {
  .c-about-quotes__nav-arrow-right svg {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-about-quotes__nav-arrow-right svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 767px) {
  .c-about-quotes__nav-arrow-right svg {
    width: 20px;
    height: 20px;
  }
}

.c-about-quotes__nav-arrow-right svg g,
.c-about-quotes__nav-arrow-right svg path,
.c-about-quotes__nav-arrow-right svg polyline {
  vector-effect: non-scaling-stroke;
  stroke: #121a2d;
  stroke-width: 3px;
}

.c-about-quotes__nav-arrow-right.area-hover #arrow-right-line {
  transform: scale(0.7, 1);
  transition: transform 0.2s;
}

.c-testimonials {
  position: relative;
}

.c-testimonials__inner {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 720px) {
  .c-testimonials__inner {
    flex-wrap: wrap;
  }
}

.c-testimonials__image {
  width: 40%;
}

@media (max-width: 720px) {
  .c-testimonials__image {
    width: 100%;
    order: 2;
    max-width: 280px;
    margin: 0 auto;
  }
}

.c-testimonials__text {
  height: 100%;
  width: 60%;
  padding-bottom: 12.5vh;
  margin-bottom: 3rem;
}

@media (max-width: 720px) {
  .c-testimonials__text {
    width: 100%;
    order: 1;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.c-testimonials__text .u-a2 {
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 1.2;
  font-size: 1.5rem;
}

@media (max-width: 1140px) {
  .c-testimonials__text .u-a2 {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

.c-testimonials__navigation {
  padding: 1rem;
}

.c-testimonials__navigation-inner {
  float: right;
  width: 60%;
}

@media (max-width: 720px) {
  .c-testimonials__navigation-inner {
    width: 100%;
    text-align: center;
  }
}

.c-testimonials__navigation-item {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 720px) {
  .c-testimonials__navigation-item {
    display: block;
  }
}

.c-testimonials__navigation-item.c-testimonials__navigation-item--arrow-left, .c-testimonials__navigation-item.c-testimonials__navigation-item--arrow-right {
  padding: 1rem;
  cursor: pointer;
}

.c-testimonials__navigation-item.c-testimonials__navigation-item--arrow-left .icon, .c-testimonials__navigation-item.c-testimonials__navigation-item--arrow-right .icon {
  font-size: 14px;
}

.c-testimonials__navigation-item.c-testimonials__navigation-item--arrow-left {
  margin-left: -1rem;
}

@media (max-width: 720px) {
  .c-testimonials__navigation-item.c-testimonials__navigation-item--arrow-left {
    margin-left: 0;
  }
}

.c-testimonials__navigation-item.c-testimonials__navigation-item--counter {
  line-height: 1.5;
  font-size: 0.6rem;
  letter-spacing: 0.2px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  padding: 0 0.5rem;
}

.c-full-width-image {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.is-mobile .c-full-width-image .o-media-wrapper--4-3 {
  padding-bottom: 78.1457%;
}

.c-footer {
  background-color: #090e17;
  background-color: #0b0f1b;
  position: relative;
  z-index: 2;
  height: 100vh;
  padding-top: 12.5vh;
  color: #ffffff;
}

.c-footer.is-morph-opened {
  z-index: 31;
}

@media (max-width: 720px) {
  .c-footer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20vh;
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) and (max-width: 1140px) {
  .c-footer {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

@media (max-width: 720px) and (max-width: 720px) {
  .c-footer {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

@media (max-width: 720px) {
  .c-footer .c-list {
    padding: 2rem 0;
  }
}

@media (max-width: 480px) {
  .c-footer {
    padding-top: 10vh;
  }
}

.c-footer__top {
  margin-bottom: 30vh;
}

@media (max-width: 480px) {
  .c-footer__top {
    margin-bottom: 12.5vh;
  }
}

@media (max-width: 480px) {
  .c-footer__middle {
    margin-bottom: 5vh;
  }
}

.c-footer__bottom .u-b4 {
  margin-bottom: 0;
}

.c-footer__contact .c-button.c-button--text {
  color: #ffffff;
}

.c-footer__contact .c-button.c-button--text:before {
  background-color: #ffffff;
}

.c-footer__address span {
  display: block;
  padding: 0.25rem 0;
}

.c-footer__privacy {
  width: 100%;
  text-align: right;
}

.c-footer__columns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.c-footer__columns--2 .c-footer__column {
  width: 50%;
}

.c-footer__columns--2 .c-footer__column--left {
  width: 47.36842%;
}

.c-footer__columns--2 .c-footer__column--right {
  width: 52.63158%;
}

.c-footer__columns--3 .c-footer__column {
  width: 33.33333%;
}

.c-footer__columns .c-footer__column--right {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.c-footer__columns .c-footer__column--right .c-footer__address,
.c-footer__columns .c-footer__column--right .c-footer__partner {
  width: 50%;
}

.c-footer__columns .c-footer__column--right .c-footer__partner {
  text-align: right;
}

.c-footer__columns .c-footer__column .c-list-item {
  padding: 0.25rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

@media (max-width: 720px) {
  .c-footer__columns .c-footer__column .c-list-item {
    padding: 0.1rem 0;
  }
}

@media (max-width: 960px) {
  .c-footer__columns {
    flex-flow: column;
    align-items: flex-start;
    justify-content: start;
  }
  .c-footer__columns .c-footer__column {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .c-footer__columns .c-footer__column {
    width: 100%;
    flex-wrap: wrap;
  }
  .c-footer__columns .c-footer__column--right .c-footer__menu {
    width: 50%;
  }
  .c-footer__columns .c-footer__column--right .c-footer__partner {
    float: none;
    text-align: left;
    width: 100%;
  }
}

.c-svg-hand {
  display: inline-flex;
  align-self: center;
  position: absolute;
  overflow: visible;
  opacity: 0;
  height: 90px;
}

@media (max-width: 1140px) {
  .c-svg-hand {
    height: 60px;
  }
}

@media (max-width: 480px) {
  .c-svg-hand {
    height: 40px;
  }
}

.c-svg-hand #hand_lines .right-big,
.c-svg-hand #hand_lines .left-big,
.c-svg-hand #hand_lines .left-small,
.c-svg-hand #hand_lines .right-small {
  opacity: 0;
}

.c-svg-hand #hand_lines .left-big {
  transform: translate(16px, 29px);
}

.c-svg-hand #hand_lines .right-small {
  transform: translate(-10px, 7px);
}

.c-svg-hand #hand_lines .left-small {
  transform: translate(22px, 12px);
}

.c-svg-hand #hand_lines .right-big {
  transform: translate(-41px, 7px);
}

.c-svg-hand.is-visible #hand {
  transition-delay: 0.5s;
  transform: rotate(0deg);
  transform-origin: 10% 90%;
  animation: hand-frames 867ms 0.5s linear forwards;
}

@keyframes hand-frames {
  0% {
    transform: rotate(0deg);
    transform-origin: 10% 90%;
  }
  25% {
    transform: rotate(11deg);
    transform-origin: 10% 90%;
  }
  50% {
    transform: rotate(-10deg);
    transform-origin: 10% 90%;
  }
  75% {
    transform: rotate(11deg);
    transform-origin: 10% 90%;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 10% 90%;
  }
}

.c-svg-hand.is-visible {
  opacity: 1;
  transition: opacity 300ms linear;
}

.c-svg-hand.is-visible .right-big,
.c-svg-hand.is-visible .left-big,
.c-svg-hand.is-visible .left-small,
.c-svg-hand.is-visible .right-small {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
  transition: opacity 1s linear, transform;
}

.c-svg-hand.is-visible .left-big {
  transition-delay: 780ms;
  transition-duration: 600ms;
}

.c-svg-hand.is-visible .right-big {
  transition-delay: 630ms;
  transition-duration: 600ms;
}

.c-svg-hand.is-visible .left-small {
  transition-delay: 1200ms;
  transition-duration: 600ms;
}

.c-svg-hand.is-visible .right-small {
  transition-delay: 1030ms;
  transition-duration: 600ms;
}

.c-svg-hand svg {
  width: 100%;
  height: 100%;
  overflow: visible;
  fill: #121a2d;
}

.u-lang--sk .c-svg-hand {
  height: 80px;
  width: 70px;
}

@media (max-width: 1400px) {
  .u-lang--sk .c-svg-hand {
    height: 70px;
    width: 60px;
  }
}

@media (max-width: 1140px) {
  .u-lang--sk .c-svg-hand {
    height: 60px;
    width: 50px;
  }
}

@media (max-width: 720px) {
  .u-lang--sk .c-svg-hand {
    height: 45px;
    width: 35px;
  }
}

@media (max-width: 480px) {
  .u-lang--sk .c-svg-hand {
    height: 40px;
    width: 30px;
  }
}

.c-culture__heading {
  padding-bottom: 5rem;
}

@media (max-width: 720px) {
  .c-culture__heading {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.c-culture__heading .u-a4 {
  max-width: 990px;
}

.c-culture__content {
  display: flex;
  align-items: center;
}

@media (max-width: 720px) {
  .c-culture__content {
    display: block;
  }
}

.c-culture__full {
  width: 100%;
}

.c-culture__left {
  width: 57.14286%;
  padding-right: 8.57143%;
}

@media (max-width: 720px) {
  .c-culture__left {
    width: 100%;
    padding-right: 0;
  }
}

.c-culture__left .u-b2 {
  max-width: 390px;
}

.c-culture__left .o-media-wrapper {
  margin-top: 20vh;
}

.c-culture__left .c-article-list {
  padding-top: 0;
}

.c-culture__left .c-article:nth-child(1), .c-culture__left .c-article:nth-child(2) {
  padding-bottom: 2rem;
}

.c-culture__left .c-article .c-list {
  padding: 1rem 0 0 0;
}

.c-culture__left .c-article .c-list-item {
  padding: 0.25rem 0;
}

.c-culture__right {
  width: 42.85714%;
}

@media (max-width: 720px) {
  .c-culture__right {
    width: 100%;
    margin-top: 3rem;
  }
}

.c-awards .u-a4 {
  margin-bottom: 0.5rem;
}

.c-awards .u-b0 {
  margin-bottom: 3rem;
}

.c-awards__list {
  display: grid;
  grid-gap: 76px 43px;
  grid-template-columns: repeat(auto-fit, minmax(calc(25% - 43px), auto));
}

@media (max-width: 1140px) {
  .c-awards__list {
    grid-gap: 40px 25px;
  }
}

@media (max-width: 720px) {
  .c-awards__list {
    grid-template-columns: repeat(auto-fit, minmax(calc(33% - 43px), auto));
  }
}

@media (max-width: 480px) {
  .c-awards__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    grid-template-columns: none;
    grid-gap: unset;
    -webkit-overflow-scrolling: touch;
    padding: 26px;
    margin-bottom: 20px;
  }
}

.c-awards__list li {
  -webkit-box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.13);
}

@media (max-width: 480px) {
  .c-awards__list li {
    width: 66.25vw;
    margin-right: 10.3125vw;
  }
}

.c-awards__list li img {
  max-width: 100%;
  height: auto;
  margin-bottom: 0;
  display: block;
}

.glide__slide,
.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.in-overlay {
  position: relative !important;
}

.in-overlay__in {
  position: fixed;
  bottom: 0px;
  right: 0px;
  top: 0px;
  left: 0px;
  z-index: 499;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}

.in-overlay__in--absolute {
  position: absolute;
}

.in-overlay__in--light {
  display: block;
  background: rgba(255, 255, 255, 0.5);
}

.in-overlay__spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 28px;
  min-height: 28px;
  margin-top: -14px;
  margin-left: -14px;
  line-height: 28px;
  text-align: center;
}

.in-overlay__spinner--absolute {
  position: absolute;
}

.in-overlay__spinner__icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  will-change: transform;
  opacity: .3;
  font-size: 28px;
  animation: spin 2s linear infinite;
  color: #121a2d;
}

.in-overlay__spinner__icon:before {
  display: block;
  line-height: 28px;
  height: 28px;
  width: 28px;
}

.in-overlay__spinner__message {
  display: block;
  margin-top: 10px;
}

.window-popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10px;
  max-height: calc( 100% - 2 * var($window-popup-space-around));
  left: 10px;
  padding: 25px 0;
  z-index: 10001;
  transform: scaleX(0);
  width: calc( 100% - 2 * var($window-popup-space-around));
  will-change: transform;
  background: #ffffff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.is-no-flex .window-popup, .is-safari .window-popup, .is-flex-popup-height-issue-detected .window-popup {
  display: block;
}

.is-no-flex .window-popup, .is-safari .window-popup, .is-flex-popup-height-issue-detected .window-popup {
  display: block;
}

.window-popup--active {
  transform: scaleX(1);
}

@media (min-width: 900px) {
  .window-popup--standard {
    position: fixed;
    width: 400px;
    left: 50%;
    margin-left: -200px;
  }
}

@media (min-width: 901px) {
  .window-popup--wide {
    width: calc( 100vw - 2 * var($window-popup-inner-padding));
    max-height: calc( 100% - 2 * var($window-popup-inner-padding) - 2 * var($window-popup-space-around-big));
    left: 20px;
    top: 20px;
  }
}

@media (max-width: 900px) {
  .window-popup--wide {
    position: fixed;
    width: 750px;
    left: 50%;
    margin-left: -375px;
  }
}

.window-popup__in {
  width: 100%;
  max-height: calc( 100vh - 2 * var($window-popup-inner-padding) - 2 * var($window-popup-space-around-big));
  flex: 1;
  padding: 0 25px;
  overflow-y: auto;
}

.window-popup__close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 10px;
  background: #ffffff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.window-popup__close:hover {
  text-decoration: none;
}

.window-popup__actions {
  padding: 12.5px 25px;
  width: 100%;
  text-align: center;
}

@media (max-width: 767px) {
  .window-popup__actions {
    padding-bottom: 0;
  }
}

.window-popup__actions--multiple-buttons {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .window-popup__actions--multiple-buttons {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.is-no-flex .window-popup__actions--multiple-buttons {
  display: block;
}

.window-popup__actions__btn {
  width: 100%;
}

@media (max-width: 767px) {
  .window-popup__actions__btn {
    width: auto;
  }
}

.window-popup__actions--multiple-buttons .window-popup__actions__btn {
  margin: 2px 0;
}

.window-popup__actions__btn i {
  position: relative;
  top: 0;
  font-size: 12px;
}

@media (max-width: 767px) {
  .window-popup__actions__btn--cancel {
    order: -1;
  }
}

.is-no-flex .window-popup__actions--multiple-buttons .window-popup__actions__btn--cancel {
  float: left;
}

.is-no-flex .window-popup__actions--multiple-buttons .window-popup__actions__btn--continue {
  float: right;
}

.window-popup__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  will-change: background;
}

.window-popup__overlay--active {
  background: rgba(255, 255, 255, 0.75);
}

.window-popup ul {
  margin: 5px 0;
  font-size: .75rem;
}

.window-popup ul li {
  font-weight: 700;
}

.window-popup ul li ul li {
  font-weight: 300;
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
}

.custom-select select {
  display: none;
  /*hide original SELECT element: */
}

.select-selected {
  color: #121a2d;
  background-color: #ffffff;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #121a2d;
  padding: 0.5rem 0.65rem;
  cursor: pointer;
}

.select-selected {
  border-radius: 4px;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: #ffffff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  color: #ffffff;
  background-color: #121a2d;
}

.c-form--light .select-items div,
.c-form--light .select-selected {
  color: #121a2d;
  background-color: #ffffff;
}

.c-form--light .select-items div:hover,
.c-form--light .select-items div.same-as-selected {
  color: #121a2d;
  background-color: rgba(0, 0, 0, 0.1);
}

.c-form--dark .select-items div,
.c-form--dark .select-selected {
  color: #121a2d;
  background-color: rgba(0, 0, 0, 0.1);
}

.c-form--dark .select-items div:hover,
.c-form--dark .select-items div.same-as-selected {
  color: #ffffff;
  background-color: #121a2d;
}

.c-cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 98;
  padding-left: 20px;
  padding-right: 20px;
}

.c-cookie-bar__inner {
  width: 100%;
  max-width: 950px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 10px 10px 10px 25px;
  background-color: #fafafa;
  color: #121a2d;
  border-radius: 4px 4px 0px 0px;
}

@media (max-width: 720px) {
  .c-cookie-bar__inner {
    flex-wrap: wrap;
    padding: 10px;
  }
}

.c-cookie-bar__inner--settings {
  background-color: #e7e7e7;
  border-radius: 0px 0px 0px 0px;
}

.c-cookie-bar__left {
  width: 100%;
  padding-right: 10px;
}

.c-cookie-bar__left p {
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .c-cookie-bar__left {
    padding-left: 0;
  }
}

.c-cookie-bar__right {
  flex-wrap: nowrap;
  display: flex;
}

@media (max-width: 720px) {
  .c-cookie-bar__right {
    padding-top: 10px;
  }
}

.c-cookie-bar__right .c-button {
  line-height: 1.5;
  font-size: 0.6rem;
}

.c-cookie-bar__right .c-button:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 960px) {
  .c-cookie-bar__right .c-button {
    line-height: 1;
    font-size: 0.5rem;
  }
}

@media (max-width: 480px) {
  .c-cookie-bar__right .c-button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.c-cookie-bar__right .c-button span {
  padding: 0;
  white-space: nowrap;
}

.c-cookie-bar__right .c-button.save-setting {
  display: none;
}

.c-cookie-bar__right .c-button.accept, .c-cookie-bar__right .c-button.toggle-setting {
  display: block;
}

.c-cookie-bar.is-open .c-button.save-setting {
  display: block;
}

.c-cookie-bar.is-open .c-button.accept, .c-cookie-bar.is-open .c-button.toggle-setting {
  display: none;
}

.c-cookie-bar__settings {
  max-height: 400px;
  overflow-y: auto;
}

.c-cookie-bar__settings__row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #121a2d;
}

.c-cookie-bar__settings__row .c-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5;
  font-size: 0.6rem;
}

@media (max-width: 960px) {
  .c-cookie-bar__settings__row .c-button {
    line-height: 1;
    font-size: 0.5rem;
  }
}

@media (max-width: 480px) {
  .c-cookie-bar__settings__row .c-button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.c-cookie-bar__settings__row .c-button.is-active {
  background-color: #346CF0;
  border-color: #346CF0;
}

body.cookie-settings .c-cookie-bar__settings {
  max-height: max-content;
}

.c-cookie-privacy-info .u-b1 {
  margin-bottom: 2rem;
}

.c-cookie-privacy-info__item strong {
  font-weight: 500;
}

.c-cookie-privacy-info__item .c-button--small {
  line-height: 1.5;
  font-size: 0.7rem;
}

.c-cookie-privacy-info .c-button--text:before {
  display: none;
}

.c-cookie-privacy-info .c-button--bordered:before {
  display: block;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

@media (min-width: 1140px) {
  .c-cookie-privacy-info .c-button--bordered:hover:before {
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;
    transition-timing-function: ease;
  }
}

.c-cookie-privacy-info.c-cookie-privacy-info--small .c-cookie-privacy-info__item .c-button--small {
  line-height: 1.5;
  font-size: 0.6rem;
}

@media (max-width: 720px) {
  .c-read-more {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.c-read-more__title {
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10px;
}

.c-read-more__subtitle.u-b0 {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}

.c-read-more__navigation {
  padding: 35px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.c-read-more__prev, .c-read-more__next {
  width: 66px;
  height: 66px;
  border: 1px solid rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.c-read-more__navigation-counter {
  display: inline-flex;
  justify-content: center;
  width: auto;
  align-items: center;
  padding: 16px;
}

.c-read-more__mask {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9;
}

.c-read-more__mask--gray {
  background-color: #fafafa;
}

.c-read-more__img {
  width: 100%;
  height: 0;
  padding-top: 66.66667%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
}

.c-read-more .swiper-slide {
  display: inline-block;
  padding: 0 15px;
}

@media (max-width: 480px) {
  .c-read-more .swiper-slide {
    padding: 0;
  }
}

.c-read-more .swiper-slide p {
  margin-top: 18px;
  margin-bottom: 0;
  max-width: 330px;
}

.c-modular-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 100vh;
  justify-content: flex-end;
  color: #ffffff;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-modular-header:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58);
}

@media (max-width: 720px) {
  .c-modular-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.c-modular-header__title {
  max-width: 650px;
  width: 100%;
  padding-bottom: 10px;
}

.c-modular-header__subtitle {
  max-width: 535px;
  width: 100%;
  padding-bottom: 15px;
}

.c-modular-header__scroll-indicator {
  position: absolute;
  left: 18.75%;
  bottom: 55px;
  width: 30px;
  height: 50px;
  border-radius: 100px;
  border: 1px solid #ffffff;
}

@media (max-width: 1140px) {
  .c-modular-header__scroll-indicator {
    left: auto;
    right: 4.16667%;
  }
}

.c-modular-header__scroll-indicator:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 1;
  border-radius: 50px;
  width: 5px;
  height: 9px;
  background-color: #ffffff;
  animation: scrollAnimation ease-out 1.5s infinite;
}

.c-modular-header .c-modular-btn {
  display: inline-block;
}

@keyframes scrollAnimation {
  from {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
}

.c-split-text-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
}

.c-split-text-block__title {
  padding-bottom: 10px;
}

.c-split-text-block__left {
  width: 44.44444%;
}

@media (max-width: 720px) {
  .c-split-text-block__left {
    width: 100%;
    max-width: 600px;
    padding-bottom: 10px;
  }
}

.c-split-text-block__right {
  width: 44.44444%;
}

@media (max-width: 720px) {
  .c-split-text-block__right {
    width: 100%;
    max-width: 600px;
  }
}

.c-split-text-block__right p {
  margin: 0;
  padding-bottom: 1rem;
}

.c-image-title-block {
  display: flex;
  flex-wrap: wrap;
}

.c-image-title-block__img {
  padding-top: 33.33333%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  width: 50%;
}

@media (max-width: 720px) {
  .c-image-title-block__img {
    width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .c-image-title-block__img {
    padding-top: 50%;
  }
}

.c-image-title-block__mask {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9;
}

.c-image-title-block__mask--gray {
  background-color: #fafafa;
}

.c-image-title-block__title-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

@media (max-width: 720px) {
  .c-image-title-block__title-wrapper {
    width: 100%;
    padding: 0 20px;
  }
}

.c-image-title-block--left {
  flex-direction: row-reverse;
}

@media (max-width: 720px) {
  .c-image-title-block--left {
    flex-direction: row;
  }
}

.c-image-title-block--left .c-image-title-block__title-wrapper {
  padding-left: 10%;
  padding-right: 0;
}

@media (max-width: 720px) {
  .c-image-title-block--left .c-image-title-block__title-wrapper {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.c-list-with-content {
  max-width: 740px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.c-list-with-content__inner {
  max-width: 660px;
  width: 100%;
}

.c-list-with-content__text-paragraph {
  padding-bottom: 1rem;
}

.c-list-with-content__text-paragraph a {
  text-decoration: underline;
}

.c-list-with-content__list-title {
  padding-bottom: 0.5rem;
}

.c-custom-video-wrapper {
  max-width: 870px;
  width: auto;
  margin: 0 auto;
  --plyr-color-main: #1e1e1e;
}

.c-custom-video-wrapper video {
  max-width: 100%;
}

.c-custom-video-wrapper .plyr__control--overlaid {
  border-radius: 0;
}

.c-morph {
  position: relative;
  display: inline-block;
  z-index: -1;
}

.c-morph.is-opening, .c-morph.is-opened {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 29;
}

.c-morph__transition {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ffffff;
  visibility: hidden;
  z-index: 30;
}

.c-morph__modal {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  z-index: 31;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.c-morph__modal-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

.c-morph__modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .c-morph__modal-content {
    padding: 3rem 1rem 1rem 1rem;
    overflow-y: scroll;
  }
}

.c-morph__modal .c-menu-trigger {
  position: absolute;
  top: 10px;
  right: 30px;
  left: auto;
  z-index: 32;
}

@media (max-width: 720px) {
  .c-morph__modal .c-menu-trigger {
    right: 10px;
  }
}

@media (max-width: 720px) {
  .c-morph__modal-content .c-form fieldset.choose-project--first,
  .c-morph__modal-content .c-form .c-fieldset.choose-project--first {
    padding-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .c-morph__modal-content .c-form .c-form__row {
    margin-bottom: 0;
  }
  .c-morph__modal-content .c-form fieldset,
  .c-morph__modal-content .c-form .c-fieldset {
    padding-top: 1rem;
  }
  .c-morph__modal-content .c-form fieldset.choose-project--first,
  .c-morph__modal-content .c-form .c-fieldset.choose-project--first {
    padding-bottom: 2rem;
  }
  .c-morph__modal-content .c-form .c-form__row--submit fieldset,
  .c-morph__modal-content .c-form .c-form__row--submit .c-fieldset {
    padding-top: 1rem;
  }
  .c-morph__modal-content .c-form .c-label {
    top: 7px;
  }
  .c-morph__modal-content .c-form fieldset.is-filled label,
  .c-morph__modal-content .c-form fieldset.is-filled .c-label,
  .c-morph__modal-content .c-form .c-fieldset.is-filled label,
  .c-morph__modal-content .c-form .c-fieldset.is-filled .c-label {
    transform: translateY(-30px);
  }
  .c-morph__modal-content .c-form .c-checkbox__label {
    padding-left: 35px;
  }
  .c-morph__modal-content .c-form [type="checkbox"]:not(:checked) + label:before,
  .c-morph__modal-content .c-form [type="checkbox"]:checked + label:before {
    top: 1px;
  }
  .c-morph__modal-content .c-form [type="checkbox"]:not(:checked) + label:after,
  .c-morph__modal-content .c-form [type="checkbox"]:checked + label:after {
    top: 4px;
  }
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper {
  touch-action: pan-y;
}

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
  touch-action: pan-x;
}

.ss-main {
  position: relative;
  display: inline-block;
  user-select: none;
  color: #666666;
  width: 100%;
}

.ss-main .ss-single-selected {
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 30px;
  padding: 6px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color .2s;
}

.ss-main .ss-single-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed;
}

.ss-main .ss-single-selected.ss-open-above {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ss-main .ss-single-selected.ss-open-below {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ss-main .ss-single-selected .placeholder {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  width: calc(100% - 30px);
  line-height: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ss-main .ss-single-selected .placeholder * {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}

.ss-main .ss-single-selected .placeholder .ss-disabled {
  color: #dedede;
}

.ss-main .ss-single-selected .ss-deselect {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px 0 6px;
  font-weight: bold;
}

.ss-main .ss-single-selected .ss-deselect.ss-hide {
  display: none;
}

.ss-main .ss-single-selected .ss-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px 0 6px;
}

.ss-main .ss-single-selected .ss-arrow span {
  border: solid #666666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform .2s, margin .2s;
}

.ss-main .ss-single-selected .ss-arrow span.arrow-up {
  transform: rotate(-135deg);
  margin: 3px 0 0 0;
}

.ss-main .ss-single-selected .ss-arrow span.arrow-down {
  transform: rotate(45deg);
  margin: -3px 0 0 0;
}

.ss-main .ss-multi-selected {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: 30px;
  width: 100%;
  padding: 0 0 0 3px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color .2s;
}

.ss-main .ss-multi-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed;
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-disabled {
  color: #666666;
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-value .ss-value-delete {
  cursor: not-allowed;
}

.ss-main .ss-multi-selected.ss-open-above {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ss-main .ss-multi-selected.ss-open-below {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ss-main .ss-multi-selected .ss-values {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1 1 100%;
  width: calc(100% - 30px);
}

.ss-main .ss-multi-selected .ss-values .ss-disabled {
  display: flex;
  padding: 4px 5px;
  margin: 2px 0px;
  line-height: 1em;
  align-items: center;
  width: 100%;
  color: #dedede;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.ss-main .ss-multi-selected .ss-values .ss-value {
  display: flex;
  user-select: none;
  align-items: center;
  font-size: 12px;
  padding: 3px 5px;
  margin: 3px 5px 3px 0px;
  color: #ffffff;
  background-color: #5897fb;
  border-radius: 4px;
  animation-name: scaleIn;
  animation-duration: .2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.ss-main .ss-multi-selected .ss-values .ss-value.ss-out {
  animation-name: scaleOut;
  animation-duration: .2s;
  animation-timing-function: ease-out;
}

.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete {
  margin: 0 0 0 5px;
  cursor: pointer;
}

.ss-main .ss-multi-selected .ss-add {
  display: flex;
  flex: 0 1 3px;
  margin: 9px 12px 0 5px;
}

.ss-main .ss-multi-selected .ss-add .ss-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #666666;
  position: relative;
  height: 10px;
  width: 2px;
  transition: transform .2s;
}

.ss-main .ss-multi-selected .ss-add .ss-plus:after {
  background: #666666;
  content: "";
  position: absolute;
  height: 2px;
  width: 10px;
  left: -4px;
  top: 4px;
}

.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross {
  transform: rotate(45deg);
}

.ss-content {
  position: absolute;
  width: 100%;
  margin: -1px 0 0 0;
  box-sizing: border-box;
  border: solid 1px #dcdee2;
  z-index: 1010;
  background-color: #ffffff;
  transform-origin: center top;
  transition: transform .2s, opacity .2s;
  opacity: 0;
  transform: scaleY(0);
}

.ss-content.ss-open {
  display: block;
  opacity: 1;
  transform: scaleY(1);
}

.ss-content .ss-search {
  display: flex;
  flex-direction: row;
  padding: 8px 8px 6px 8px;
}

.ss-content .ss-search.ss-hide {
  height: 0px;
  opacity: 0;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.ss-content .ss-search.ss-hide input {
  height: 0px;
  opacity: 0;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.ss-content .ss-search input {
  display: inline-flex;
  font-size: inherit;
  line-height: inherit;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0px;
  height: 30px;
  padding: 6px 8px;
  margin: 0;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  text-align: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: textfield;
}

.ss-content .ss-search input::placeholder {
  color: #8a8a8a;
  vertical-align: middle;
}

.ss-content .ss-search input:focus {
  box-shadow: 0 0 5px #5897fb;
}

.ss-content .ss-search .ss-addable {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  flex: 0 0 30px;
  height: 30px;
  margin: 0 0 0 8px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  box-sizing: border-box;
}

.ss-content .ss-addable {
  padding-top: 0px;
}

.ss-content .ss-list {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label {
  padding: 6px 10px 6px 10px;
  font-weight: bold;
}

.ss-content .ss-list .ss-optgroup .ss-option {
  padding: 6px 6px 6px 25px;
}

.ss-content .ss-list .ss-optgroup-label-selectable {
  cursor: pointer;
}

.ss-content .ss-list .ss-optgroup-label-selectable:hover {
  color: #ffffff;
  background-color: #5897fb;
}

.ss-content .ss-list .ss-option {
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  user-select: none;
}

.ss-content .ss-list .ss-option * {
  display: inline-block;
}

.ss-content .ss-list .ss-option:hover, .ss-content .ss-list .ss-option.ss-highlighted {
  color: #ffffff;
  background-color: #5897fb;
}

.ss-content .ss-list .ss-option.ss-disabled {
  cursor: not-allowed;
  color: #dedede;
  background-color: #ffffff;
}

.ss-content .ss-list .ss-option:not(.ss-disabled).ss-option-selected {
  color: #666666;
  background-color: rgba(88, 151, 251, 0.1);
}

.ss-content .ss-list .ss-option.ss-hide {
  display: none;
}

.ss-content .ss-list .ss-option .ss-search-highlight {
  background-color: #fffb8c;
}

.u-no-events {
  pointer-events: none;
}

.u-visually-hidden {
  display: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  pointer-events: none;
}

.u-clearfix {
  display: inline-block;
}

* html .u-clearfix {
  height: 1%;
}

.u-clearfix {
  display: block;
}

.u-clearfix:after {
  height: 0;
  clear: both;
  font-size: 0;
  content: "\A";
  display: block;
  visibility: hidden;
}

.u-a8,
.u-a7,
.u-a6,
.u-a5,
.u-a4,
.u-a3,
.u-a2,
.u-a1,
.u-b0,
.u-b1,
.u-b2,
.u-b3,
.u-b4,
.u-b5,
.u-b6,
.u-b7 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-a8 {
  line-height: 1;
  font-size: 7rem;
}

@media (max-width: 1140px) {
  .u-a8 {
    line-height: 1.05263;
    font-size: 4.75rem;
  }
}

.u-a8.u-a8--fluid {
  font-size: 9.72222vw;
}

@media (max-width: 1140px) {
  .u-a8.u-a8--fluid {
    line-height: 1;
    font-size: 7rem;
  }
}

@media (max-width: 1140px) and (max-width: 1140px) {
  .u-a8.u-a8--fluid {
    line-height: 1.05263;
    font-size: 4.75rem;
  }
}

.u-a7 {
  line-height: 1;
  font-size: 5.25rem;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
}

@media (max-width: 1140px) {
  .u-a7 {
    line-height: 1.05714;
    font-size: 3.5rem;
  }
}

@media (max-width: 480px) {
  .u-a7 {
    line-height: 1.18182;
    font-size: 1.65rem;
  }
}

.u-a7.u-a7--fluid {
  font-size: 7.63889vw;
}

@media (min-width: 1400px) {
  .u-a7.u-a7--fluid {
    letter-spacing: -0.13889vw;
  }
}

@media (max-width: 1140px) {
  .u-a7.u-a7--fluid {
    line-height: 1;
    font-size: 5.25rem;
    letter-spacing: -1px;
  }
}

@media (max-width: 1140px) and (max-width: 1140px) {
  .u-a7.u-a7--fluid {
    line-height: 1.05714;
    font-size: 3.5rem;
  }
}

@media (max-width: 1140px) and (max-width: 480px) {
  .u-a7.u-a7--fluid {
    line-height: 1.18182;
    font-size: 1.65rem;
  }
}

.u-a6 {
  line-height: 1.025;
  font-size: 4rem;
  letter-spacing: -1px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

@media (max-width: 1140px) {
  .u-a6 {
    line-height: 1;
    font-size: 2.7rem;
  }
}

@media (max-width: 720px) {
  .u-a6 {
    line-height: 1;
    font-size: 2rem;
  }
}

@media (max-width: 1140px) {
  .u-a6 {
    line-height: 1.025;
    font-size: 4rem;
  }
}

@media (max-width: 1140px) and (max-width: 1140px) {
  .u-a6 {
    line-height: 1;
    font-size: 2.7rem;
  }
}

@media (max-width: 1140px) and (max-width: 720px) {
  .u-a6 {
    line-height: 1;
    font-size: 2rem;
  }
}

@media (max-width: 720px) {
  .u-a6 {
    letter-spacing: -1.5px;
  }
}

.u-a6.u-a6--fluid {
  font-size: 5.55556vw;
}

@media (max-width: 1140px) {
  .u-a6.u-a6--fluid {
    line-height: 1.025;
    font-size: 4rem;
  }
}

@media (max-width: 1140px) and (max-width: 1140px) {
  .u-a6.u-a6--fluid {
    line-height: 1;
    font-size: 2.7rem;
  }
}

@media (max-width: 1140px) and (max-width: 720px) {
  .u-a6.u-a6--fluid {
    line-height: 1;
    font-size: 2rem;
  }
}

@media (max-width: 720px) {
  .u-a6.u-a6--fluid {
    letter-spacing: -1.5px;
  }
}

.u-a5 {
  line-height: 1.07692;
  font-size: 3.25rem;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
}

@media (max-width: 1140px) {
  .u-a5 {
    line-height: 1.04348;
    font-size: 2.3rem;
  }
}

@media (max-width: 720px) {
  .u-a5 {
    line-height: 1.13333;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .u-a5 {
    letter-spacing: -1px;
  }
}

.u-a5.u-a5--fluid {
  font-size: 5.55556vw;
}

@media (max-width: 1140px) {
  .u-a5.u-a5--fluid {
    line-height: 1.07692;
    font-size: 3.25rem;
  }
}

@media (max-width: 1140px) and (max-width: 1140px) {
  .u-a5.u-a5--fluid {
    line-height: 1.04348;
    font-size: 2.3rem;
  }
}

@media (max-width: 1140px) and (max-width: 720px) {
  .u-a5.u-a5--fluid {
    line-height: 1.13333;
    font-size: 1.5rem;
  }
}

.u-a4 {
  line-height: 1.25;
  font-size: 2.35rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

@media (max-width: 1140px) {
  .u-a4 {
    line-height: 1.25;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .u-a4 {
    line-height: 1.25;
    font-size: 1.5rem;
  }
}

.u-a3 {
  line-height: 1.24324;
  font-size: 1.85rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

@media (max-width: 1140px) {
  .u-a3 {
    line-height: 1.2;
    font-size: 1.5rem;
  }
}

@media (max-width: 720px) {
  .u-a3 {
    line-height: 1.35;
    font-size: 1rem;
  }
}

.u-a2 {
  line-height: 1.2;
  font-size: 1.5rem;
  letter-spacing: -1px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

@media (max-width: 1140px) {
  .u-a2 {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

@media (max-width: 720px) {
  .u-a2 {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

.u-a1 {
  line-height: 1.33333;
  font-size: 1.2rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-bottom: 0.8em;
}

@media (max-width: 1140px) {
  .u-a1 {
    line-height: 1.5;
    font-size: 1rem;
  }
}

.u-b0 {
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-bottom: 0.5rem;
}

@media (max-width: 1140px) {
  .u-b0 {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

@media (max-width: 720px) {
  .u-b0 {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.u-b0.u-b0--highlights {
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-bottom: 0.5rem;
}

.u-b1 {
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
}

@media (max-width: 1140px) {
  .u-b1 {
    line-height: 1.33333;
    font-size: 0.9rem;
  }
}

.u-b2 {
  line-height: 1.5;
  font-size: 0.9rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

@media (max-width: 1140px) {
  .u-b2 {
    line-height: 1.5;
    font-size: 0.8rem;
  }
}

@media (max-width: 720px) {
  .u-b2 {
    line-height: 1.5;
    font-size: 0.7rem;
  }
}

.u-b2--medium {
  font-weight: 600;
}

.u-b3 {
  line-height: 1.3125;
  font-size: 0.8rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

@media (max-width: 1140px) {
  .u-b3 {
    line-height: 1.42857;
    font-size: 0.7rem;
  }
}

.u-b4 {
  line-height: 1.5;
  font-size: 0.7rem;
  letter-spacing: 0.2px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  margin-bottom: 0.8em;
}

.u-b4.u-b4--bold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
}

.u-b5 {
  line-height: 1.5;
  font-size: 0.6rem;
  letter-spacing: 0.2px;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
}

.u-b5.u-b5--uppercase {
  text-transform: uppercase;
}

.u-b6 {
  line-height: 1.5;
  font-size: 0.6rem;
  letter-spacing: 0.2px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

.u-b7 {
  line-height: 1;
  font-size: 0.5rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

.u-b7--bold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-clip--left {
  -webkit-clip-path: polygon(0 0, 100% 3vw, 100% calc(100% - 3vw), 0 100%);
  clip-path: polygon(0 0, 100% 3vw, 100% calc(100% - 3vw), 0 100%);
}

.u-clip--right {
  -webkit-clip-path: polygon(0 3vw, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 3vw, 100% 0, 100% 100%, 0 100%);
}

.u-clip--bottom .u-clip--left {
  -webkit-clip-path: polygon(0 0, 100% 3vw, 100% calc(100% - 3vw), 0 100%);
  clip-path: polygon(0 0, 100% 3vw, 100% calc(100% - 3vw), 0 100%);
}

.u-clip--bottom .u-clip--right {
  -webkit-clip-path: polygon(0 3vw, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 3vw, 100% 0, 100% 100%, 0 100%);
}

.u-clip + section {
  margin-top: -3vw;
}

.u-text-align--left {
  text-align: left;
}

.u-text-align--right {
  text-align: right;
}

.u-text-align--center {
  text-align: center;
}

.home-show-more {
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.home-intro-heading {
  max-width: 900px;
  padding-bottom: 20vh;
}

@media (max-width: 720px) {
  .home-intro-heading {
    padding-bottom: 2rem;
  }
}

.home-intro-heading .u-b6 {
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.home-intro-heading .u-a4 {
  line-height: 1.14894;
  font-size: 2.35rem;
}

@media (max-width: 1140px) {
  .home-intro-heading .u-a4 {
    line-height: 1.21053;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .home-intro-heading .u-a4 {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

.home-intro {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 720px) {
  .home-intro {
    display: block;
  }
}

.home-intro__left {
  /*padding-right: percentage(1/17.5);
        width: percentage(10.5/17.5);

        @include mq(md) {
            width: 100%;
            padding-right: 0;
            margin-bottom: getSpacing('xl');
        }*/
  float: left;
  width: 48.57143%;
  padding-right: 11.42857%;
}

@media (max-width: 1140px) {
  .home-intro__left {
    width: 42.85714%;
    padding-right: 5.71429%;
  }
}

.home-intro__left .u-b2 {
  max-width: 360px;
}

.home-intro__left .c-button {
  margin-top: 1rem;
}

.home-intro__right {
  /*width: percentage(7/17.5);

        .o-media-wrapper__mask {
            background-color: getColor('white');
        }

        @include mq(md) {
            width: 100%;
        }*/
  float: right;
  width: 51.42857%;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1140px) {
  .home-intro__right {
    width: 57.14286%;
  }
}

.home-testimonials {
  position: relative;
  padding-bottom: 100px;
}

.home-testimonials .c-quotes__navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 180px;
}

.home-testimonials .c-quotes__navigation-inner {
  width: 100%;
  text-align: center;
}

.home-testimonials .c-quotes__navigation-item--arrow-left {
  position: absolute;
  left: 0;
  top: 0;
}

.home-testimonials .c-quotes__navigation-item--arrow-right {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -1rem;
}

@media (max-width: 720px) {
  .home-testimonials .c-quotes__navigation-item--arrow-right {
    margin-right: 0;
  }
}

.c-list-column {
  padding: 0.65rem 0;
}

.approach {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 1;
}

@media (max-width: 720px) {
  .approach {
    display: block;
  }
}

.approach__left {
  order: 1;
  width: 42.85714%;
  padding-right: 5.71429%;
}

@media (max-width: 720px) {
  .approach__left {
    width: 100%;
    padding-right: 0;
    margin-top: 1rem;
  }
}

.approach__left .u-a4 {
  max-width: 330px;
}

.approach__left .u-b2 {
  max-width: 330px;
  margin-top: 1rem;
}

.approach__left .c-button {
  margin-top: 2rem;
}

.approach__right {
  order: 2;
  width: 57.14286%;
}

@media (max-width: 720px) {
  .approach__right {
    width: 100%;
  }
}

.strategic-sessions {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

@media (max-width: 720px) {
  .strategic-sessions {
    display: block;
  }
}

.strategic-sessions__left {
  order: 2;
  width: 48.57143%;
  padding-left: 11.42857%;
  padding-right: 5.71429%;
}

@media (max-width: 1140px) {
  .strategic-sessions__left {
    padding-left: 5.71429%;
    padding-right: 0;
  }
}

@media (max-width: 720px) {
  .strategic-sessions__left {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.strategic-sessions__left .u-b2 {
  max-width: 330px;
  margin-top: 1rem;
}

.strategic-sessions__left .c-button {
  margin-top: 2rem;
}

.strategic-sessions__right {
  order: 1;
  width: 48.57143%;
  padding-right: 2.85714%;
}

@media (max-width: 720px) {
  .strategic-sessions__right {
    width: 100%;
    margin-bottom: 2rem;
    padding-right: 0;
  }
}

.strategic-sessions__right .o-media-wrapper--1-2 {
  padding-bottom: 176.47059%;
}

.work-single-heading {
  width: 100%;
  padding-right: 60%;
}

@media (max-width: 720px) {
  .work-single-heading {
    padding-right: 0%;
  }
}

.work-single-heading .u-b4 {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 1rem;
}

.work-single-heading .u-a2 {
  margin-top: 5vh;
}

.work-single-info {
  width: 100%;
  padding-right: 60%;
}

@media (max-width: 720px) {
  .work-single-info {
    margin-bottom: 2rem;
    padding-right: 0%;
  }
}

.work-single-info .c-list,
.work-single-info .u-b4 {
  margin-top: 30vh;
  padding-top: 1rem;
  padding-bottom: 0;
  position: relative;
  max-width: 270px;
}

@media (max-width: 720px) {
  .work-single-info .c-list,
  .work-single-info .u-b4 {
    margin-top: 3rem;
  }
}

@media (max-width: 720px) {
  .work-single-info .c-list {
    max-width: 100%;
  }
}

.work-single-info .c-tag {
  margin-top: 2rem;
}

.work-single-info .u-b4 {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
}

.work-single-info .c-list-item {
  line-height: 1.5;
  font-size: 0.7rem;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.c-list__line {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.3);
}

.work-links {
  padding: 5rem 0;
}

@media (max-width: 720px) {
  .work-links {
    padding: 0 0 2rem 0;
  }
}

.work-links .u-b4,
.work-links .u-a6 {
  color: rgba(255, 255, 255, 0.3);
}

.work-links .u-b4 {
  margin-bottom: 2rem;
}

.work-links .u-a6 {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

@media (max-width: 720px) {
  .work-links .u-a6 {
    color: #ffffff;
  }
}

.work-links__item {
  float: left;
  width: 50%;
  padding-right: 2rem;
}

@media (max-width: 480px) {
  .work-links__item {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.work-links__item a {
  display: block;
}

.work-links__item:hover .u-a6 {
  color: #ffffff;
}

.c-mobile-work__list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-mobile-work__item {
  width: 47.5%;
  margin-bottom: 2rem;
}

@media (max-width: 480px) {
  .c-mobile-work__item {
    width: 100%;
  }
}

.c-mobile-work__item a {
  display: block;
}

.c-mobile-work__item .c-work-item {
  display: block;
  padding-top: 0;
}

.c-mobile-work__item .u-a4 {
  line-height: 1.14894;
  font-size: 2.35rem;
}

@media (max-width: 1140px) {
  .c-mobile-work__item .u-a4 {
    line-height: 1.21053;
    font-size: 1.9rem;
  }
}

@media (max-width: 720px) {
  .c-mobile-work__item .u-a4 {
    line-height: 1.33333;
    font-size: 1.2rem;
  }
}

.c-mobile-work__item .c-work-item__image {
  width: 100%;
  margin-bottom: 1rem;
}

.intro-heading .u-b2 {
  margin-top: 1rem;
}

.c-contact {
  display: flex;
  align-items: start;
}

@media (max-width: 720px) {
  .c-contact {
    flex-flow: column;
  }
}

.c-contact__left {
  width: 66.66667%;
}

@media (max-width: 720px) {
  .c-contact__left {
    margin-bottom: 2rem;
    width: 100%;
  }
}

.c-contact__right {
  width: 33.33333%;
  padding-left: 4.16667vw;
}

@media (max-width: 720px) {
  .c-contact__right {
    width: 100%;
  }
}
