//ARTICLE
.c-article {
    display: block;
    text-align: left;

    &__caption {

    }

    &__image {
        overflow: hidden;

        .o-media-wrapper {
            transition: getTransition('slide-slow', 'ease-out-quad');
        }

        &--resources {
            max-width: 150px;
        }

        &--resources--big {
            max-width: 240px;
            margin-bottom: 40px;
        }

    }

    &--resources {
        padding-bottom: 32px;

        &:hover {
            .o-media-wrapper {
                transform: scale(1.15);
            }

            .caption__header {
                transform: translateX(10px);
            }

            .resources__content {
                transform: translateX(10px);
            }
        }
    }

}

//CAPTION
.caption {
    &__header {
        padding-top: getSpacing('sm');
        transition: getTransition('default-delay', 'ease-out-quad');
    }

    &__content {
        padding-top: getSpacing('xs');
        padding-bottom: getSpacing('sm');
        transition: getTransition('default', 'ease-out-quad');

        .u-b4 {
            margin-bottom: 0;
        }
    }

    &__category {
        display: inline-block;
        margin-right: getSpacing('xs');
    }

    &__date {
        display: inline-block;
        margin-right: getSpacing('xs');
    }
}

//ARTICLE LIST
.c-article-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq(md) {
    }
}

//MODIFIERS
.c-article-list--2 {

    &:after {
        content: "";
        width: percentage(7.5/16.5);
        flex: auto;
    }

    .c-article {
        width: percentage(7.5/16.5);

        @include mq(lg) {
            width: percentage(8.5/17.5);
        }

        @include mq(sm) {
            width: 100%;
        }
    }

    .caption__content {
        padding-top: getSpacing('xs');
        max-width: 7 * $settings-grid-column-width;
    }
}

.c-article-list--3 {

    &:after {
        content: "";
        width: percentage(5.5/17.5);
    }

    .c-article {
        width: percentage(5.5/17.5);

        @include mq(md) {
            width: 100%;
        }
    }

    .caption__content {
        padding-top: getSpacing('sm');
        max-width: 5 * $settings-grid-column-width;
    }

    .resources__content {
        padding-top: getSpacing('sm');
        max-width: 4.4 * $settings-grid-column-width;

        &--e-books,
        &--e-book {
            transition: getTransition('default', 'ease-out-quad');
        }
    }
}

.c-article-list--4 {

    &:after {
        content: "";
        width: percentage(4/17.5);
        flex: auto;
    }

    .c-article {
        width: percentage(4/17.5);
        margin-bottom: getSpacing('md');

        &:last-child {
            margin-left: percentage(0.5/17.5);
        }
    }

    .caption__content {
        padding-top: getSpacing('sm');
        max-width: 4 * $settings-grid-column-width;
    }
}

.c-article-list--team {
    padding-top: 0;
    padding-bottom: getSpacing('xxl', 'fluid');

    //
    display: grid;
    grid-template-columns: repeat(auto-fill, 31%);
    grid-gap: 30px;
    justify-content: space-between;

    @include mq($from: xl-A) {
        grid-template-columns: repeat(auto-fill, 21.5%);
    }

    @include mq(lg) {
        grid-template-columns: repeat(auto-fill, 30%);
        grid-gap: 15px;
    }

    @include mq(md) {
        grid-template-columns: repeat(auto-fill, 45%);
        grid-gap: 15px;
        padding-bottom: getSpacing('lg');
    }

    @include mq(xs) {
        grid-template-columns: repeat(auto-fill, 100%);
        grid-gap: 0;
    }

    .c-article {
        width: 100%;
        max-width: 320px;
        margin-bottom: getSpacing('lg');

        @include mq(md) {
            margin-bottom: getSpacing('md');
        }


        @include mq(xs) {
            max-width: 300px;
            margin: 0 auto;
            margin-bottom: getSpacing('md');
        }
    }
}

.c-article-list--blog-posts {
    background-color: rgb(219, 226, 236);
    position: relative;
}

//MODIFIERS
.c-article--team {
    display: block;

    &.is-hidden {
        opacity: 0;
        visibility: hidden;
        display: none;
        border: 2px solid red;
    }
}

//NO POINTER EVENTS - temporary
.c-article--team,
.c-article--careers,
.c-article--activities,
.c-article--services {
    //pointer-events: none;
}

.c-article--careers {

    &:hover {
        .caption__content {
            //transform: none;
        }
    }

    .c-button {
        //pointer-events: all;
    }
}

.opensource {
    display: block;
    border-bottom: 1px solid getColor('dark-blue');
    margin-bottom: 32px;

    div.u-b4 {
        margin-bottom: 0;
    }

    .c-editor-content {

        p.p1 {
            margin-bottom: 0;

        }
    }

    .c-article__caption {

        .caption__content {
            max-width: none;
            padding-top: 0;
            padding-bottom: 0;
        }

        h4.u-b0 {
            margin-bottom: 0;
        }
    }

    .source-wrapper {
        padding-top: 24px;
        padding-bottom: 24px;
        border-top: 1px solid getColor('dark-blue');
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
            display: inline-block;
            margin-right: 5px;
            vertical-align: top;
            font-size: 18px;
        }
    }

    .c-article {
        width: auto;
    }
}
