//SECTIONS
.o-section {
    //padding-left: $navigation-width;
    //padding-left: percentage(1.5/24);
    position: relative;
    //background-color: getColor('white');

    &__mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }

    @include mq(md) {
        padding-left: getSpacing('lr-fixed', 'mobile');
        padding-right: getSpacing('lr-fixed', 'mobile');
    }
}

//MODIFIERS
//common
.o-section--dark {
    background-color: getColor('dark-blue', 'sections');
    color: getColor('white');

    //overrides
    .c-button--bordered {

        &:before {
            background-color: getColor('white');
        }
    }
}

.o-section--cta {
    background-color: getColor('light-grey-A', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--small-cta {
    background-color: getColor('white');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--related-content {
    background-color: getColor('white');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }

    //hover overrides due to phase 2 similarities of article CTA's
    .c-article-list {
        .c-article {
            //hover
            @include mq(null, 'lg') {

                &:hover {

                    .o-media-wrapper {
                        transform: scale(1.15);
                    }

                    .caption__header {
                        transform: translateX(10px);
                    }

                    .caption__content {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
}

//HOME
.o-section--featured-work {
    color: getColor('dark-blue');
    background-color: getColor('white', 'sections');

    .o-container {
        padding-right: 0;
    }
}

@include mq(null, md) {
    //.is-desktop .o-section--featured-work--mobile {
    .o-section--featured-work--mobile,
    .o-section--featured-work--mobile.u-clearfix {
        display: none;
    }
}

@include mq(md, null) {
    //.is-mobile .o-section--featured-work--desktop {
    .o-section--featured-work--desktop,
    .o-section--featured-work--desktop.u-clearfix {
        display: none;
    }
}

.o-section--show-more {
    color: getColor('dark-blue');
    background-color: getColor('white', 'sections');
}

.o-section--services-clients {
    color: getColor('dark-blue');
    background-color: getColor('white', 'sections');

    &.u-clip {
        margin-top: calc(3vw - 1px);
        margin-bottom: -1px;
    }
}

.o-section--home-quote {
    background-color: getColor('white', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: 0;
        padding-bottom: getSpacing('lg');
    }

    //overrides
    .c-quotes {
        max-width: 16.5 * $settings-grid-column-width;
        float: left;
    }

    .u-a4 {
        @include font-map('a4--quote');
    }
}

.o-section--home-intro {
    background-color: getColor('white', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    .c-article {
        margin-top: getSpacing('lg');
    }

    .c-list--multicolumn-2 {
        .c-list-column {
            width: 45%;
        }
    }

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

//SERVICES PAGE
//services
.o-section--services {
    //background-color: getColor('pale-brown', 'sections');
    padding-bottom: getSpacing('lg', 'fluid');

    //overrides
    .u-b0 {
        @include font-map('b0--highlight');
    }

    .c-article-list--3 {
        padding-bottom: getSpacing('lg');

        @include mq(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .c-article {
        margin-bottom: getSpacing('md');

        @include mq(lg) {
            margin-bottom: 0;
        }

        .caption__content {
            max-width: 3.5 * $settings-grid-column-width;

            @include mq(md) {
                max-width: 300px;
            }
        }
    }

    //hover overrides due to phase 2 similarities of article CTA's
    .c-article-list {

        .c-article {

            @include mq(md) {
                margin-bottom: 20px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            .u-b0 {
                transition: getTransition('default-delay', 'ease-out-quad');
            }

            .u-b4 {
                transition: getTransition('default', 'ease-out-quad');
            }

            //hover
            @include mq(null, 'lg') {

                &:hover {

                    .o-media-wrapper {
                        transform: scale(1.15);
                    }

                    .u-b0 {
                        transform: translateX(10px);
                    }

                    .u-b4 {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
}

// dark services section
.o-section--services-dark {
    background-color: getColor('dark-blue');
    color: getColor('white');
    padding-top: getSpacing('lg', 'fluid');

    // overrides
    .c-article-list {
        padding-top: 0;
        padding-bottom: 3rem;
    }
}

//services quote
.o-section--services-quote {
    background-color: getColor('pale-blue-C', 'sections');

    //overrides
    .c-quotes {
        max-width: 16.5 * $settings-grid-column-width;
        float: left;
        margin-bottom: getSpacing('xl');
    }

    .c-testimonials__inner {
        align-items: center;
    }

    .c-testimonials__text {
        padding: getSpacing('md') 0;
        margin-bottom: 0;

        @include mq(md) {
            padding-bottom: 0;
        }
    }
}

//our expertise
.o-section--our-expertise {
    padding-top: getSpacing('xl-A', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(lg) {
        padding-bottom: getSpacing('xl-A', 'fluid');
    }

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: 0;
    }

    //overrides
    .u-b0 {
        @include font-map('b0--highlight');
    }

    .c-article {
        @include mq(md) {
            margin-bottom: getSpacing('md');
        }
    }
}

//languages & frameworks
.o-section--languages-frameworks {
    padding-top: getSpacing('xl-A', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }

    //overrides
    .c-section-heading {

        .u-a4 {
            max-width: 6 * $settings-grid-column-width;
            margin-bottom: getSpacing('xs');
        }

        .u-b2 {
            max-width: 9 * $settings-grid-column-width;
        }
    }

    .c-list {
        margin-bottom: 40px;

        @include mq(md) {
            margin-bottom: 0;
        }

        //GLOBAL STATE
        .is-mobile & {

            @include mq(md) {
                padding-top: getSpacing('md');
                padding-bottom: 0;
            }
        }
    }

    .c-list-column {

        //GLOBAL STATE
        .is-mobile & {
            @include mq(md) {
                margin-right: 0;
                margin-bottom: getSpacing('sm');
            }

            .c-expander__trigger {
                width: 100%;
            }
        }
    }
}

//approach
.o-section--approach {
    padding-top: getSpacing('xl-A', 'fluid');

    @include mq(lg) {
        padding-bottom: getSpacing('xl', 'fluid');
    }

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

//working with us
.o-section--working-with-us {
    margin-top: - getSpacing('lg', 'fluid');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(lg) {
        margin-top: 0;
        padding-top: getSpacing('xl', 'fluid');
    }

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }

    //overrides
    .c-list {
        padding-right: percentage(0.5/17.5);

        @include mq(md) {
            padding-top: 0;
        }

        //GLOBAL STATE
        .is-mobile & {
            @include mq(md) {
                padding-right: 0;
            }
        }
    }

    .c-list--multicolumn-2 {

        .c-list-column {
            width: percentage(7/16);

            //GLOBAL STATE
            .is-mobile & {
                @include mq(md) {
                    width: 100%;
                }
            }
        }
    }

    .c-article {
        margin-top: getSpacing('lg');

        //GLOBAL STATE
        .is-mobile & {

            @include mq(md) {
                margin-top: getSpacing('md');
            }
        }

        .u-a1 {
            margin-bottom: getSpacing('xs');

            //GLOBAL STATE
            .is-mobile & {

                @include mq(md) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .c-button--bordered {

        &:after {
            background-color: getColor('dark-grey', 'secondary');
        }
    }
}

//strategic sessions
.o-section--strategic-sessions {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
    //background-color: getColor('light-grey', 'sections');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--strategic-sessions-home {
    //overrides
    .strategic-sessions__left {

        @include mq(md) {
            margin-bottom: getSpacing('md');
        }
    }

    .strategic-sessions__right {

        .o-media-wrapper__mask {
            background-color: getColor('dark-grey', 'sections');
        }

        @include mq(md) {
            margin-bottom: 0;
        }
    }
}

.o-section--case-study-coming {
    background-color: getColor('white', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
}

//CULTURE
.o-section--culture {
    background-color: getColor('white', 'sections');
    //background-color: getColor('light-blue', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    .o-media-wrapper__mask {
        background-color: getColor('light-blue', 'sections');
    }

    &.u-clip {
        margin-top: calc(-3vw - 1px);
        margin-bottom: -1px;
    }
}

//activities
.o-section--activities {
    overflow: hidden;
    margin-top: getSpacing('xl', 'fluid');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(md) {
        margin-top: 0;
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('sm');
    }
}

//awards
.o-section--awards {
    //background-color: getColor('light-grey', 'sections');
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl-A', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('md');
        padding-bottom: getSpacing('lg');
    }
}

//OUR APPROACH PAGE
//our approach steps
.o-section--our-approach {
    background-color: getColor('white');
}

//BLOG
//blog list
.o-section--blog-list {
    background-color: getColor('pale-blue-A', 'sections');

    .o-container {
        position: relative;
    }

    .c-pagination__loader {
        width: auto;
        left: percentage(1.5/20);
        right: percentage(1/20);
        margin-left: -1px;
        margin-right: -1px;

        @include mq(lg) {
            left: percentage(1.5/22.5);
            right: percentage(1/22.5);
        }

        @include mq(md) {
            left: 0;
            right: 0;
        }
    }

    //overrides
    .c-article-list {
        padding-top: 0;
        //hover overrides due to phase 2 similarities of article CTA's
        .c-article {
            //hover
            @include mq(null, 'lg') {

                &:hover {

                    .o-media-wrapper {
                        transform: scale(1.15);
                    }

                    .caption__header {
                        transform: translateX(10px);
                    }

                    .caption__content {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }

    .c-article-list--2 {
        .caption__content {
            padding-bottom: getSpacing('md');
        }
    }
}

.o-section--blog-single-content {
    padding-top: getSpacing('md');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('sm');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--resources-single-content {
    padding-top: getSpacing('md');
    padding-bottom: getSpacing('md', 'fluid');
    display: flex !important;

    @include mq(lg) {
        flex-direction: column-reverse;
    }

    @include mq(md) {
        padding-top: getSpacing('sm');
        padding-bottom: getSpacing('lg');
    }

    .resources-single-container__image {

        @include mq(lg) {
            margin-left: percentage(1.5/($settings-grid-column-number - 1.5));
        }

        @include mq(md) {
            margin-left: 0;
        }
    }
}

.o-section--generic-single-content {
    padding-top: getSpacing('md');
    padding-bottom: getSpacing('md', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('sm');
        padding-bottom: getSpacing('md');
    }

    .is-protected & {
        .c-editor-content {

            form {
                p {
                    max-width: 480px;
                }
            }

            p {
                label {
                    display: inline-block;
                    position: relative;
                    max-width: 480px;
                    width: 100%;
                    color: getColor('dark-blue');
                    @include font-map('b6');

                    input[type="password"] {
                        border-bottom: 1px solid getColor('black-20', 'bw-transparent');
                        position: relative;
                        display: block;
                        transition: getTransition();

                        &:focus {
                            border-bottom: 1px solid getColor('dark-blue');
                        }
                    }
                }

                input[type="submit"] {
                    margin-top: 40px;
                    display: block;
                    width: 100%;
                    transition: getTransition();
                    position: relative;
                    border: none;
                    outline: none;
                    @include font-map('b3');
                    background-color: getColor('dark-blue');
                    color: getColor('white');
                    padding: getSpacing('sm');
                    cursor: pointer;

                    @include mq(null, 'lg') {

                        &:hover {
                            background-color: getColor('dark-grey', 'secondary');
                        }
                    }
                }
            }
        }

    }
}

//CLIENT LOGOS
.o-section--clients-logos {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');
    background-color: getColor('dark-blue');

    .c-section-heading {
        color: getColor('white');
    }
}

//ABOUT QUOTES
.o-section--about-quotes {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');
    background-color: getColor('dark-blue');

    .c-about-quotes {

        &__quote-text {
            color: getColor('white');
        }

        &__quote-author {
            p {
                color: getColor('white');
            }
        }

        &__nav-line {
            background-color: getColor('white');
        }

        &__nav-arrow-left {
            svg {
                g,
                path,
                polyline {
                    stroke: getColor('white');
                }
            }
        }

        &__nav-arrow-right {
            svg {
                g,
                path,
                polyline {
                    stroke: getColor('white');
                }
            }
        }
    }
}

//WHAT WRITE ABOUT US
.o-section--what-write-about-us {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
    background-color: getColor('dark-blue');

    .c-section-heading {
        color: getColor('white');
    }

    .o-media-wrapper__mask {
        background-color: getColor('dark-blue');
    }

    .c-article__caption {
        color: getColor('white');
    }
}

//WHAT WE DO
.o-section--what-we-do {
    padding-top: getSpacing('xl');

    .c-list-column {
        .c-article--services {
            max-width: 90%;
            margin-top: 0;

            .u-b0 {
                color: getColor('dark-grey');
            }
        }
    }
}

//AWARDS
.o-section--awards {
    background-color: getColor('light-blue');
}

//WORK
//work list
.o-section--work-list {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
    color: getColor('dark-blue');
    background-color: getColor('white', 'sections');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }

    .c-featured-work {
        &__list {
            &-item {
                height: auto;
                padding: getSpacing('md') 0;
                color: getColor('dark-blue');
            }
        }
    }
}

.is-mobile .o-section--work-list--desktop {
    display: none;
}

.is-desktop .o-section--work-list--mobile {
    display: none;
}

//work details
.o-section--work-details {
    background-color: getColor('white', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

//work links
.o-section--work-links {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

//work single
.o-section--work-single-content {
    background-color: getColor('white', 'sections');
    padding-top: getSpacing('xl');
    padding-bottom: getSpacing('xl');

    &.u-clip {
        margin-top: calc(3vw - 1px);
        margin-bottom: -1px;
    }
}

//CONTACT
//form
.o-section--contact {
    padding-top: getSpacing('lg', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');
}

//FULL WIDTH IMAGES
.o-section--full-width-image {
    padding-left: 0;
    padding-right: 0;
}

//CAREERS
//careers
.o-section--careers-intro {
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-bottom: getSpacing('lg');
    }
}

.o-section--careers-people {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--careers-selection-process {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--careers-testimonials {
    padding-top: getSpacing('lg');
    background-color: getColor('pale-blue-C', 'sections');
    position: relative;

    .o-section__mask {
        background-color: getColor('pale-blue', 'sections');
    }

    @include mq(md) {
        padding-top: getSpacing('lg');
    }
}

.o-section--careers-students {
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--careers-open-positions {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
    background-color: getColor('dark-grey', 'sections');
    color: getColor('white');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--careers-single-image {
    //overrides
    .o-media-wrapper {
        img {
            top: 0;
        }
    }
}

//careers single
.o-section--careers-form {
    padding-top: getSpacing('xxl', 'fluid');
    background-color: getColor('pale-green-A', 'sections');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }

    //overrides
    .u-a4 {
        padding-bottom: getSpacing('lg');
    }
}

// SINGLE SERVICE PAGE
//
.o-section--service-icon-list {
    padding-top: getSpacing('sm', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');
    color: getColor('white');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }

    //overrides
    .c-button {
        border: 1px solid getColor('white');
        color: getColor('dark-blue');
        background-color: getColor('white');
        margin-top: getSpacing('md', 'fluid');

        &:before {
            background-color: getColor('dark-blue');
        }

        &:hover {
            span {
                color: getColor('white');
            }
        }
    }
}

.o-section--service-project-list {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');
    color: getColor('white');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: 0;
    }

    //overrides
    h4 {
        padding-bottom: getSpacing('lg', 'fluid');

        @include mq(md) {
            padding-bottom: getSpacing('lg');
        }
    }
}

.o-section--service-cta {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
    color: getColor('dark-blue');
    background-color: getColor('white');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--service-partnership {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');
    color: getColor('white');
    background-color: getColor('dark-blue');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--service-quotes {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
    color: getColor('white');
    background-color: getColor('dark-blue');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }
}

.o-section--blog-single-newsletter {
    padding-right: percentage(1/24);
}

.o-section--resources-single-newsletter {
    padding-right: percentage(1/24);
    padding-bottom: 10vh;
    padding-left: 0;

    @include mq(md) {
        padding-right: 0;
    }

    &-white {
        background-color: getColor('white');
    }
}


// MODULAR LANDING [GUTENBERG BLOCKS]
.o-section-modular--gray {
    background-color: getColor('white-two', 'sections');
}

.o-section-modular--client-quotes {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--small-cta {
    padding-top: getSpacing('lg', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--cards-grid {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('md', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--awwwards {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--companies {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--statistic-numbers {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl-A', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--skills {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--read-more {
    padding-top: getSpacing('lg', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--development-process {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--accordion-block {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--content-slider {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--list-with-content {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--rte-block {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--image-title-block {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}

.o-section-modular--split-text-block {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    @include mq(md){
        padding-top: getSpacing('lg', 'fixed');
        padding-bottom: getSpacing('lg', 'fixed');
    }
}


