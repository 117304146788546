@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');

$default-font: 'Kanit', sans-serif;
$heading-font: 'Kanit', sans-serif;

$settings-fonts: (
    'default': $default-font,
    'heading': $heading-font,
);

$settings-font-weight: (
    'default': 300,
    'thin': 100,
    'extra-light': 200,
    'light': 300,
    'regular': 400,
    'medium': 500,
    'semibold': 600,
    'bold': 700,
    'black': 800,
    'ultra-black': 900
);
