.c-hide-on-scroll-down {
  z-index: getZindex('navbar');
  transition: getTransition('slide', 'ease-out-quad');

  &.is-hidden {
    top: -100%;
    position: fixed;
  }

  &.is-visible {
    top: 0;
    position: fixed;
  }
}