.c-split-text-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;

    &__title {
        padding-bottom: 10px;
    }

    &__left {
        width: percentage(8/18);

        @include mq(md){
            width: 100%;
            max-width: 600px;
            padding-bottom: 10px;
        }
    }

    &__right {
        width: percentage(8/18);

        @include mq(md){
            width: 100%;
            max-width: 600px;
        }

        p {
            margin: 0;
            padding-bottom: getSpacing('sm');
        }
    }
}
