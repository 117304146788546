//CONTAINER
$wrapper-max-columns: $settings-grid-column-number - 1.5;
$container-max-columns: $settings-grid-column-number - 3.5;
$container-max-columns-lg: $settings-grid-column-number - 2.5;

.o-container {
    float: right;
    width: 100%;
    max-width: percentage($container-max-columns/$wrapper-max-columns);
    padding-left: percentage(1.3/$wrapper-max-columns);
    padding-right: percentage(2/$wrapper-max-columns);

    @include mq(lg) {
        max-width: percentage($container-max-columns-lg/$wrapper-max-columns);
    }

    @include mq(md) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    &--centered {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
}
