$content-grid-column-number: 15.5; // 13;
$blockquote-grid-column-number: 17.5; // 13;
$blockquote-p-grid-column-number: 15.5; // 13;

//WP CONTENT EDITOR
//pre {
//    word-break: break-all;
//    word-wrap: break-word;
//    white-space: pre-wrap;
//    overflow-x: auto;
//    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console",
//    "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
//    "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !important;
//}
//
code {
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !important;
}

.c-editor-content {

    //EDITOR HTML ELEMENT SPECIFIC STYLE
    //h1, h2, h6 should be disabled

    &__opensource {
        color: getColor('dark-grey', 'secondary');

        .p1 {
            @include font-map('b6');
        }
    }

    &--embed {
        padding-bottom: rem(80px);

        h2 {
            margin-bottom: 2em;
        }

        @include mq(md) {
            padding-bottom: rem(80px);
        }
    }

    h3,
    h4,
    p,
    ul,
    ol,
    blockquote,
    pre,
    video,
    iframe,
    code,
    figure,
    .wp-block-image,
    img {
        display: block;
        @include font-default();
        //float: left;
        //margin-left: percentage(4.5/17.5);
        width: 100%;
        height: auto;
        max-width: $content-grid-column-number * $settings-grid-column-width;
        margin-top: 0;
    }

    .c-column {
        @include font-default();
        //float: left;
        //margin-left: percentage(4.5/17.5);
        width: 100%;
        height: auto;
        max-width: $content-grid-column-number * $settings-grid-column-width;
        margin-top: 0;
    }

    h3 {
        @include font-map('a3');
        margin-bottom: 0.8em;
    }

    h5 {
        @include font-heading();
        margin-bottom: 1.4em;
    }

    h4 {
        @include font-map('a2--quote');
        margin-bottom: 0.6em;

        + h5 {
            margin-top: 2em;
        }
    }

    p {
        @include font-map('b0');
        margin-bottom: 1.1em;

        @include mq(lg) {

        }

        + h4 {
            margin-top: 2em;
        }

        + h5 {
            margin-top: 2.4em;
        }

        + ul {
            margin-top: 1.4em;
            margin-bottom: 1.4em;
        }

        + ol {
            margin-top: 1.4em;
            margin-bottom: 1.4em;
        }

        + img {
            margin-top: 2.4em;
        }

        + .c-blog-image {
            margin-top: 3.4em;

            @include mq(md) {

                margin-top: 2.2em;
            }
        }
    }

    //overrides
    .o-media-wrapper {
        img {
            top: -1px !important;
        }

        &.o-media-wrapper--auto {
            img {
                top: 0 !important;
            }
        }
    }

    .c-quotes__item {
        .u-a4 {
            margin-bottom: 0;
        }

        .u-b5 {
            @include font-map('b5');
            letter-spacing: 0.2px;
            @include font-heading(); //bold
            margin-bottom: 0;
        }

        .u-b6 {
            @include font-map('b6');
            letter-spacing: 0.2px;
            @include font-default();
            margin-bottom: 0;
        }
    }

    .c-quotes__info {
        //padding-left: percentage(1.5/17.5);
        //margin-left: -30px;
        margin-top: 30px;

        @include mq(md) {
            margin-left: 0;
            padding-left: 0;
        }
    }

    blockquote {
        position: relative;
        //margin-left: percentage(2.5/17.5);
        //margin-left: - percentage(1.5/17.5);
        margin-left: -30px;
        max-width: $blockquote-grid-column-number * $settings-grid-column-width;
        @include font-map('a3--quote');
        padding-left: 30px;
        margin-top: 1.2em;
        margin-bottom: 1.8em;

        p {
            @include font-map('a3--quote');
            max-width: $blockquote-p-grid-column-number * $settings-grid-column-width;
        }

        @include mq(lg) {
            margin-left: 0;
        }

        &:before {
            @include font-map('a4');
            display: inline-block;
            content: "“";
            position: absolute;
            top: 0;
            left: -14px;

            @include mq(md) {
                left: 0;
            }
        }
    }

    ul,
    ol {
        display: block;
        margin-top: 0.8em;
        margin-bottom: 0.4em;
        counter-reset: bf-counter;
        @include font-map('b0');

        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 0.4em;

            &:before {
                @include font-map('b4');
                content: counter(bf-counter) ".";
                counter-increment: bf-counter;
                position: absolute;
                top: 6px;
                left: 11px;

                @include mq(lg) {
                    top: 4px;
                }

                @include mq(md) {
                    top: 0;
                }


            }

            a {
                &:before {
                    bottom: -2px;

                    @include mq(md) {
                        bottom: 0;
                    }
                }
            }

            p,
            span {
                font-size: inherit;
                font-weight: inherit;
            }
        }
    }

    ul {
        li {
            &:before {
                content: "";
                position: absolute;
                top: 12px;
                left: 13px;
                width: 4px;
                height: 4px;
                border-radius: 100px;
                background-color: getColor('dark-blue');

                @include mq(lg) {
                    top: 10px;
                }

                @include mq(md) {
                    top: 8px;
                }
            }

            p,
            span {
                font-size: inherit;
                font-weight: inherit;
            }
        }

        + h4 {
            margin-top: 2em;
        }
    }

    li {
        line-height: 1.5em;
    }

    img {
        margin-bottom: 2em;
        vertical-align: top;

        + p {
            margin-top: 2em;
        }
    }

    pre {
        margin-top: 2em;
        margin-bottom: 3.4em;
        display: block;
        @include font-map('b4');
        padding: 2em;
        background-color: getColor('dark-grey', 'secondary');

        @include mq(md) {
            //padding: 25px;
        }

        code {
            margin-left: 0;
            font-weight: bold;
        }
    }

    a {
        display: inline-block;
        position: relative;

        &:visited {
            //color: getColor('grey', 'secondary');
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: getColor('dark-blue');
            will-change: transform, background-color;
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
            transition: transform 0.35s;
            transition-timing-function: map_get($settings-transitions-easing, 'ease-out-quad');
        }

        span,
        i {
            font-style: normal;
            color: inherit;
        }

        @include mq(null, 'lg') {

            &:hover {

                span {
                    color: getColor('dark-blue');
                }

                &:before {
                    transform: scale3d(0, 1, 1);
                    transform-origin: 100% 50%;
                    transition-timing-function: ease;
                }
            }
        }
    }

    //overrides
    .c-blog-image,
    .o-media-wrapper {
        //float: left;
        max-width: 100%;

        img {
            max-width: 100%;
            margin-left: 0;
            vertical-align: top;
        }

        p {
            margin-left: 0;
        }
    }

    .c-blog-image {
        width: 100vw;
        max-width: 100vw;
        margin-left: - (3.5/24) * 100vw;

        @include mq(md) {
            margin-left: - getSpacing('lr-fixed', 'mobile');
        }

        + p {
            margin-top: 1em;
        }

        + h4 {
            margin-top: 1.6em;
        }
    }

    .o-media-caption {
        display: block;
        color: getColor('dark-blue');
        margin-top: getSpacing('xs');
        max-width: 3.5 * $settings-grid-column-width;
        @include font-default();
        @include font-map('b6');
        z-index: 2;
        width: 100vw;
        margin-left: (3.5/24) * 100vw !important;

        @include mq(md) {
            margin-left: getSpacing('lr-fixed', 'mobile') !important;
        }
    }

    //wp overrides
    .wp-caption {
        width: 100% !important;
        //float: left;
        //padding-left: percentage(4.5/17.5);
        margin-bottom: 2em;

        + p {
            margin-top: 0.8em;
        }

        + h4 {
            margin-top: 1.6em;
        }

        img {
            margin: 0;
            width: 100%;
            height: auto;
            vertical-align: top;
            //float: left;
        }

        &-text {
            margin-top: getSpacing('xs');
            width: 100%;
            //float: left;
            @include font-default();
            @include font-map('b6');
            margin-left: 0;
            padding-right: 6 * $settings-grid-column-width;

            @include mq(lg) {
                padding-right: 0;
                max-width: 210px;
            }
        }
    }
}

//MODIFIERS
.c-editor-content--work {
    padding-top: getSpacing('md', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('sm');
    }

    @include mq(sm) {
        padding-top: getSpacing('xs');
    }

    //EDITOR HTML ELEMENT SPECIFIC STYLE
    //h1, h2, h6 should be disabled

    h3,
    h4,
    p,
    ul,
    ol,
    blockquote,
    pre,
    code,
    img {
        display: block;
        @include font-default();
        //float: left;
        margin-left: 0;
        width: 100%;
        height: auto;
        max-width: $content-grid-column-number * $settings-grid-column-width;
        margin-top: 0;
    }

    blockquote {
        position: relative;
        //margin-left: - percentage(1.5/17.5);
        margin-left: -30px;
        max-width: $blockquote-grid-column-number * $settings-grid-column-width;
        @include font-map('a3--quote');
        padding-left: 30px;
        margin-top: 1.2em;
        margin-bottom: 1.8em;

        p {
            @include font-map('a3--quote');
            max-width: $blockquote-p-grid-column-number * $settings-grid-column-width;
        }

        @include mq(lg) {
            margin-left: 0;
        }

        &:before {
            @include font-map('a4');
            display: inline-block;
            content: "“";
            position: absolute;
            top: 0;
            left: -14px;

            @include mq(lg) {
                left: 0;
            }
        }
    }

    //overrides
    .c-blog-image,
    .o-media-wrapper {
        //float: left;

        img {
            max-width: 100vw;
            vertical-align: top;
        }
    }

    .c-blog-image {
        width: 100vw;
        max-width: 100vw;
        margin-left: - (3.5/24) * 100vw;

        @include mq(lg) {
            margin-left: -6.75%
        }

        @include mq(md) {
            margin-left: - getSpacing('lr-fixed', 'mobile');
        }
    }

    .o-media-caption {
        width: 100vw;
        margin-left: (3.5/24) * 100vw !important;

        @include mq(lg) {
            margin-left: -6.75%
        }

        @include mq(md) {
            margin-left: getSpacing('lr-fixed', 'mobile') !important;
        }
    }

    //wp overrides
    .wp-caption {
        width: 100vw !important;

        &-text {
            width: 100vw;
        }
    }
}

.c-editor-content--resources {
    h3 {
        @include font-map('a1');
    }

    h4 {
        @include font-map('a0');
    }

    p {
        @include font-map('b5');
    }

    //overrides
    .c-quotes__item {
        .u-b5 {
            @include font-map('b5');
        }

        .u-b6 {
            @include font-map('b7');
        }
    }

    blockquote {
        @include font-map('a2--quote');

        p {
            @include font-map('a2--quote');
        }

        &:before {
            @include font-map('a2');
        }
    }

    ul,
    ol {
        @include font-map('b5');

        li {

            &:before {
                @include font-map('b6');
            }
        }
    }

    pre {
        @include font-map('b6');
    }

    //overrides


    .o-media-caption {
        @include font-map('b7');
    }

    //wp overrides
    .wp-caption {

        &-text {
            @include font-map('b7');
        }
    }
}

.c-editor-content--careers {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: getSpacing('lg');
        padding-bottom: getSpacing('lg');
    }

    //EDITOR HTML ELEMENT SPECIFIC STYLE
    //h1, h2, h6 should be disabled

    h3,
    h4,
    p,
    ul,
    ol,
    blockquote,
    pre,
    code,
    img {
        display: block;
        @include font-default();
        margin-left: 0;
        //float: left;
        width: 100%;
        height: auto;
        max-width: $content-grid-column-number * $settings-grid-column-width;
        margin-top: 0;

        @include mq(lg) {
            margin-left: 0;
            padding-left: 0;
        }
    }

    blockquote {
        position: relative;
        //margin-left: - percentage(1.5/17.5);
        margin-left: -30px;
        max-width: $blockquote-grid-column-number * $settings-grid-column-width;
        @include font-map('a3--quote');
        padding-left: 30px;
        margin-top: 1.2em;
        margin-bottom: 1.8em;

        p {
            @include font-map('a3--quote');
            max-width: $blockquote-p-grid-column-number * $settings-grid-column-width;
        }

        @include mq(lg) {
            margin-left: 0;
        }

        &:before {
            @include font-map('a4');
            display: inline-block;
            content: "“";
            position: absolute;
            top: 0;
            left: -14px;

            @include mq(lg) {
                left: 0;
            }
        }
    }

    .c-editor-content--careers {
        padding-top: getSpacing('xl', 'fluid');
        padding-bottom: getSpacing('xl', 'fluid');

        @include mq(md) {
            padding-top: getSpacing('lg');
            padding-bottom: getSpacing('lg');
        }

        //EDITOR HTML ELEMENT SPECIFIC STYLE
        //h1, h2, h6 should be disabled

        h3,
        h4,
        p,
        ul,
        ol,
        blockquote,
        pre,
        code,
        img {
            display: block;
            @include font-default();
            margin-left: 0;
            //float: left;
            width: 100%;
            height: auto;
            max-width: $content-grid-column-number * $settings-grid-column-width;
            margin-top: 0;

            @include mq(lg) {
                margin-left: 0;
                padding-left: 0;
            }
        }

        blockquote {
            position: relative;
            //margin-left: - percentage(1.5/17.5);
            margin-left: -30px;
            max-width: $blockquote-grid-column-number * $settings-grid-column-width;
            @include font-map('a3--quote');
            padding-left: 30px;
            margin-top: 1.2em;
            margin-bottom: 1.8em;

            p {
                @include font-map('a3--quote');
                max-width: $blockquote-p-grid-column-number * $settings-grid-column-width;
            }

            @include mq(lg) {
                margin-left: 0;
            }

            &:before {
                @include font-map('a4');
                display: inline-block;
                content: "“";
                position: absolute;
                top: 0;
                left: -14px;

                @include mq(lg) {
                    left: 0;
                }
            }
        }
    }
}

.padding-bottom-10vh {
    padding-bottom: 10vh;
}
