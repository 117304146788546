.c-cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: getZindex('cookie-bar');
    padding-left: getSpacing('lr-fixed', 'mobile');
    padding-right: getSpacing('lr-fixed', 'mobile');

    &__inner {
        width: 100%;
        //max-width: 18 * $settings-grid-column-width;
        max-width: 950px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 auto;
        padding: 10px 10px 10px 25px;
        background-color: getColor('white-two', 'sections');
        color: getColor('dark-blue');
        border-radius: getBorderRadius('default') getBorderRadius('default') getBorderRadius('zero') getBorderRadius('zero');

        @include mq(md) {
            flex-wrap: wrap;
            padding: 10px;
        }

        &--settings {
            background-color: getColor('light-grey', 'secondary');
            border-radius: getBorderRadius('zero') getBorderRadius('zero') getBorderRadius('zero') getBorderRadius('zero');
        }
    }

    &__left {
        width: 100%;
        padding-right: 10px;

        p {
            margin-bottom: 0;
        }

        @include mq(sm) {
            padding-left: 0;
        }
    }

    &__right {
        flex-wrap: nowrap;
        display: flex;

        @include mq(md) {
            padding-top: 10px;
        }

        //overrides
        .c-button {
            &:not(:last-child) {
                margin-right: 10px;
            }

            @include font-map('b6');

            @include mq(md-lg) {
                @include font-map('b7');
            }

            @include mq(sm) {
                padding-left: getSpacing('sm');
                padding-right: getSpacing('sm');
            }

            //cursor: pointer;
            //border-color: getColor('dark-grey-A', 'secondary');
            //background-color: getColor('dark-grey-A', 'secondary');

            span {
                padding: 0;
                white-space: nowrap;
            }

            &.save-setting {
                display: none;
            }

            &.accept,
            &.toggle-setting {
                display: block;
            }
        }
    }

    &.is-open {
        .c-button {
            &.save-setting {
                display: block;
            }

            &.accept,
            &.toggle-setting {
                display: none;
            }
        }
    }

    &__settings {
        max-height: 400px;
        overflow-y: auto;

        &__row {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid getColor('dark-blue');

            .c-button {
                padding-top: getSpacing('xs');
                padding-bottom: getSpacing('xs');

                @include font-map('b6');

                @include mq(md-lg) {
                    @include font-map('b7');
                }

                @include mq(sm) {
                    padding-left: getSpacing('sm');
                    padding-right: getSpacing('sm');
                }

                &.is-active {
                    background-color: getColor('blue');
                    border-color: getColor('blue');
                }
            }
        }
    }
}

body {
    &.cookie-settings {
        .c-cookie-bar {
            &__settings {
                max-height: max-content;
            }
        }
    }
}
