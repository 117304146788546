.c-table {
  &__wrapper {
    overflow-x: scroll;
    margin-bottom: getSpacing('md');
  }

  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;

  td,
  th {
    font-size: 1rem;
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;

    @media (max-width: 768px) {
      font-size: .75rem;
    }
  }

  td {

  }

  th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
}
