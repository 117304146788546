//HEADER
.c-header {
    padding: getSpacing('xl', 'fluid') 0;
    //padding-left: $navigation-width;
    //padding-left: percentage(1.5/24);
    position: relative;
    height: 100vh;

    @include mq(md) {
        padding-left: getSpacing('lr-fixed', 'mobile');
        padding-right: getSpacing('lr-fixed', 'mobile');
    }

    //overrides
    .o-container {
        position: relative;
        z-index: 2;

        height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;

        @include mq(md) {
            //padding-top: getSpacing('sm');
        }
    }

    &--work-single {
        .o-container {

        }
    }

    .c-work-single-visual {
        z-index: 1;
    }

    &__home-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        transform: rotate(90deg);
        width: 40px;
        height: 40px;
        position: absolute;
        left: auto;
        top: calc(100vh - 60px);
        right: 30px;
        cursor: pointer;
        z-index: 3;
        animation-name: arrowAnimation;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;

        .icon {
            width: 1.5rem;
            height: 1.5rem;
        }

        @include mq(md) {
            display: none;
        }
    }

    @keyframes arrowAnimation {
        0% {
            transform: translateY(-20px) rotate(90deg);
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        60% {
            opacity: 1;
        }
        100% {
            transform: translateY(10px) rotate(90deg);
            opacity: 0;
        }
    }

    //HEADER INTRO
    &__intro {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 10.5 * $settings-grid-column-width;
        margin-top: getSpacing('xl');

        @include mq(md) {
            margin-top: getSpacing('lg');
        }

        @include mq(sm) {
            display: block;
        }

        &-column {
            max-width: 5 * $settings-grid-column-width;
            padding-right: 30px;

            @include mq(sm) {
                width: 100%;
                padding-right: 0;
                max-width: 320px;
                margin-bottom: getSpacing('sm');

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__cta {
        padding-top: 40px;
    }

    &__heading {
        width: percentage(1/1);
    }

    &__slider {
        margin-top: getSpacing('md');
    }

    &__slider-nav {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        z-index: 1;
        margin-top: getSpacing('sm-A');
    }

    &__slider-prev {
        margin-right: 13px;
    }

    //MODIFIERS
    .c-header__intro--home {
        margin-top: 0;
    }
}

body:not(.home) {
    .c-header__home-arrow {
        display: none;
    }
}

//MODIFIERS
//home
.c-header--home {
    padding-top: getSpacing('xxxl', 'fluid');
    padding-bottom: getSpacing('xxxl', 'fluid');
    background-color: getColor('watermelon', 'sections');

    //background: linear-gradient(left , getColor('pink', 'sections') , getColor('light-pink', 'sections') 100%);
    //background: -o-linear-gradient(left , getColor('pink', 'sections') , getColor('light-pink', 'sections') 100%);
    //background: -ms-linear-gradient(left , getColor('pink', 'sections') , getColor('light-pink', 'sections') 100%);
    //background: -moz-linear-gradient(left , getColor('pink', 'sections') , getColor('light-pink', 'sections') 100%);
    //background: -webkit-linear-gradient(left , getColor('pink', 'sections') , getColor('light-pink', 'sections') 100%);

    @include mq(md) {
        padding-top: 25vh;
        padding-bottom: 25vh;
    }

    color: getColor('white');
    position: relative;
    z-index: 2;

    //overrides
    .c-button--primary {
        border: 1px solid getColor('white');
        color: getColor('dark-blue');
        background-color: getColor('white');
        margin-top: 10px;
        min-width: 135px;

        &:before {
            background-color: getColor('dark-blue');
        }

        &:hover {
            span {
                color: getColor('white');
            }
        }
    }

    .u-a7 {
        @include font-map('a7--highlight');
        @include font-heading(); //bold
        letter-spacing: -1.2px;

        &.u-a7--fluid {
            font-size: get-vw(80px);
            max-width: 1200px;

            @include mq($from: xl) {
                letter-spacing: - get-vw(1px);
            }

            @include mq(lg) {
                //@include font-map('a7--highlight');
                @include font-map('a7--header');
                letter-spacing: -1.2px;
            }
        }

        span {
            display: block;

            @include mq(lg) {
                display: inline;
            }
        }

        > div {
            overflow: hidden;
        }
    }

    .c-header__heading {
        max-width: percentage(15.5/17.7);
    }
}

.u-lang--sk {
    .c-header,
    .c-header--home {
        .u-a7,
        .u-a7--fluid {
            //@include font-map('a7--header');
            font-size: get-vw(70px);

            @include mq('sm') {
                font-size: get-vw(150px);
            }
        }
    }
}

//services
.c-header--services {
    //background-color: getColor('pale-brown', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: 25vh;
    }

    .u-a7 {
        max-width: percentage(16.5/17.5);

        @include mq('md') {
            max-width: 100%;
        }
    }
}

//service
.c-header--service {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('sm', 'fluid');
    color: getColor('white');

    h1 {
        padding-bottom: 25px;
    }

    h1,
    h2 {
        max-width: 960px;
        width: 100%;
    }
}

//culture
.c-header--culture {
    //background-color: getColor('pale-blue-A', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: 25vh;
    }

    //overrides
    .u-a7 {
        max-width: percentage(16/17.5);
    }
}

//our approach
.c-header--our-approach {
    //background-color: getColor('light-green', 'sections');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');

    @include mq(md) {
        padding-top: 25vh;
    }

    //overrides
    .u-a7 {
        margin-bottom: 0.4em;
        max-width: percentage(14/17.5);
    }

    .u-a3 {
        max-width: 14 * $settings-grid-column-width;
    }
}

//blog
.c-header--blog {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: 0;
    background-color: getColor('pale-blue-A', 'sections');
}

//resources
.c-header--resources {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: 0;
}

//blog single
.c-header--blog-single {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xs');

    //overrides
    .u-a5 {
        margin-bottom: 1.1em;
        max-width: 14 * $settings-grid-column-width;
    }

    .c-details-info {
        display: flex;

        @include mq(md) {
            display: block;
        }

    }
}

//careers
.c-header--careers {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    //overrides
    .u-a7 {
        padding-bottom: getSpacing('md');
    }

    .u-a3 {
        max-width: 12 * $settings-grid-column-width;
    }
}

//careers single
.c-header--careers-single {
    background-color: getColor('dark-grey', 'sections');
    color: getColor('white');
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('lg', 'fluid');

    //overrides
    .u-b4 {
        color: getColor('white-40', 'bw-transparent');
        margin-bottom: getSpacing('md');

        @include mq(md) {
            margin-bottom: 0;
        }

        &.c-details-info__item {
            color: getColor('white');
        }
    }

    .u-b0 {
        padding-top: 15px;
    }

    .c-details-info {
        margin-top: getSpacing('lg');
        padding-top: getSpacing('sm');

        @include mq(md) {
            margin-bottom: 0;
        }
    }
}

//work
.c-header--work {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xxl', 'fluid');
    color: getColor('white');
    position: relative;
    z-index: 2;

    //overrides
    .u-a7 {
        max-width: percentage(12/17.5);
    }
}

//work single
.c-header--work-single-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    position: relative;

    @include mq(md) {
        padding-left: 0;
        padding-right: 0;
    }

    @include mq(md) {
        flex-flow: column;
    }

    .c-header--work-single {
        &.c-header--work-prev,
        &.c-header--work-next {
            width: 100%;
            float: left;
        }
    }

    &--columns-1 {
        flex-flow: column;
    }

    &--columns-2 {
        .c-header--work-single {
            &.c-header--work-prev,
            &.c-header--work-next {
                width: 50%;

                @include mq(md) {
                    width: 100%;
                }

                .u-a7 {
                    &.smaller {
                        white-space: nowrap;
                        //overflow: hidden;
                        //text-overflow: ellipsis;
                        //max-width: 100%;
                        font-size: get-vw(70px);
                        letter-spacing:  - get-vw(1px);

                        @include mq(md) {
                            font-size: get-vw(100px);
                        }
                    }
                }

                .u-a2 {
                    &.smaller {
                        font-size: get-vw(21px);

                        @include mq(md) {
                            font-size: get-vw(61px);
                        }
                    }
                }
            }
        }
    }

    &--columns-1,
    &--columns-2 {
        .c-header--work-single:not(.is-loading) {
            .c-work-single-visual {
                @include mq(null, 'lg') {
                    transition: getTransition('slide-slow', 'ease-out-quad');
                }
            }

            &:hover {
                .c-work-single-visual {
                    @include mq(null, 'lg') {
                        transform: translateY(-10%);
                    }
                }
            }
        }
    }

    &--columns-1 {
        .c-header--work-single:not(.is-loading) {
            &:hover {
                .c-work-single-visual {
                     @include mq(null, 'lg') {
                        transform: translateY(-5%);
                    }
                }
            }
        }
    }
}

.c-header--work-single {
    padding-top: getSpacing('xl', 'fluid');
    padding-bottom: getSpacing('xl', 'fluid');
    color: getColor('white');
    position: relative;
    z-index: 2;
    height: 100vh;

    &.text-dark {
        color: getColor('black');
    }

    &.text-light {
        color: getColor('white');
    }

    .u-a7 {
        white-space: nowrap;
    }

    @include mq(lg) {
        //padding-top: getSpacing('xxl', 'fluid');
    }

    @include mq(md) {
        height: auto;
        //padding-top: getSpacing('xxl', 'fluid');
        //padding-top: 25vh;
        min-height: 100vh;
        padding-bottom: percentage(9/16);
    }

    .o-container {
        @include mq(md) {
            padding-top: 3rem;
        }
    }

    &.is-loading {
        z-index: 3;
    }

    //MODIFIER
    &.c-header--work-prev,
    &.c-header--work-next {
        padding-top: getSpacing('xl', 'fluid');
        padding-bottom: getSpacing('xl', 'fluid');
        position: relative;
        height: 80vh;

        @include mq(md) {
            height: 100vh;
            padding-bottom: percentage(9/16);
        }

        .o-container {

        }

        a {
            display: block;
            height: 80vh;

            @include mq(md) {
                height: 100vh;
            }

            //&:before {
            //    content: "";
            //    position: absolute;
            //    top: 0;
            //    left: - percentage(1.5/22.5);
            //    width: 100vw;
            //    bottom: 0;
            //    background-color: getColor('black-10', 'bw-transparent');
            //    will-change: transform, background-color;
            //    transform: scale3d(0, 1, 1);
            //    transform-origin: 100% 50%;
            //    transition: transform 0.8s;
            //    transition-timing-function: map_get($settings-transitions-easing, 'ease-in-out-expo');
            //}
        }

        @include mq(null, 'lg') {

            &:hover {
/*
                &:before {
                    transform: translateY(-100%);
                }
*/
                //span {
                //
                //}
                //
                //&:before {
                //    transform: scale3d(1, 1, 1);
                //    transform-origin: 0% 50%;
                //    transition-timing-function: ease;
                //}

                .u-a5 {
                    transform: translateY(-10px);

                    &:before {
                        transform: scale3d(1, 1, 1);
                        transform-origin: 0% 50%;
                        transition-timing-function: ease;
                    }
                }
            }
        }

        //overrides
        .u-a5 {
            position: relative;
            display: inline-block;
            transition: transform 0.65s;
            transition-timing-function: map_get($settings-transitions-easing, 'ease-out-expo');

            &:before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 5px;
                background-color: getColor('white');
                z-index: 2;
                will-change: transform, color;
                transform: scale3d(0, 1, 1);
                transform-origin: 100% 50%;
                transition: transform 0.5s;
                transition-timing-function: map_get($settings-transitions-easing, 'ease-in-out-expo');
            }

        }
    }
}

//team
.c-header--team {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('lg');

    @include mq(md) {
        padding-top: 25vh;
    }
}

//contact
.c-header--contact {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: 0;

    //overrides
    .u-a7 {
        max-width: percentage(16/17.5);
    }

}

//404
.c-header--error-page {
    padding-top: getSpacing('xxxl', 'fluid');
    padding-bottom: getSpacing('xxxl', 'fluid');
    background-color: getColor('watermelon', 'sections');

    @include mq(md) {
        padding-top: 25vh;
        padding-bottom: 25vh;
    }

    color: getColor('white');
    position: relative;
    z-index: 2;

    .u-a7 {
        @include font-map('a7--highlight');
        @include font-heading(); //bold
        letter-spacing: -1.2px;

        &.u-a7--fluid {
            font-size: get-vw(110px);
            max-width: 1200px;

            @include mq($from: xl) {
                letter-spacing: - get-vw(1px);
            }

            @include mq(lg) {
                //@include font-map('a7--highlight');
                @include font-map('a7--header');
                letter-spacing: -1.2px;
            }
        }

        span {
            display: block;

            @include mq(lg) {
                display: inline;
            }
        }

        > div {
            overflow: hidden;
        }
    }

    .c-header__heading {
        max-width: percentage(15.5/17.7);
    }
}

.u-lang--sk {
    .c-header,
    .c-header--error-page {
        .u-a7,
        .u-a7--fluid {
            //@include font-map('a7--header');
            font-size: get-vw(70px);

            @include mq('sm') {
                font-size: get-vw(150px);
            }
        }
    }
}

.c-header--error-page {
    /*height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    padding-left: 0;
    background-color: getColor('green', 'sections');

    //overrides
    .o-container {
        max-width: 100%;
        padding: percentage(1/24);
    }

    .u-a7 {

    }

    .u-b0 {
        max-width: 13 * $settings-grid-column-width;
        margin: 0 auto;
    }

    .c-button {
        margin-top: 40px;
    }*/
}

//WORK LIST
.c-header--work-list {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl');
    height: auto;
}


//ABOUT
.c-header--about {
    padding-top: getSpacing('xxl', 'fluid');
    padding-bottom: getSpacing('xl');
    height: auto;

    .c-svg-hand {
        margin-left: getSpacing('sm');

        @include mq(sm) {
            margin-left: getSpacing('xs');
        }
    }
}

//GENERIC
.c-header--generic {
    padding-bottom: 0;
    height: auto;

    .is-protected & {
        .u-b4,
        .u-a5 {
            max-width: 660px;
        }
    }
}


.c-header__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: getZindex('background-animation');

    &-inner {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
        perspective-origin: 50% 50%;
    }

    &-item {
        position:absolute;
        bottom: -100vh;
        bottom: -100%;
        transform-style: preserve-3d;

        img {
            max-height: 100%;
            width: auto;
        }
    }

    $total: 10;
    @for $i from 1 through $total {
        $scale: random(2) - .4;

        .c-header__bg-item:nth-child(#{$i}) {
            height: $i * 15 + px;
            width: auto;
            opacity: .35;
            left: random(120) * 1% - 20;
            animation: raise#{$i} 6 + random(15) +s linear infinite;
            animation-delay:random(5) - 5 + s;
            transform: scale(.3 * $i - .6) rotate(random(360) + deg);
            //z-index: $i - 7;
            filter: blur($i - 6 + px);

            @keyframes raise#{$i} {
                to{
                    bottom:150vh;
                    bottom:150%;
                    transform: scale(.3 * $i - .6) rotate(random(360) + deg);
                    opacity: -.2;
                }
            }
        }
    }
}
