//SERVICES PAGE SPECIFIC STYLES
.c-list {
    &-column {
        padding: getSpacing('xs-A') 0;
    }
}
//approach
.approach {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: getZindex('default');

    @include mq(md) {
        display: block;
    }

    &__left {
        order: 1;
        width: percentage(7.5/17.5);
        padding-right: percentage(1/17.5);

        @include mq(md) {
            width: 100%;
            padding-right: 0;
            margin-top: getSpacing('sm');
        }

        //overrides
        .c-article {

        }

        .u-a4 {
            max-width: 5.5 * $settings-grid-column-width;
        }

        .u-b2 {
            max-width: 5.5 * $settings-grid-column-width;
            margin-top: getSpacing('sm');
        }

        .c-button {
            margin-top: getSpacing('md');
        }
    }

    &__right {
        order: 2;
        width: percentage(10/17.5);

        @include mq(md) {
            width: 100%;
        }
    }
}

//strategic sessions
.strategic-sessions {
    display: flex;
    align-items: center;
    position: relative;
    z-index: getZindex('default');

    @include mq(md) {
        display: block;
    }

    &__left {
        order: 2;
        width: percentage(8.5/17.5);
        padding-left: percentage(2/17.5);
        padding-right: percentage(1/17.5);

        @include mq(lg) {
            padding-left: percentage(1/17.5);
            padding-right: 0;
        }

        @include mq(md) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }

        //overrides
        .c-article {

        }

        .u-b2 {
            max-width: 5.5 * $settings-grid-column-width;
            margin-top: getSpacing('sm');
        }

        .c-button {
            margin-top: getSpacing('md');
        }
    }

    &__right {
        order: 1;
        width: percentage(8.5/17.5);
        padding-right: percentage(0.5/17.5);

        @include mq(md) {
            width: 100%;
            margin-bottom: getSpacing('md');
            padding-right: 0;
        }

        .o-media-wrapper--1-2 {
            padding-bottom: percentage(900/510);
        }
    }
}