.c-read-more {

    @include mq(md) {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__title {
        max-width: 770px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 10px;
    }

    &__subtitle.u-b0 {
        max-width: 850px;
        margin: 0 auto;
        text-align: center;
    }

    &__list {

    }

    &__navigation {
        padding: 35px 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__prev,
    &__next {
        width: 66px;
        height: 66px;
        border: 1px solid rgba(getColor('grey', 'secondary'), 0.5);
        border-radius: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__navigation-counter {
        display: inline-flex;
        justify-content: center;
        width: auto;
        align-items: center;
        padding: 16px;
    }

    &__prev {
    }

    &__mask {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: getColor('white');
        z-index: 9;

        &--gray {
            background-color: getColor('white-two', 'sections');
        }
    }

    &__img {
        width: 100%;
        height: 0;
        padding-top: percentage(240/360);
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        position: relative;
    }

    .swiper-slide {
        display: inline-block;
        padding: 0 15px;

        @include mq(sm) {
            padding: 0;
        }

        p {
            margin-top: 18px;
            margin-bottom: 0;
            max-width: 330px;
        }
    }


}
