.c-awards {

  .u-a4 {
    margin-bottom: getSpacing('xs');
  }

  .u-b0 {
    margin-bottom: getSpacing('lg');
  }

  &__list {
    display: grid;
    grid-gap: 76px 43px;
    grid-template-columns: repeat(auto-fit,minmax(calc(25% - 43px),auto));

    @include mq(lg) {
      grid-gap: 40px 25px;
    }

    @include mq(md) {
      grid-template-columns: repeat(auto-fit,minmax(calc(33% - 43px),auto));
    }

    @include mq(sm) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      grid-template-columns: none;
      grid-gap: unset;
      -webkit-overflow-scrolling: touch;
      padding: 26px;
      margin-bottom: 20px;
    }

    li {
      -webkit-box-shadow: 4px 4px 4px 0 rgba(0,0,0,.13);
      box-shadow: 4px 4px 4px 0 rgba(0,0,0,.13);

      @include mq(sm) {
        width: 66.25vw;
        margin-right: 10.3125vw;
      }

      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 0;
        display: block;
      }
    }
  }
}

.glide__slide,
.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}