// PAGE TRANSITIONS
.c-page-transitions {
    height: 100vh;
    width: 0;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: getZindex('navigation', +2);
    pointer-events: none;
    transform: translateY(99.5%);
    will-change: transform, width;

    &__container {

    }
}