//WORK

//WORK SINGLE
.work-single-heading {
    width: 100%;
    padding-right: percentage(10.5/17.5);
    //max-width: percentage(7.5/17.5);

    @include mq(md) {
        //max-width: percentage(17.5/17.5);
        padding-right: percentage(0);
    }

    //overrides
    .u-b4 {
        color: getColor('white-50', 'bw-transparent');
        margin-bottom: getSpacing('sm');
    }

    .u-a7 {

    }

    .u-a2 {
        margin-top: getSpacing('md', 'fluid');
    }

}

.work-single-info {
    width: 100%;
    padding-right: percentage(10.5/17.5);
    //max-width: percentage(8.5/17.5);

    @include mq(lg) {
        //max-width: percentage(6.5/17.5);
    }

    @include mq(md) {
        //max-width: 100%;
        margin-bottom: getSpacing('md');
        padding-right: percentage(0);
    }

    //overrides
    .c-list,
    .u-b4 {
        margin-top: getSpacing('xxxl', 'fluid');
        padding-top: getSpacing('sm');
        padding-bottom: 0;
        position: relative;
        max-width: 4.5 * $settings-grid-column-width;

        @include mq(md) {
            margin-top: getSpacing('lg');
        }
    }

    .c-list {

        @include mq(md) {
            max-width: 100%;
        }
    }

    .c-tag {
        margin-top: getSpacing('md');
    }

    .u-b4 {
        @include font-heading();
    }

    .c-list-item{
        @include font-map('b4');
        padding-top: 0;
        padding-bottom: 0;
        border: none;
    }
}

.c-list__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: getColor('white-30', 'bw-transparent');
}

//WORK LINKS
.work-links {
    padding: getSpacing('xl') 0;

    @include mq(md) {
        padding: 0 0 getSpacing('md') 0;
    }

    //overrides
    .u-b4,
    .u-a6 {
        color: getColor('white-30', 'bw-transparent');
    }

    .u-b4 {
        margin-bottom: getSpacing('md');
    }

    .u-a6 {
        transition: getTransition();

        @include mq(md) {
            color: getColor('white');
        }
    }

    &__item {
        float: left;
        width: percentage(1/2);
        padding-right: getSpacing('md');

        @include mq(sm) {
            width: 100%;
            padding-right: 0;
            padding-top: getSpacing('sm');
            padding-bottom: getSpacing('sm');
        }

        a {
            display: block;
        }

        //hover
        &:hover {
            .u-a6 {
                color: getColor('white');
            }
        }
    }
}

//WORK MOBILE
.c-mobile-work {
    &__list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__item {
        width: percentage(9.5/20);
        margin-bottom: getSpacing('md');

        @include mq(sm) {
            width: 100%;
        }

        a {
            display: block;
        }

        //overrides
        .c-work-item {
            display: block;
            padding-top: 0;
        }

        .u-a4 {
            @include font-map('a4--quote')
        }

        .c-work-item__image {
            width: 100%;
            margin-bottom: getSpacing('sm');
        }
    }
}
