$lineIndex : 1; // --line-index

.c-word-set {
    display: none;
}

.c-word-insert {
    overflow: hidden;
    line-height: 1.15;

    > * {
        display: block;
        -webkit-transform:translateY(0);
        -o-transform:translateY(0);
        transform:translateY(0);
        -webkit-transition:-webkit-transform .5s ease;
        transition:-webkit-transform .5s ease;
        -o-transition:-o-transform .5s ease;
        transition:transform .5s ease;
        transition:transform .5s ease,-webkit-transform .5s ease,-o-transform .5s ease;
    }

    &.show-on > * {
        -webkit-transform:translateY(0);
        -o-transform:translateY(0);
        transform:translateY(0);
        -webkit-transition-delay:.25s;
        -o-transition-delay:.25s;
        transition-delay:.25s;

        -webkit-transition:-webkit-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
        transition:-webkit-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
        -o-transition:-o-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
        transition:transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease;
        transition:transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease,-webkit-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease,-o-transform calc(0.25s*(var($lineIndex) + 1) + .5s) ease
    }

    &.show-off > * {
        -webkit-transform:translateY(105%);
        -o-transform:translateY(105%);
        transform:translateY(105%);
    }
}

.c-wire-text {
    color: getColor('white', 'primary');
    -webkit-text-stroke: 3px getColor('white-0', 'bw-transparent');

    @include mq(md) {
        -webkit-text-stroke: 2px getColor('white-0', 'bw-transparent');
    }

    &.show {
        transition:-webkit-text-stroke .75s ease,color .75s ease;
        -webkit-transition-delay: .5s;
        -o-transition-delay: .5s;
        transition-delay: .5s;
        color: getColor('white-0', 'bw-transparent');
        -webkit-text-stroke-color: getColor('white', 'primary');
    }
}

.c-header__heading {
    .c-wire-text {
        &.show {
        }
    }
}