//HOME PAGE SPECIFIC STYLES
.home-show-more {
    text-align: center;
    padding-top: getSpacing('md', 'fluid');
    padding-bottom: getSpacing('md', 'fluid');
}

.home-intro-heading {
    max-width: 15 * $settings-grid-column-width;
    padding-bottom: getSpacing('xxl', 'fluid');

    @include mq(md) {
        padding-bottom: getSpacing(md);
    }

    //overrides
    .u-b6 {
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: getSpacing('lg');
    }

    .u-a4 {
        @include font-map('a4--quote');
    }
}

.home-intro {
    display: flex;
    align-items: flex-start;

    @include mq(md) {
        display: block;
    }

    &__left {
        /*padding-right: percentage(1/17.5);
        width: percentage(10.5/17.5);

        @include mq(md) {
            width: 100%;
            padding-right: 0;
            margin-bottom: getSpacing('xl');
        }*/

        float:left;
        width: percentage(8.5/17.5);
        padding-right: percentage(2/17.5);

        @include mq(lg) {
            width: percentage(7.5/17.5);
            padding-right: percentage(1/17.5);
        }

        //overrides
        .u-b2 {
            max-width: 6 * $settings-grid-column-width;
        }

        .c-button {
            margin-top: getSpacing('sm');
        }

    }

    &__right {
        /*width: percentage(7/17.5);

        .o-media-wrapper__mask {
            background-color: getColor('white');
        }

        @include mq(md) {
            width: 100%;
        }*/

        float: right;
        width: percentage(9/17.5);
        position: relative;
        overflow: hidden;

        @include mq(lg) {
            width: percentage(10/17.5);
        }
    }
}

//TESTIMONIALS
.home-testimonials {
    position: relative;
    padding-bottom: 100px;
    //overrides
    .c-quotes__navigation {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 3 * $settings-grid-column-width;

        &-inner {
            width: 100%;
            text-align: center;
        }
    }

    .c-quotes__navigation-item--arrow-left {
        position: absolute;
        left: 0;
        top: 0;
    }

    .c-quotes__navigation-item--arrow-right {
        position: absolute;
        right: 0;
        top: 0;
        margin-right: - getSpacing('sm');

        @include mq(md) {
            margin-right: 0;
        }

    }
}