//FEATURED WORK
.c-featured-work {
    position: relative;

    //LIST
    &__list {
        //float:left;
        //width: percentage(9.5/17.5);
        //padding-right: percentage(1.5/17.5);
        position: absolute;
        width: 100%;
        padding-right: percentage(7/10);
        z-index: 10;

        @include mq(lg) {
            //width: percentage(7.5/17.5);
            //padding-right: percentage(1/17.5);
        }
    }

    &__list-item {
        height: 100vh;
        display: flex;
        align-items: center;

        &:last-of-type {
            //margin-bottom: 50vh;
        }
    }

    .u-b1 {
        position: absolute;
        top: getSpacing('lg');
        left: 0;
    }

    &__content {
        position: relative;
        //padding: 30px;
        //padding-right: 0;
        //margin-left: -30px;
        background-color: getColor('white', 'primary');
        padding: 40px 40px 40px 0;
        border-radius: getBorderRadius('xs');

        a {
            display: block;

            //hover
            @include mq(null, 'lg') {

                &:hover {

                    .c-button__layer {
                        transform: scale(1.5);
                    }

                    .u-a2 {

                    }

                    .u-b6 {
                        //opacity: 0;
                        //transform: translateX(10px);
                    }

                    .u-b4 {
                        //transform: translateX(10px);
                    }
                }
            }
        }

        .c-featured-work__show-more {
            margin-top: getSpacing('xxxl', 'fluid');

            a {
                display: inline-block;
            }
        }

        .u-b1 {
            margin-bottom: getSpacing('xxl', 'fluid');
        }

        .u-a5 {
            margin-bottom: getSpacing('sm', 'fluid');
        }

        .u-a5 {
            &.u-a5--fluid {
                font-size: get-vw(70px);
            }

            margin-bottom: getSpacing('xs', 'fluid');
        }

        .u-a2 {

        }

        .u-b6 {
            display: inline-block;
            margin-bottom: getSpacing('sm');
            transition: getTransition('default-delay', 'ease-out-quad');
            will-change: transform, opacity;
        }

        .u-b4 {
            //max-width: 4.5 * $settings-grid-column-width;
            margin-top: getSpacing('md');
            margin-bottom: getSpacing('md');
            transition: getTransition('default', 'ease-out-quad');
            will-change: transform;
        }
    }

    //PREVIEW
    &__preview {
        float: right;
        //width: percentage(8/17.5);
        position: relative;
        overflow: hidden;
        width: percentage(8/10);

        @include mq(lg) {
            //width: percentage(10/17.5);
        }
    }

    &__preview-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: getColor('white', 'primary');
        z-index: 9;
    }

    &__preview-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        will-change: background-color;
    }

    &__preview-inner {
        position: relative;
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }

    &__preview-image {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 0;
        //padding-bottom: percentage(720/1080);
        padding-bottom: percentage(720/1620);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateY(-50%);
    }

    &__preview-list {
        height: 100vh;
        font-size: 0;
        white-space: nowrap;
        z-index: 2;
        position: relative;
        will-change: transform;

        &--alt {
            height: 90vh;
            position: absolute;
            top: 5vh;
            left: 10%;
            width: 90%;
            z-index: 1;

            .c-featured-work__preview-list-item {
                height: 90vh;
                //filter: blur(1px);
                //perspective: 800px;
                //transform: rotateX(40deg);

            }
        }
    }

    &__preview-list-item {
        width: 200%;
        height: 100vh;
        display: inline-block;
        position: relative;

        &:first-child {
            //margin-left: 100%;
        }

        //
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
        }
    }
}

.u-lang--sk {
    .c-featured-work {
        &__content {
            .u-a7 {
                @include font-map('a6--highlight');
            }
        }
    }
}

//MOBILE FEATURED WORK
.c-mobile-featured-work {
    padding-top: getSpacing('lg');

    //overrides
    .u-b6 {
        display: block;
        margin-bottom: getSpacing('xs');
    }

    .u-a7 {
        @include font-map('a4--case');
        margin-bottom: getSpacing('sm');
    }

    .u-b4 {
        //max-width: 320px;
    }

    .c-button--round {
        margin-top: getSpacing('sm');
    }

    &__list {

    }

    &__list-item {

    }

    &__visual {
        width: 100vw;
        margin-left: - getSpacing('lr-fixed', 'mobile');
        height: 0;
        padding-bottom: percentage(480/480);
        margin-bottom: getSpacing('sm');
        position: relative;

        img {
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            height: 200px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__preview-image {
        //display: block;
        //position: absolute;
        //top: 50%;
        //left: 0;
        //width: 100vw;
        //height: 0;
        //padding-bottom: percentage(480/480);
        //background-size: contain;
        //background-position: center;
        //background-repeat: no-repeat;
        //transform: translateY(-50%);
    }

    &__content {
        margin-bottom: getSpacing('lg');
    }
}
