.c-what-write-about-us {

  &__list {
    margin-bottom: getSpacing('sm');
  }

  &__item {
    position: relative;
    padding: 0 getSpacing('lg') getSpacing('lg') 0;
    display: inline-block;
    width: percentage(1/6.5);

    @include mq(md) {
      padding-bottom: 0;
    }

    img {
      opacity: 0.85;
      max-width: 100%;
      transition: getTransition('default', 'ease-out-quad');
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}