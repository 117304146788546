//COOKIE PRIVACY INFO
//NAVIGATION
.c-cookie-privacy-info {

    .u-b1 {
        margin-bottom: getSpacing('md');
    }

    &__item {
        strong {
            font-weight: 500;
        }

        .c-button--small {
            @include font-map('b4');
        }
    }

    //overrides
    .c-button--text {
        &:before {
            display: none;
        }
    }

    .c-button--bordered {
        &:before {
            display: block;
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }

        //hover
        @include mq(null, 'lg') {

            &:hover {

                &:before {
                    transform: scale3d(0, 1, 1);
                    transform-origin: 100% 50%;
                    transition-timing-function: ease;
                }
            }
        }
    }

    //MODIFIERS
    //small
    &.c-cookie-privacy-info--small {

        .c-cookie-privacy-info__item {
            .c-button--small {
                @include font-map('b6');
            }
        }
    }
}
