//WORK DETAILS
.c-work-details {
    width: 100%;
    max-width: 13 * $settings-grid-column-width;

    &__heading {
        padding-bottom: getSpacing('lg');

        @include mq(md) {
            padding-bottom: getSpacing('sm')
        }
    }

    &__list-wrapper {
        margin-bottom: getSpacing('lg', 'fluid');

        //
        display: grid;
        grid-template-columns: repeat(auto-fill, 3.5 * $settings-grid-column-width);
        grid-gap: 30px;
        justify-content: space-between;

        @include mq(md) {
            grid-gap: 15px;
            margin-bottom: getSpacing('lg');
        }
    }

    &__list {
        width: 100%;
        max-width: 3.5 * $settings-grid-column-width;
        margin-bottom: getSpacing('md');
        padding-right: 30px;

        @include mq(md) {
            margin-bottom: getSpacing('sm')
        }

        //overrides
        .c-list {
            padding: 0;
        }

        .c-list-item {
            padding-top: 0;
            padding-bottom: 4px;
            @include font-map('b4');
            border: none;
        }

        .u-b4 {
            margin-bottom: getSpacing('xs');
            @include font-heading();
        }
    }
}