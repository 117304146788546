.c-about {
  padding: getSpacing('lg') 0;

  &__first {
    .u-b0 {
      margin-bottom: getSpacing('lg');
    }

    .c-about__image {
      max-width: percentage(3/4);

      @include mq(md) {
        max-width: 100%;
      }
    }
  }

  &__second {
    display: flex;
    align-items: center;
    padding-top: getSpacing('xl');

    @include mq(sm) {
      display: block;
    }

    .u-a4 {
      padding-right: 11.1vw;
      margin-bottom: 0;

      @include mq(sm) {
        padding: 0 0 50px;
      }
    }

    .c-about__image {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 31vw;
      flex: 0 0 31vw;
      max-width: 31vw;

      @include  mq (sm) {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        max-width: 100%;
      }
    }
  }

  &__third {
    padding-top: getSpacing('xl');
  }

  &__fourth  {
    display: flex;
    align-items: center;
    padding-top: getSpacing('xl');

    @include mq(sm) {
      display: block;
    }

    &-content {
      padding-left: 11.1vw;

      @include mq(sm) {
        padding-left: 0;
      }

      .u-a4 {
        padding: 0 0 50px 0;
        @include mq(sm) {
          padding: 50px 0 50px 0;
        }
      }

      a {
        
      }
    }

    .c-about__image {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 31vw;
      flex: 0 0 31vw;
      max-width: 31vw;

      @include  mq (sm) {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        max-width: 100%;
      }

    }
  }
}