.c-image-title-block {
    display: flex;
    flex-wrap: wrap;

    &__img {
        padding-top: percentage(400/1200);
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        position: relative;
        width: percentage(10/20);

        @include mq(md) {
            width: 100%;
            margin-top: getSpacing("sm");
        }

        @include mq(sm){
            padding-top: percentage(600/1200);
        }
    }

    &__mask {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: getColor('white');
        z-index: 9;

        &--gray {
            background-color: getColor('white-two', 'sections');
        }
    }

    &__title-wrapper {
        width: percentage(10/20);
        display: flex;
        align-items: center;
        padding-left: percentage(1/20);
        padding-right: percentage(1/20);

        @include mq(md) {
            width: 100%;
            padding: 0 20px;
        }
    }

    &--left {
        flex-direction: row-reverse;

        @include mq(md) {
            flex-direction: row;
        }

        .c-image-title-block {

            &__title-wrapper {
                padding-left: percentage(2/20);
                padding-right: 0;

                @include mq(md) {
                    width: 100%;
                    padding-left: percentage(1/20);
                    padding-right: percentage(1/20);
                }
            }
        }
    }
}
