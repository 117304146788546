//RESET MARGINS
.u-a8,
.u-a7,
.u-a6,
.u-a5,
.u-a4,
.u-a3,
.u-a2,
.u-a1,
.u-b0,
.u-b1,
.u-b2,
.u-b3,
.u-b4,
.u-b5,
.u-b6,
.u-b7 {
    margin-top: 0;
    margin-bottom: 0;
}

//
.u-a8 {
    @include font-map('a8');

    &.u-a8--fluid {
        font-size: get-vw(140px);

        @include mq(lg) {
            @include font-map('a8');
        }
    }
}

//
.u-a7 {
    @include font-map('a7');
    @include font-heading(); //bold
    letter-spacing: -1px;

    &.u-a7--fluid {
        font-size: get-vw(110px);

        @include mq($from: xl) {
            letter-spacing: - get-vw(2px);
        }

        @include mq(lg) {
            @include font-map('a7');
            letter-spacing: -1px;
        }
    }
}

//

.u-a6 {
    @include font-map('a6');
    letter-spacing: -1px;
    @include font-default();

    @include mq(lg) {
        @include font-map('a6');
    }

    @include mq(md) {
        letter-spacing: -1.5px;
    }

    &.u-a6--fluid {
        font-size: get-vw(80px);

        @include mq(lg) {
            @include font-map('a6');
        }

        @include mq(md) {
            letter-spacing: -1.5px;
        }
    }
}

//
.u-a5 {
    @include font-map('a5');
    @include font-heading('regular'); //bold
    letter-spacing: -1px;

    @include mq(md) {
        letter-spacing: -1px;
    }

    &.u-a5--fluid {
        font-size: get-vw(80px);

        @include mq(lg) {
            @include font-map('a5');
        }
    }
}

//
.u-a4 {
    @include font-map('a4');
    @include font-default();
}

//
.u-a3 {
    @include font-map('a3');
    @include font-default();
}

//
.u-a2 {
    @include font-map('a2');
    letter-spacing: -1px;
    @include font-default();
}

//
.u-a1 {
    @include font-map('a1');
    @include font-default();
    margin-bottom: 0.8em;
}

//BASE
//
.u-b0 {
    @include font-map('b0');
    @include font-default();
    margin-bottom: getSpacing('xs');

    &.u-b0--highlights {
        @include font-map('b0--highlight');
        @include font-default();
        margin-bottom: getSpacing('xs');
    }
}

//
//BASE

//
.u-b1 {
    @include font-map('b1');
    @include font-heading(); //bold
}

//
.u-b2 {
    @include font-map('b2');
    @include font-default();

    &--medium {
        font-weight: 600;
    }
}

//
.u-b3 {
    @include font-map('b3');
    @include font-default();
}

//
.u-b4 {
    @include font-map('b4');
    letter-spacing: 0.2px;
    @include font-default();
    margin-bottom: 0.8em;

    &.u-b4--bold {
        @include font-heading();
    }
}

//
.u-b5 {
    @include font-map('b5');
    letter-spacing: 0.2px;
    @include font-heading(); //bold

    &.u-b5--uppercase {
        text-transform: uppercase;
    }
}

//
.u-b6 {
    @include font-map('b6');
    letter-spacing: 0.2px;
    @include font-default();
}

//
.u-b7 {
    @include font-map('b7');
    @include font-default();

    &--bold {
        @include font-heading();
    }
}

.u-uppercase {
    text-transform: uppercase;
}
