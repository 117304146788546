.c-modular-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 100vh;
    justify-content: flex-end;
    color: getColor("white");
    padding-top: getSpacing('lg', 'fixed');
    padding-bottom: getSpacing('lg', 'fixed');
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.58);
    }

    @include mq("md") {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: getSpacing('xl', 'fixed');
        padding-bottom: getSpacing('xl', 'fixed');
    }

    &__title {
        max-width: 650px;
        width: 100%;
        padding-bottom: 10px;
    }

    &__subtitle {
        max-width: 535px;
        width: 100%;
        padding-bottom: 15px;
    }

    &__scroll-indicator {
        position: absolute;
        left: percentage(4.5/24);
        bottom: 55px;
        width: 30px;
        height: 50px;
        border-radius: 100px;
        border: 1px solid getColor("white");

        @include mq("lg") {
            left: auto;
            right: percentage(1/24);
        }

        &:after {
            content: '';
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            opacity: 1;
            border-radius: 50px;
            width: 5px;
            height: 9px;
            background-color: getColor("white");
            animation: scrollAnimation ease-out 1.5s infinite;
        }
    }

    .c-modular-btn {
        display: inline-block;
    }

    @keyframes scrollAnimation {
        from {
            transform: translate(-50%, 0);
            opacity: 1;
        }

        to {
            transform: translate(-50%, 20px);
            opacity: 0;
        }
    }
}
