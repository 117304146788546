$settings-border-radius: (
    'round': (
        'default': 4px,
        'zero': 0px,
        'xxs': 1px,
        'xs': 2px,
        'sm': 3px,
        'md': 4px,
        'lg': 6px,
        'xl': 8px,
        'xxl': 10px,
        'xxxl': 20px,
    ),
    'rounding': (
        'default': 100%,
        'zero': 0%,
        'xxs': 10%,
        'xs': 10%,
        'sm': 30%,
        'md': 40%,
        'lg': 50%,
        'xl': 70%,
        'xxl': 90%,
        'xxxl': 100%,
    ),
);