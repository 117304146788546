.c-list-with-content {
    max-width: 740px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    &__inner {
        max-width: 660px;
        width: 100%;
    }

    &__text-paragraph {
        padding-bottom: getSpacing('sm');

        a {
            text-decoration: underline;
        }
    }

    &__list-title {
        padding-bottom: getSpacing('xs');
    }
}
