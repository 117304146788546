//HORIZONTAL SEPARATOR
.o-horizontal-separator {
    display: block;
    width: 100%;
    border-top: 1px solid getColor('white');
}

//MODIFIERS

.o-horizontal-separator--dark {
    border-color: getColor('dark-blue');
}