//PAGE WRAPPER
.o-page-wrapper {
    width: 100%;
    min-height: 100vh;
    position: relative;
}

//MODIFIERS
.o-page-wrapper--work {
    background-color: getColor('dark-grey', 'sections');
}

.o-page-wrapper--team {
    //background-color: getColor('pale-blue-B', 'sections');
}

.o-page-wrapper--contact {
    //background-color: getColor('pale-green-A', 'sections');
}

.o-page-wrapper--blog-single {
    background-color: getColor('white');
}

.o-page-wrapper--service{

}
